import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Toggler from "../../../Components/Toggler/Toggler";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import { HOSTEL_ASSIGN } from "../../../utils/Hostel.apiConst";
import { HR_ASSIGN_WORKFLOW } from "../../../utils/apiConstants";
import { getDateMeta } from "@fullcalendar/react";
import { SessionOpt } from "../../../Data/student/sessionData";
import Select from "react-select";
import {
  STUDENT_TRANSPORT_FEES,
  TRANSPORT_ASSIGN,
} from "../../../utils/Transport.apiConst";
import {
  HOSTEL_STUDENT_DATA,
  HOSTEL_ROOMS_DETAILS,
  HOSTEL_FEE_YEAR,
} from "../../../utils/Hostel.apiConst";

function ModalTransportAssign({
  setLoading,
  selectedStudent,
  routeData,
  pickuppointData,
  pickuppoints,
}) {
  var emp_id = sessionStorage.getItem("employee_id");
  const role = sessionStorage.getItem("role");

  const options = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const [selectedMonths, setSelectedMonths] = useState([]);
  const [studentTransportData, setStudentTransportData] = useState([]);
  const isSelectAllSelected = selectedMonths.length === options.length;
  const [show, setShow] = useState([]);
  const [hostelFee, setHostelFee] = useState([]);
  const [edit, setEdit] = useState(false);

  const selectAllOption = { label: "Select All", value: "all" };

  let selectedValue = isSelectAllSelected ? [...options] : selectedMonths;

  const [collegeOpt, setCollegeOpt] = useState(
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null
  );

  const [user, setUser] = useState({
    year: "",
    months: [],
  });

  const handleSelectChange = (selectedValues) => {
    if (selectedValues.includes(selectAllOption)) {
      setSelectedMonths(options.map((option) => option.value));
      setUser({ ...user, months: options.map((option) => option) });
    } else {
      setSelectedMonths(
        selectedValues.filter((val) => val !== selectAllOption)
      );
      setUser({
        ...user,
        months: selectedValues.filter((val) => val !== selectAllOption),
      });
    }
  };

  useEffect(() => {
    console.log("user hi - ", user);
  }, [user]);

  const getStudentTransportData = async (year) => {
    if (!year) return;
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    console.log(user.year);
    console.log(selectedStudent?.student_session_id);
    await axios({
      ...config,
      url: `${STUDENT_TRANSPORT_FEES}?year=${year}&student_id=${selectedStudent?.student_session_id}`,
    })
      .then((res) => {
        console.log("student transport - ", res.data.data);
        setStudentTransportData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Error while fetching Student Transport Data");
        console.log(err);
      });
    setLoading(0);
  };

  const handleSubmit = async () => {
    const ids = user.months;
    let arr = ids?.map((i, k) => {
      return i.value;
    });
    console.log("months selected - ", ids);
    console.log("arr - ", arr);
    const monthsString = `[${arr.join(",")}]`;

    if (!user.route_id) return toast.error("Please Select Route");
    if (!user.pickuppoint_id) return toast.error("Please Select Pickup Point");
    if (!user.amount) return toast.error("Unable to get Amount");
    if (
      !selectedStudent?.student_session_id ||
      !selectedStudent?.department_id ||
      !selectedStudent?.session_id
    )
      return toast.error("Unable to get student Id");
    if (!monthsString) return toast.error("Please Select Months");

    console.log("user - ", {
      ...user,
      months: monthsString,
      department_id: selectedStudent?.department_id,
      student_id: selectedStudent?.student_session_id,
      session_id: selectedStudent?.session_id,
    });

    const config = {
      method: "post",
      url: `${TRANSPORT_ASSIGN}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        months: monthsString,
        department_id: selectedStudent?.department_id,
        student_id: selectedStudent?.student_session_id,
        session_id: selectedStudent?.session_id,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        console.log("success");
        //   getRooms();
        setUser({
          year: "",
          months: [],
          amount: "",
          route_id: "",
          pickuppoint_id: "",
        });
        setStudentTransportData([]);
        setSelectedMonths([]);
        toast.success("Transport Assigned");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
    setLoading(0);
  };

  const handleEdit = async () => {
    const ids = user.months;
    let arr = ids?.map((i, k) => {
      return i.value;
    });
    console.log("months selected - ", ids);
    console.log("arr - ", arr);
    const monthsString = `[${arr.join(",")}]`;

    if (!user.route_id) return toast.error("Please Select Route");
    if (!user.pickuppoint_id) return toast.error("Please Select Pickup Point");
    if (!user.amount) return toast.error("Unable to get Amount");
    if (
      !selectedStudent?.student_session_id ||
      !selectedStudent?.department_id ||
      !selectedStudent?.session_id
    )
      return toast.error("Unable to get student Id");
    if (!monthsString) return toast.error("Please Select Months");

    // console.log(monthsString);

    console.log("user - ", {
      ...user,
      months: monthsString,
      department_id: selectedStudent?.department_id,
      student_id: selectedStudent?.student_session_id,
      session_id: selectedStudent?.session_id,
    });

    const config = {
      method: "put",
      url: `${TRANSPORT_ASSIGN}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        months: monthsString,
        department_id: selectedStudent?.department_id,
        student_id: selectedStudent?.student_session_id,
        session_id: selectedStudent?.session_id,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        console.log("success");
        setUser({
          year: "",
          months: [],
          amount: "",
          route_id: "",
          pickuppoint_id: "",
        });
        setStudentTransportData([]);
        setSelectedMonths([]);
        toast.success("Edited");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
    setLoading(0);
  };

  return (
    <div className="ModalTransportAssign">
      <div className="ModalTransportAssign">
        <div
          className="modal fade"
          id="ModalTransportAssign"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered mw-100 w-75"
            role="document"
          >
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Assign Transport
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setUser({
                      year: "",
                      // months: [],
                      amount: "",
                      floor: "",
                      hostel_id: "",
                      room_id: "",
                      room_type: "",
                      bed_no: "",
                    });
                    setStudentTransportData([]);
                    setEdit(false);
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <br />
                <div className="row">
                  <div className="col-md-3">
                    <img
                      className="profile-user-img img-responsive rounded-circle mx-auto d-block"
                      src={`${
                        selectedStudent?.student_picture
                          ? selectedStudent?.student_picture
                          : "../../../assets/images/reports/graduated.png"
                      }
                                `}
                      width="50%"
                      style={{ aspectRatio: "1/1" }}
                    />
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6">
                        <table>
                          <tr>
                            <th>Enrollment No </th>
                            <td> : {selectedStudent?.user_id}</td>
                          </tr>
                          <tr>
                            <th>Name</th>
                            <td> : {selectedStudent?.name}</td>
                          </tr>
                          <tr>
                            <th>Gender</th>
                            <td> : {selectedStudent?.gender}</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td> : {selectedStudent?.phone}</td>
                          </tr>
                        </table>
                      </div>
                      <div className="col-md-6">
                        <table>
                          <tr>
                            <th>Session </th>
                            <td>
                              {" "}
                              : {selectedStudent?.session_id}-
                              {selectedStudent?.session_id + 1}
                            </td>
                          </tr>
                          <tr>
                            <th>Department</th>
                            <td> : {selectedStudent?.dept_name}</td>
                          </tr>
                          <tr>
                            <th>Class</th>
                            <td> : {selectedStudent?.class_name}</td>
                          </tr>
                          <tr>
                            <th>Semester</th>
                            <td> : {selectedStudent?.semester}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="class">
                        Select Year <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-control"
                        name="year"
                        id="year"
                        value={user.year}
                        disabled={edit}
                        onChange={(e) => {
                          setUser({ ...user, year: e.target.value });
                          if (e.target.value) {
                            getStudentTransportData(e.target.value);
                          }
                          setEdit(false);
                        }}
                      >
                        <option value="">Select Year</option>
                        {SessionOpt &&
                          SessionOpt?.map((item, key) => {
                            return (
                              <option value={item?.id}>
                                {item?.name?.split("-")[0]}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  {studentTransportData?.length > 0 ? (
                    edit ? (
                      //options for edit
                      <>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Select Months{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              isMulti
                              options={[selectAllOption, ...options]}
                              value={selectedValue}
                              onChange={handleSelectChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">
                              Select Route{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="route_id"
                              id="route_id"
                              value={user.route_id}
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  route_id: e.target.value,
                                  amount: pickuppointData?.find(
                                    (s) => s.id == e.target.value
                                  )?.fees,
                                })
                              }
                            >
                              <option value="">Select Route</option>
                              {routeData?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">
                              Select Pickup Point{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="pickuppoint_id"
                              id="pickuppoint_id"
                              value={user.pickuppoint_id}
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  pickuppoint_id: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Pickup point</option>
                              {pickuppointData
                                ?.filter((s) => s.route == user.route_id)
                                ?.map((i, key) => (
                                  <option value={i.id} key={key}>
                                    {
                                      pickuppoints?.find(
                                        (s) => s.pickuppointname == s.id
                                      )?.name
                                    }
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">Amount </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Amount"
                              value={user.amount}
                              onChange={(e) =>
                                setUser({ ...user, amount: e.target.value })
                              }
                              // disabled="true"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      //Display hostel assigned to student for particular year
                      <div className="col-md-12">
                        <p className="alert alert-danger text-center ml-5 mr-5">
                          <b>
                            Transport assigned to student for the year{" "}
                            {user.year}
                          </b>
                        </p>
                        <div className="row">
                          <div className="col-md-3"></div>
                          <div className="col-md-9">
                            <div className="row">
                              <div className="col-md-6">
                                <table>
                                  <tr>
                                    <th>Route</th>
                                    <td>
                                      {" "}
                                      :{" "}
                                      {
                                        routeData?.find(
                                          (s) =>
                                            s.id ==
                                            studentTransportData[0].route_id
                                        )?.title
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Pickup Point</th>
                                    <td>
                                      {" "}
                                      :{" "}
                                      {
                                        pickuppointData?.find(
                                          (s) =>
                                            s.id ==
                                            studentTransportData[0]
                                              .pickuppoint_id
                                        )?.name
                                      }
                                    </td>
                                  </tr>
                                  {/* <tr>
                                            <th>Amount</th>
                                            <td> : {roomTypes?.find((s) => s.id == rooms.find((s) => s.id == studentHostelData[0].room_id)?.hostel_room_type_id)?.room_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Room number</th>
                                            <td> : {rooms?.find((s) => s.id == studentHostelData[0].room_id)?.room_name_number}</td>
                                        </tr>
                                        <tr>
                                            <th>Bed number</th>
                                            <td> : {studentHostelData[0]?.bed_no}</td>
                                        </tr> */}
                                </table>
                              </div>
                              <div className="col-md-6">
                                <table>
                                  <tr>
                                    <th>Year</th>
                                    <td> : {studentTransportData[0]?.year}</td>
                                  </tr>
                                  <tr>
                                    <th>Amount</th>
                                    <td>
                                      {" "}
                                      :{" "}
                                      {studentTransportData[0]?.amount?.toLocaleString(
                                        "en-IN",
                                        {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        }
                                      )}
                                    </td>
                                  </tr>
                                  {/* <tr>
                                            <th>Paid amount</th>
                                            <td> : {studentHostelData[0]?.paid_amount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                        </tr> */}
                                  {/* <tr>
                                            <th>Balance</th>
                                            <td> : {(studentHostelData[0]?.amount - studentHostelData[0]?.paid_amount)?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                        </tr>
                                        <tr>
                                            <th>Fee Status</th>
                                            <td> :
                                                <span className={`ml-1 badge badge-soft-${(studentHostelData[0]?.amount - studentHostelData[0]?.paid_amount) == 0 ? "success":((studentHostelData[0]?.amount - studentHostelData[0]?.paid_amount) == studentHostelData[0]?.amount ?"danger":"warning")}`} >
                                                {(studentHostelData[0]?.amount - studentHostelData[0]?.paid_amount) == 0 ? "Paid" : (studentHostelData[0]?.amount - studentHostelData[0]?.paid_amount) == studentHostelData[0]?.amount ? "Not Paid" : "Partial"}
                                                </span>
                                            </td>
                                        </tr> */}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    // Dropdown for assigning neew hostel
                    <>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Select Months{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            isMulti
                            options={[selectAllOption, ...options]}
                            value={selectedValue}
                            onChange={handleSelectChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="class">
                            Select Route <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="route_id"
                            id="route_id"
                            value={user.route_id}
                            onChange={(e) =>
                              setUser({ ...user, route_id: e.target.value })
                            }
                          >
                            <option value="">Select Route</option>
                            {routeData?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="class">
                            Select Pickup Point{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="pickuppoint_id"
                            id="pickuppoint_id"
                            value={user.pickuppoint_id}
                            onChange={(e) =>
                              setUser({
                                ...user,
                                pickuppoint_id: e.target.value,
                                amount: pickuppointData?.find(
                                  (s) => s.id == e.target.value
                                )?.fees,
                              })
                            }
                          >
                            <option value="">Select Pickup point</option>
                            {pickuppointData
                              ?.filter((s) => s.route == user.route_id)
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {
                                    pickuppoints?.find(
                                      (s) => i.pickuppointname == s.id
                                    )?.name
                                  }
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="class">
                            Amount <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Amount"
                            value={user.amount}
                            onChange={(e) =>
                              setUser({ ...user, amount: e.target.value })
                            }

                            // disabled="true"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="row d-flex justify-content-between px-2">
                <button
                  className="btn btn-danger btn-rounded btn-outline ml-3"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setUser({
                      year: "",
                      months: [],
                      amount: "",
                      floor: "",
                      hostel_id: "",
                      room_id: "",
                      room_type: "",
                      bed_no: "",
                    });
                    setStudentTransportData([]);
                    setSelectedMonths([]);
                    setEdit(false);
                  }}
                >
                  Cancel
                </button>
                {studentTransportData?.length > 0 ? (
                  edit ? (
                    <button
                      className="btn btn-success btn-outline mr-3"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setEdit(true);
                        handleEdit();
                      }}
                    >
                      Save Changes
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary btn-outline mr-3"
                      onClick={() => {
                        let studentMonths = JSON.parse(
                          studentTransportData[0]?.months
                        )
                          ? JSON.parse(studentTransportData[0]?.months)
                          : [];
                        let tempMonths = [];
                        for (let i in studentMonths) {
                          tempMonths.push({
                            label: options.find(
                              (s) => s.value == studentMonths[i]
                            )?.label,
                            value: studentMonths[i],
                          });
                        }
                        console.log("temp months - ", tempMonths);
                        handleSelectChange(tempMonths);
                        setEdit(true);
                        setUser({
                          route_id: studentTransportData[0]?.route_id,
                          year: studentTransportData[0]?.year,
                          pickuppoint_id:
                            studentTransportData[0]?.pickuppoint_id,
                          amount: studentTransportData[0]?.amount,
                          months: tempMonths,
                        });
                        console.log("edit user - ", {
                          route_id: studentTransportData[0]?.route_id,
                          year: studentTransportData[0]?.year,
                          pickuppoint_id:
                            studentTransportData[0]?.pickuppoint_id,
                          amount: studentTransportData[0]?.amount,
                          months: tempMonths,
                        });
                      }}
                    >
                      Edit
                    </button>
                  )
                ) : (
                  <button
                    className="btn btn-success btn-outline mr-3"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      handleSubmit();
                      setEdit(false);
                    }}
                  >
                    Assign
                  </button>
                )}
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalTransportAssign;
