import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { LIBRARY_USER_LOGS } from "../../../utils/Library.apiConst";
import { toast } from "react-toastify";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../../utils/LocalStorageConstants";

function UserLogs({ setLoading, collegeId }) {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);

  const [flag, setFlag] = useState(false);
  const [user, setUser] = useState({
    from_date: new Date().toISOString(),
    to_date: new Date().toISOString(),
    enrollment_no: "",
  });

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [classOpt, setClassOpt] = useState([]);

  const [sectionOpt, setSectionOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const clearData = () => {
    setUser({
      from_date: "",
      to_date: "",
      enrollment_no: "",
    });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getAllData = async () => {
    setLoading(1);

    const config1 = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const [data1, data2, data3] = await Promise.all([
      await axios({
        ...config1,
        url: `${ACADEMICS_ADD_CLASS}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),

      await axios({
        ...config1,
        url: `${ACADEMICS_ADD_SEMESTER}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),

      await axios({
        ...config1,
        url: `${ACADEMICS_ADD_SECTION}`,
      })
        .then((res) => {
          setSectionOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),
    ]);

    const config = {
      method: "get",
      url: `${LIBRARY_USER_LOGS}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setLoading(0);
        let x = res.data.data.filter(
          (s) =>
            s.from_date?.split("T")[0] == new Date().toISOString().split("T")[0]
        );
        setData(x);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
        console.log(err);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleSubmit = async () => {
    setLoading(1);
    const config = {
      method: "post",
      url: LIBRARY_USER_LOGS,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: user,
    };
    await axios(config)
      .then((res) => {
        setLoading(0);
        clearData();
        getAllData();
        toast.success("Successfully Saved Details");
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
        console.log(err);
      });
  };

  const handleSearch = () => {
    setFlag(true);
    if (!user.from_date || !user.to_date) {
      return toast.error("Please Enter Required Details");
    }

    let x = data?.filter(
      (s) =>
        s.from_date.split("T")[0] >= user?.from_date &&
        s.to_date.split("T")[0] <= user?.to_date
    );
    setData1(x);
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <label>Enter Details</label>
                  <div className="row">
                    <div className="col-md-4">
                      <label>Enrollment Number</label>
                      <div className="form-group">
                        <input
                          type="text"
                          name="enrollment_no"
                          value={user?.enrollment_no}
                          onChange={handleChange}
                          placeholder="Enter Enrollment Number"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <button
                        className="btn btn-primary btn-md btn-rounded mt-4"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label>From Date</label>
                      <div className="form-group">
                        <input
                          type="date"
                          value={user?.from_date}
                          name="from_date"
                          onChange={handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label>To Date</label>
                      <div className="form-group">
                        <input
                          type="date"
                          value={user?.to_date}
                          name="to_date"
                          onChange={handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        onClick={handleSearch}
                        className="btn btn-primary btn-rounded mt-4 float-right"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      className="table table-bordered"
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead className="text-center">
                        <tr>
                          <th>Sl.No.</th>
                          <th>Enrollment No/Employee Id</th>
                          <th>Name</th>
                          <th>Gender</th>
                          <th>Faculty</th>
                          <th>Department</th>
                          <th>Semester</th>
                          <th>Class</th>
                          <th>Section</th>
                          <th>Login Date</th>
                          <th>Logout Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {!flag
                          ? data &&
                            data?.map((item, key) => {
                              return (
                                <tr>
                                  <td>{key + 1}</td>
                                  <td>{item?.enrollment_no}</td>
                                  <td>{item?.name}</td>
                                  <td>{item?.gender}</td>
                                  <td>
                                    {
                                      collegeOpt?.filter(
                                        (s) => s.id == item?.college_id
                                      )[0]?.name
                                    }
                                  </td>
                                  <td>
                                    {
                                      department?.filter(
                                        (s) => s.id == item?.department_id
                                      )[0]?.name
                                    }
                                  </td>
                                  <td>
                                    {
                                      semesterOpt?.filter(
                                        (s) => s.id == item?.semester_id
                                      )[0]?.name
                                    }
                                  </td>
                                  <td>
                                    {
                                      classOpt?.filter(
                                        (s) => s.id == item?.class_id
                                      )[0]?.name
                                    }
                                  </td>
                                  <td>
                                    {
                                      sectionOpt?.filter(
                                        (s) => s.id == item?.section_id
                                      )[0]?.name
                                    }
                                  </td>
                                  <td>{item?.from_date?.split("T")[0]}</td>
                                  <td>{item?.to_date?.split("T")[0]}</td>
                                  <td>{item?.status}</td>
                                </tr>
                              );
                            })
                          : data1?.map((item, key) => {
                              return (
                                <tr>
                                  <td>{key + 1}</td>
                                  <td>{item?.enrollment_no}</td>
                                  <td>{item?.name}</td>
                                  <td>{item?.gender}</td>
                                  <td>
                                    {
                                      collegeOpt?.filter(
                                        (s) => s.id == item?.college_id
                                      )[0]?.name
                                    }
                                  </td>
                                  <td>
                                    {
                                      department?.filter(
                                        (s) => s.id == item?.department_id
                                      )[0]?.name
                                    }
                                  </td>
                                  <td>
                                    {
                                      semesterOpt?.filter(
                                        (s) => s.id == item?.semester_id
                                      )[0]?.name
                                    }
                                  </td>
                                  <td>
                                    {
                                      classOpt?.filter(
                                        (s) => s.id == item?.class_id
                                      )[0]?.name
                                    }
                                  </td>
                                  <td>
                                    {
                                      sectionOpt?.filter(
                                        (s) => s.id == item?.section_id
                                      )[0]?.name
                                    }
                                  </td>
                                  <td>{item?.from_date?.split("T")[0]}</td>
                                  <td>{item?.to_date?.split("T")[0]}</td>
                                  <td>{item?.status}</td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLogs;
