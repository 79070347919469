import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { ASSET_MEDIA } from "../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../Helpers/Helpers";
import {
  COMPANY_DETAILS_GET,
  COMPANY_DETAILS_PUT,
  COMPANY_DETAILS_UPLOAD,
} from "../../utils/InfoUploadingApiConstants";
import DocumentsModal from "../../modals/Students/DocumentsModal";

function CompanyDetails({ setLoading }) {
  const fileref = useRef(null);

  const [user, setUser] = useState({
    companyname: "",
    logo: "",
  });
  const [data, setData] = useState([]);

  const [edit, setEdit] = useState(0);

  const [editId, setEditId] = useState();

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  const clearData = () => {
    setUser({
      companyname: "",
      logo: "",
    });
    fileref.current.value = null;
  };

  const getdata = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${COMPANY_DETAILS_GET}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        // setData(res.data.data);
        const data1 = res.data.data.filter(
          (item) =>
            item.status == "ACTIVE" &&
            item.college_id == sessionStorage.getItem("college_id")
        );
        setData(data1);
        data1.sort((a, b) => b.id - a.id);
        data1.forEach((element) => {
          element.logo = JSON.parse(element.logo);
        });
      })
      .catch((err) => {
        setLoading(0);
        // toast.error("Something Went Wrong");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (!user.companyname) return toast.error("Please Add Company Name");

    const config = {
      method: "post",
      url: `${COMPANY_DETAILS_UPLOAD}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        college_id: sessionStorage.getItem("college_id"),
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Successfully Uploaded Details");
        getdata();
      })
      .catch((err) => {
        setLoading(0);
        // toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    getdata();
  }, []);

  // const handleEdit = (editId) => {
  //     if (!user?.companyname) return toast.error("name is required");
  //     setLoading(1);
  //     const config = {
  //       method: "put",
  //       url: `${COMPANY_DETAILS_PUT}/${editId}`,
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
  //         "Content-Type": "application/json",
  //       },
  //       data: {
  //         ...user,
  //       },
  //     };

  //     axios(config)
  //       .then((res) => {
  //         setLoading(0);
  //         getdata();
  //         clearData();
  //         toast.success("Success");
  //       })
  //       .catch((err) => {
  //         setLoading(0);
  //         toast.error("Something went wrong");
  //       });
  //   };

  const handleEdit = (editId) => {
    if (!user?.companyname) return toast.error("name is required");

    // Update state before making the API call
    setUser((prevValue) => ({ ...prevValue }));

    setLoading(1);
    const config = {
      method: "put",
      url: `${COMPANY_DETAILS_PUT}/${editId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        // getdata();
        clearData();
        getdata();
        toast.success("Success");
      })
      .catch((err) => {
        setLoading(0);
        // toast.error("Something went wrong");
      });
  };

  const handleDelete = (id) => {
    const config = {
      method: "put",
      url: `${COMPANY_DETAILS_PUT}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("INACTIVE Successfully");
        const updatedData = data.map((item) =>
          item.id === id ? { ...item, status: "INACTIVE" } : item
        );
        setData(updatedData);
        getdata();
      })
      .catch((err) => {
        setLoading(0);
        // toast.error("Something Went Wrong")
      });
  };

  const handleApprove = (id) => {
    const config = {
      method: "put",
      url: `${COMPANY_DETAILS_PUT}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "ACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("ACTIVE Successfully");
        const updatedData = data.map((item) =>
          item.id === id ? { ...item, status: "ACTIVE" } : item
        );
        setData(updatedData);
      })
      .catch((err) => {
        setLoading(0);
        // toast.error("Something Went Wrong")
      });
  };

  const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      user.logo = d;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DocumentsModal title={title} img={link} setLink={setLink} />
      <div ClassName="CompanyDetails">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* Followup */}
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h5 className="">COMPANY DETAILS</h5>
                  </div>
                </div>
              </div>
              <>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">Add Criteria</h2>
                        <br />
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="">
                                companyname{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Company Name"
                                className="form-control"
                                name="companyname"
                                value={user?.companyname}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">COMPANY LOGO</label>
                              <input
                                type="file"
                                placeholder="Upload Photo"
                                className="form-control"
                                name="logo"
                                ref={fileref}
                                onChange={(e) => handleChange1(e)}
                              />
                              {user?.logo?.length > 0 ? (
                                <button
                                  onClick={() => {
                                    setLink(user?.logo);
                                    setTitle("Company Logo");
                                  }}
                                  data-toggle="modal"
                                  data-target="#DocumentsModal"
                                  className="btn btn-primary btn-sm my-1"
                                >
                                  View
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row float-right ">
                          {edit ? (
                            <>
                              <button
                                className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                                type="submit"
                                name="submit"
                                // style={{aspectRatio:'1/1'}}
                                onClick={() => {
                                  setEdit(0);
                                  getdata();
                                  clearData();
                                  // setAddNew(false);
                                }}
                              >
                                {"<   "}{" "}
                              </button>
                              <button
                                className="btn btn-nex btn-rounded float-right  "
                                type="submit"
                                name="submit"
                                //   onClick={(e) => handleEdit(e)}
                                onClick={() => handleEdit(editId)}
                              >
                                Save Changes
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn btn-nex btn-rounded float-right mr-4"
                              type="submit"
                              name="submit"
                              onClick={(e) => handleSubmit(e)}
                            >
                              <i className="fa fa-save" aria-hidden="true" />{" "}
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* end card */}
                  </div>
                </div>
                {/* container-fluid */}
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="card-title text-primary">
                              COMPANIES LIST
                            </h3>
                          </div>
                        </div>
                        <hr />
                        <table
                          className="table table-bordered dt-responsive nowrap"
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>Sl. No.</th>
                              <th>Company Names</th>
                              <th>Logo</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((data, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{data?.companyname}</td>
                                    <td>
                                      <a
                                        href={
                                          data?.logo && data?.logo != '""'
                                            ? data.logo
                                            : "#"
                                        }
                                        target={
                                          data?.logo && data?.logo != '""'
                                            ? "_blank"
                                            : "_self"
                                        }
                                        style={{
                                          color:
                                            data?.logo && data?.logo != '""'
                                              ? "blue"
                                              : "black",
                                        }}
                                      >
                                        {data?.logo == '""'
                                          ? "Not Uploaded"
                                          : "View File"}
                                      </a>
                                    </td>

                                    <td
                                      className={
                                        data?.status == "ACTIVE"
                                          ? "badge badge-soft-success"
                                          : "badge badge-soft-danger"
                                      }
                                    >
                                      {data?.status}
                                    </td>

                                    <td>
                                      <span
                                        className="badge badge-light text-dark mr-3"
                                        data-toggle="tooltip"
                                        title="Edit"
                                        onClick={() => {
                                          setUser({
                                            companyname: data?.companyname,
                                            logo: data?.logo,
                                          });
                                          setEdit(1);
                                          setEditId(data?.id);
                                        }}
                                      >
                                        {" "}
                                        <i
                                          class="fa fa-edit "
                                          aria-hidden="true"
                                        ></i>
                                        {/* Edit */}
                                      </span>

                                      {data?.status == "INACTIVE" ? (
                                        <span
                                          className="text-success mr-2"
                                          onClick={() =>
                                            handleApprove(data?.id)
                                          }
                                        >
                                          <i
                                            class="fa fa-thumbs-up text-success"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      ) : (
                                        <span
                                          className="text-danger mr-2"
                                          onClick={() => handleDelete(data?.id)}
                                        >
                                          <i
                                            class="fa fa-thumbs-down "
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>{" "}
                  {/* end col */}
                </div>{" "}
                {/* end row */}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails;
