import React from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EMPLOYEE_ALL } from "../../utils/apiConstants";
import { ASSET_HOMEOMEDIA } from "../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../Helpers/Helpers";
import {
  PHOTO_GALLERY_GET,
  PHOTO_GALLERY_UPDATE,
  PHOTO_GALLERY_UPLOAD,
} from "../../utils/InfoUploadingApiConstants";

function PhotoGallery({ setLoading }) {
  const fileref = useRef(null);

  const [attachments, setAttachments] = useState([]);

  const [data, setData] = useState([]);

  const [info, setInfo] = useState({
    title: "",
    date: "",
  });

  const clearData = () => {
    setInfo({
      title: "",
      date: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {
    if (!info?.date || !info?.title) {
      toast.error("Please Enter all the required Details");
      return;
    }

    setLoading(1);

    // Retrieve college_id from session storage
    const college_id = sessionStorage.getItem("college_id");
    console.log("college_id", college_id);

    const config = {
      method: "post",
      url: PHOTO_GALLERY_UPLOAD,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...info,
        attachments,
        //  college_id,
        college_id: college_id, // Add college_id to the data
      },
    };

    axios(config)
      .then((res) => {
        // console.log("college_id"+res);
        toast.success("Succesfully Uploaded Details");
        clearData();
        getData();
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const Change = async (e) => {
  //   let files = [];
  //   for (let i of e.target.files) {
  //     try {
  //       const d = await getFileUrl(
  //         ASSET_HOMEOMEDIA,
  //         "Homoeopathy_Assets/Events",
  //         i?.name.split(".")[1],
  //         setLoading,
  //         i
  //       );
  //       console.log("abcd" + d);
  //       files.push(d);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   setAttachments(files);
  // };

  //Only Images are allowed
  const Change = async (e) => {
    let files = [];

    for (let i of e.target.files) {
      if (i.type === "application/pdf") {
        toast.error("PDF files are not allowed.");
        continue;
      }
      console.log(i);
      try {
        const d = await getFileUrl(
          ASSET_HOMEOMEDIA,
          "Homoeopathy_Assets/Events",
          i?.name.split(".")[1],
          setLoading,
          i
        );
        files.push(d);
      } catch (error) {
        console.log(error);
      }
    }
    setAttachments(files);
  };

  const getData = async () => {
    const collegeid = sessionStorage.getItem("college_id");

    const config = {
      method: "get",
      url: PHOTO_GALLERY_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);
        res.data.data.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        console.log(res.data.data);
        // setData(res.data.data);
        setData(res.data.data.filter((item) => item.college_id == collegeid));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleApprove = (id) => {
    const config = {
      method: "put",
      url: `${PHOTO_GALLERY_UPDATE}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "ACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Successfully ACTIVE");
        const updatedData = data.map((item) =>
          item.id === id ? { ...item, status: "ACTIVE" } : item
        );
        setData(updatedData);
        getData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  const handleDelete = (id) => {
    const config = {
      method: "put",
      url: `${PHOTO_GALLERY_UPDATE}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Successfully INACTIVE");
        const updatedData = data.map((item) =>
          item.id === id ? { ...item, status: "INACTIVE" } : item
        );
        setData(updatedData);
        getData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 mt-4">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h5 className="ml-4">Upload Gallery Photos Details</h5>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 mb-2">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Select criteria</h2>
              <br />

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      {" "}
                      Title <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter the Title"
                      name="title"
                      value={info?.title}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>

                {/* <div className="col-md-4">
                                <div className="form-group">
                                    <label> Description <span style={{ color: "red" }}>*</span></label>
                                    <input
                      rows={5}
                      cols={10}
                      className="form-control"
                      placeholder="Enter Description"
                      name="description"
                      value={info?.description}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                                </div>
                            </div> */}

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      {" "}
                      Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      placeholder="Select Date"
                      value={info?.date}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">
                      Attachments<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      multiple="multiple"
                      type="file"
                      className="form-control"
                      name="attachments"
                      placeholder="Enter Title Of the Subject"
                      ref={fileref}
                      onChange={(e) => Change(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="row  mr-5 mt-2 float-right">
                <button
                  className="btn btn-nex btn-rounded float-right "
                  type="submit"
                  name="submit"
                  onClick={handleSubmit}
                >
                  <i className="fa fa-save" aria-hidden="true" />
                  Save
                </button>
              </div>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div class="card-header">
              <h5 class="text-primary"> GALLERY PHOTOS LIST </h5>
            </div>

            <div className="card-body">
              <br />

              <div style={{ overflowX: "auto" }}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sl.No.</th>
                      <th>Title</th>
                      <th>Attachments</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data &&
                      data?.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td width="2%">{key + 1}</td>
                            <td width="8%">{item?.title}</td>
                            <td width="4%">
                              <a href={item?.attachments} target="_blank">
                                {" "}
                                View Uploaded File{" "}
                              </a>
                            </td>
                            <td width="4%">
                              {new Date(item?.date).toISOString().split("T")[0]}
                            </td>
                            <td width="1%">
                              {item?.status == "INACTIVE" ? (
                                <button
                                  className="btn btn-danger mr-2"
                                  onClick={() => handleApprove(item?.id)}
                                >
                                  INACTIVE
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success mr-2"
                                  onClick={() => handleDelete(item?.id)}
                                >
                                  ACTIVE
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoGallery;
