import axios from "axios";
import React, { useState, useEffect } from "react";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import { PREV_EXAM_RESULT } from "../../../utils/apiConstants";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import "./style.scss";
import { ACADEMICS_ADD_SEMESTER } from "../../../utils/Academics.apiConst";
import { Link, useNavigate } from "react-router-dom";

function PrevResults({ setLoading, studentData, user }) {
  const componentRef = useRef();

  const calculateGrade = (p) => {
    if (p >= 85) return "O+";
    if (p >= 70 && p <= 84.99) return "O";
    if (p >= 60 && p <= 69.99) return "A";
    if (p >= 55 && p <= 59.99) return "B+";
    if (p >= 48 && p <= 54.99) return "B";
    if (p >= 36 && p <= 47.99) return "C";
    if (p < 36) return "D";
    // console.log(p);
  };

  const calculateGradePoint = (p) => {
    return parseFloat(p * 10).toFixed(2);
  };

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [faculty, setFaculty] = useState(studentData?.college_id);
  const [prog, setProg] = useState(studentData?.program_id);
  const [depart, setDepart] = useState(studentData?.department_id);
  const [currentclass, setCurrentClass] = useState(user?.class_id);
  const [currentSemester, setCurrentSemester] = useState(user?.sem_id);
  const [classopt, setClassOpt] = useState([]);
  const [sectionOpt, setSectionOpt] = useState([]);
  const [semesterOpt, setSemesterOpt] = useState([]);

  useEffect(() => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios({
      ...config,
      url: `${ACADEMICS_ADD_SEMESTER}?college_id=${faculty}`,
    })
      .then((res) => {
        // console.log("Semester", res.data.data);
        setSemesterOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [faculty]);

  // console.log(currentclass, currentSemester);
  //Faculty data
  const facultyData = [
    {
      id: 1111000,
      name: "FACULTY OF ENGINEERING AND TECHNOLOGY",
      college_type_id: "ENG",
      status: "ACTIVE",
      colloge_name:
        "Shree Swaminarayan College Engineering and Technology,Kalol",
    },
    {
      id: 1111001,
      name: "FACULTY OF AYURVEDA",
      college_type_id: "AYU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Ayurvedic College,Kalol",
    },
    {
      id: 1111002,
      name: "FACULTY OF LAW",
      college_type_id: "LAW",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Law College, Kalol",
    },
    {
      id: 1111003,
      name: "FACULTY OF NURSING",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  College of Nursing, Kalol",
    },
    {
      id: 1111004,
      name: "FACULTY OF ARTS AND HUMANITIES ",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Arts  College, Kalol",
    },
    {
      id: 1111005,
      name: "FACULTY OF COMMERCE AND MANAGEMENT",
      college_type_id: "COM",
      status: "ACTIVE",
      colloge_name: "FACULTY OF COMMERCE AND MANAGEMENT,Kalol",
    },
    {
      id: 1111006,
      name: "FACULTY OF MEDICINE",
      college_type_id: "MED",
      status: "ACTIVE",
      colloge_name:
        "Shree Swaminarayan Institute of Medical Science and Research, Kalol",
    },
    {
      id: 1111007,
      name: "FACULTY OF HOMEOPATHY",
      college_type_id: "HOM",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Homoeopathy College, Kalol",
    },
    {
      id: 1111008,
      name: "FACULTY OF PHARMACY",
      college_type_id: "PHA",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Pharmacy College, Kalol",
    },
    {
      id: 1111009,
      name: "FACULTY OF IT AND COMPUTER APPLICATIONS",
      college_type_id: "ITC",
      status: "ACTIVE",
      colloge_name: "FACULTY OF IT AND COMPUTER APPLICATIONS, Kalol",
    },
    {
      id: 1111010,
      name: "FACULTY OF EDUCATION",
      college_type_id: "EDU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan B.Ed College, Kalol",
    },
    {
      id: 1111011,
      name: "FACULTY OF SCIENCE",
      college_type_id: "SCI",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  Science College, Kalol",
    },
    {
      id: 1111012,
      name: "SWAMINARAYAN UNIVERSITY",
      college_type_id: "SUK",
      status: "ACTIVE",
      colloge_name: "SWAMINARAYAN UNIVERSITY, Kalol",
    },
    {
      id: 1111013,
      name: "DOCTORAL AND POST DOCTORAL PROGRAMME",
      college_type_id: "PHD",
      status: "ACTIVE",
      colloge_name: "SWAMINARAYAN UNIVERSITY, Kalol",
    },
  ];

  const [studentId, setStudentId] = useState(studentData?.user_id);
  const [sessionId, setSessionId] = useState(user?.session);
  const [status, setStatus] = useState("");

  const [flag, setFlag] = useState(0);

  const [examResults, setExamResults] = useState([]);
  const [totalsum, setTotalSum] = useState();
  const [totalsum_emin, setTotalSumEmin] = useState();
  const [totalsum_imin, setTotalSumImin] = useState();
  const [isPass, setIsPass] = useState(0);
  const [totalsum_imax, setTotalSumImax] = useState();
  const [totalsum_imarks, setTotalSumImarks] = useState();
  const [totalsum_emarks, setTotalSumEmarks] = useState();
  const [totalsum_obt, setTotalSumobt] = useState();
  const [totalabsent, setTotalAbsent] = useState();
  const [totalcredits, setTotalCredits] = useState();

  const getResults = async () => {
    if (
      !studentId ||
      !sessionId ||
      !faculty ||
      !prog ||
      !depart ||
      !currentclass ||
      !currentSemester ||
      !sessionId
    )
      return toast.error("Please fill Student Id and Session");
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${PREV_EXAM_RESULT}?student_id=${studentData?.user_id}&session=${user?.session}&class_id=${user?.class_id}&semester=${user?.sem_id}&college_id=${studentData?.college_id}`,
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setStatus(res.data.data.examStatus);
        if (res.data.data?.examStatus == "ACTIVE") {
          return toast.error("Result is not Published yet");
        }
        const result = Object.values(
          res.data.data.y?.reduce((acc, curr) => {
            const subject = curr.subject;
            if (!acc[subject]) {
              acc[subject] = { ...curr };
            } else {
              acc[subject].e_marks += curr.e_marks;
              acc[subject].e_max += curr.e_max;
              acc[subject].e_min += curr.e_min;
              if (curr.e_grace) acc[subject].e_grace += Number(curr.e_grace);
            }
            return acc;
          }, {})
        );
        if (result.length == 0) {
          toast.error("Please Check Entered Details");
          return;
        }
        console.log(res.data.data);
        setExamResults({ ...res.data.data, data: result });

        var sum = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum = sum;
          else sum += item.e_max;
        });
        // console.log("Sum", sum);
        setTotalSum({ emax: sum });

        var sum_emin = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_emin = sum_emin;
          else sum_emin += item.e_min;
        });
        // console.log(sum_emin);
        setTotalSumEmin({ emin: sum_emin });

        var sum_imin = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_imin = sum_imin;
          else sum_imin += item.i_min;
        });
        // console.log(sum_imin);
        setTotalSumImin({ imin: sum_imin });

        var sum_imax = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_imax = sum_imax;
          else sum_imax += item.i_max;
        });
        // console.log(sum_imax);
        setTotalSumImax({ imax: sum_imax });

        var sum_imarks = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_imarks = sum_imarks;
          else sum_imarks += item.i_marks;
        });
        // console.log(sum_imarks);
        setTotalSumImarks({ imarks: sum_imarks });

        var sum_emarks = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_emarks = sum_emarks;
          else sum_emarks += item.e_marks;
        });
        // console.log(sum_emarks);
        setTotalSumEmarks({ emarks: sum_emarks });

        var sum_obt = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_obt = sum_obt;
          else sum_obt += item.e_marks + item.i_marks;
        });
        // console.log(sum_obt);
        setTotalSumobt({ obt: sum_obt });

        var sum_credits = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_credits = sum_credits;
          else sum_credits += item.credit;
        });
        // console.log(sum_credits);
        setTotalCredits({ credits: sum_credits });

        var absent = 0;
        result?.forEach((item) => {
          if (item.i_absent == 1 || item.e_absent == 1) {
            absent++;
          }
        });

        var flag = 0;
        for (const item of result) {
          if (item.i_absent == 1 || item.e_absent == 1) {
            flag = 1;
            break;
          }
          if (
            item.e_min > item.e_grace + item.e_marks &&
            item.e_grace != null &&
            item.e_grace != 0
          ) {
            flag = 1;
            // console.log("grace min " + item.e_min);
            // console.log("grace " + item.subject);
            // console.log("grace mark " + item.e_grace);
            // console.log("grace flag " + flag);
            break;
          } else if (
            item.e_min > item.e_marks &&
            (item.e_grace == null || item.e_grace == 0)
          ) {
            flag = 1;
            // console.log("min flag" + flag);
            break;
          }
        }
        if (!flag) {
          setIsPass(1);
        } else {
          setIsPass(0);
        }
        // if(!flag) setIsPass(1)

        // console.log(absent);
        setTotalAbsent({ absent: absent });

        setFlag(1);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <div className="Enrollment-area mt-5 pb-70 ">
      <div className="container p-5" ref={componentRef}>
        <br />

        {/* <h5 class="text-uppercase text-center">
          Previous Provisional Statement of Marks and Grades of{" "}
          {examResults?.data[0]?.program || "UG"} Examination &nbsp;
          {months[Number(examResults?.data[0]?.month) - 1] || "JAN" || "MARCH"}-
          {examResults?.data[0]?.year || "2023"}
        </h5> */}

        <br />

        {examResults?.examStatus == "DIFF" ? (
          <div>
            {examResults?.data[0]?.college_id == 1111008 ? (
              <div className="text-center alert alert-danger">
                <h5>
                  Please Check Previous Pharmacy Results{" "}
                  <Link to={"/prevPharmresults"} className="text-danger">
                    here
                  </Link>
                </h5>{" "}
              </div>
            ) : examResults?.data[0]?.college_id == 1111000 ? (
              <div className="text-center alert alert-danger">
                <h5>
                  Please Check Previous Engineering Results{" "}
                  <Link to={"/prevEnggresults"} className="text-danger">
                    Here
                  </Link>
                </h5>{" "}
              </div>
            ) : null}
          </div>
        ) : examResults?.examStatus == "PUBLISHED" && status == "PUBLISHED" ? (
          <div className="table-responsive ">
            {/* <table className="table table-bordered result ">
              <thead>
                <tr>
                  <th>Enrollment Number</th>
                  <td>{examResults?.data[0]?.student_id}</td>
                  <th>Student Name </th>
                  <td>{examResults?.data[0]?.name}</td>
                </tr>
                <tr>
                  <th>Faculty Name</th>
                  <td>{examResults?.data[0]?.college}</td>

                  <th>Program</th>
                  <td>{examResults?.data[0]?.department}</td>
                </tr>
                <tr>
                  <th>College Name</th>
                  <td>
                    {
                      facultyData.find(
                        (item) => item.id == examResults?.data[0]?.college_id
                      )?.colloge_name
                    }
                  </td>

                  <th>
                    {examResults?.data[0]?.department == "B.H.M.S."
                      ? "Year"
                      : examResults?.data[0]?.department == "B.Sc. Nursing"
                      ? "Year"
                      : examResults?.data[0]?.department ==
                        "Post Basic B.Sc. Nursing"
                      ? "Year"
                      : "Semester"}
                  </th>

                  <td>
                    {examResults?.data[0]?.class_id}

                    {
                      semesterOpt
                        ?.filter((s) => s.class_id == currentclass)
                        ?.filter((s) => s.id == currentSemester)[0]?.name
                    }
                  </td>
                </tr>
              </thead>
            </table> */}

            <table className="table result table-bordered ">
              <thead>
                <tr>
                  <th rowSpan="2" className="text-center">
                    Course Name
                  </th>

                  {totalcredits?.credits != 0 ? (
                    <th rowSpan="2" className="text-center">
                      Credit
                    </th>
                  ) : (
                    ""
                  )}

                  <th className="text-center" colSpan="3">
                    {" "}
                    {examResults?.data[0]?.college_id == "1111002" ||
                    examResults?.data[0]?.college_id == "1111007" ? (
                      "University"
                    ) : (
                      <span>
                        {" "}
                        University Exam <br />
                        {totalsum_imarks?.i_marks == 0 ||
                        totalsum_imarks?.i_marks == null
                          ? " "
                          : "Internal Evaluation"}{" "}
                      </span>
                    )}
                  </th>
                  {examResults?.data[0]?.department != "B.H.M.S." ? (
                    <th rowSpan="2" className="text-center">
                      Total
                    </th>
                  ) : (
                    ""
                  )}
                  {totalcredits?.credits != 0 ? (
                    <th rowSpan="2" className="text-center">
                      {" "}
                      Grade
                    </th>
                  ) : (
                    ""
                  )}
                  {totalcredits?.credits != 0 ? (
                    <th rowSpan="2" className="text-center">
                      {" "}
                      GP
                    </th>
                  ) : (
                    ""
                  )}
                  {totalcredits?.credits != 0 ? (
                    <th rowSpan="2" className="text-center">
                      {" "}
                      CGP
                    </th>
                  ) : (
                    ""
                  )}
                  <th rowSpan="2" className="text-center">
                    {" "}
                    Result
                  </th>
                </tr>
                <tr>
                  <th className="text-center">Max</th>
                  <th className="text-center">Min</th>
                  <th className="text-center">Obtained</th>
                </tr>
              </thead>
              <tbody>
                {examResults &&
                  examResults?.data?.map((i, key) => (
                    <tr key={key}>
                      <td>
                        {i?.sub_code} - {i?.subject}
                      </td>
                      {totalcredits?.credits != 0 ? (
                        <td className="text-center">{i?.credit} </td>
                      ) : (
                        ""
                      )}

                      <td className="text-center">
                        {i?.e_max == 0 ? "-" : i?.e_max} <br />{" "}
                        {i?.i_max == 0 ? "-" : i?.i_max}{" "}
                      </td>

                      <td className="text-center">
                        {i?.e_min == 0 ? "-" : i?.e_min} <br />{" "}
                        {i?.i_min == 0 ? "-" : i?.i_min}{" "}
                      </td>

                      <td className="text-center">
                        {i?.e_absent == 1 ? (
                          <span className="text-danger">
                            <b>A</b>
                          </span>
                        ) : !i?.e_marks ? (
                          "-"
                        ) : (
                          i?.e_marks + (i?.e_grace ? "+" + i?.e_grace : "")
                        )}{" "}
                        <br />
                        {i?.i_absent == 1 ? (
                          <span className="text-danger">
                            <b>A</b>
                          </span>
                        ) : i?.i_max == null ? (
                          ""
                        ) : (
                          i?.i_marks
                        )}
                      </td>
                      {totalcredits?.credits != 0 ? (
                        <td className="text-center">
                          {i?.e_grace == null
                            ? Number(i?.i_marks) + Number(i?.e_marks)
                            : Number(i?.i_marks) +
                              Number(i?.e_marks) +
                              Number(i?.e_grace)}
                        </td>
                      ) : examResults?.data[0]?.department == "B.Sc. Nursing" ||
                        "Post Basic B.Sc. Nursing" ? (
                        <td className="text-center">
                          {i?.e_grace == null
                            ? Number(i?.i_marks) + Number(i?.e_marks)
                            : Number(i?.i_marks) +
                              Number(i?.e_marks) +
                              Number(i?.e_grace)}
                        </td>
                      ) : (
                        ""
                      )}
                      {totalcredits?.credits != 0 ? (
                        <td className="text-center">
                          {i?.e_grace == null
                            ? calculateGrade(
                                ((Number(i?.i_marks) +
                                  Number(i?.e_marks) +
                                  Number(i?.e_grace)) /
                                  (Number(i?.e_max) + Number(i?.i_max))) *
                                  100
                              )
                            : calculateGrade(
                                ((Number(i?.i_marks) +
                                  Number(i?.e_marks) +
                                  Number(i?.e_grace)) /
                                  (Number(i?.e_max) + Number(i?.i_max))) *
                                  100
                              )}
                        </td>
                      ) : (
                        ""
                      )}

                      {totalcredits?.credits != 0 ? (
                        <td className="text-center">
                          {i?.e_grace == null
                            ? calculateGradePoint(
                                (Number(i?.i_marks) +
                                  Number(i?.e_marks) +
                                  Number(i?.e_grace)) /
                                  (Number(i?.e_max) + Number(i?.i_max))
                              )
                            : calculateGradePoint(
                                (Number(i?.i_marks) +
                                  Number(i?.e_marks) +
                                  Number(i?.e_grace)) /
                                  (Number(i?.e_max) + Number(i?.i_max))
                              )}{" "}
                        </td>
                      ) : (
                        ""
                      )}

                      {totalcredits?.credits != 0 ? (
                        <td className="text-center">
                          {i?.e_grace == null
                            ? calculateGradePoint(
                                ((Number(i?.i_marks) +
                                  Number(i?.e_marks) +
                                  Number(i?.e_grace)) /
                                  (Number(i?.e_max) + Number(i?.i_max))) *
                                  Number(i?.credit).toFixed(2)
                              )
                            : calculateGradePoint(
                                ((Number(i?.i_marks) +
                                  Number(i?.e_marks) +
                                  Number(i?.e_grace)) /
                                  (Number(i?.e_max) + Number(i?.i_max))) *
                                  Number(i?.credit).toFixed(2)
                              )}{" "}
                        </td>
                      ) : (
                        ""
                      )}

                      <td className="text-center">
                        {i?.data?.result}{" "}
                        {(Number(i?.i_marks) >= i?.i_min &&
                          Number(i?.e_marks) + Number(i?.e_grace) >=
                            i?.e_min) ||
                        i?.e_marks == null ? (
                          <p className="text-success">
                            <b> P </b>{" "}
                          </p>
                        ) : (Number(i?.i_marks) >= i?.i_min &&
                            Number(i?.e_marks) + Number(i?.e_grace) >=
                              i?.e_min) ||
                          i?.e_marks == "null" ? (
                          <p className="text-success">
                            <b> P </b>{" "}
                          </p>
                        ) : (
                          <p className="text-danger">
                            <b>F </b>
                          </p>
                        )}{" "}
                      </td>
                    </tr>
                  ))}

                <tr>
                  <th className="text-center">Total</th>
                  {totalcredits?.credits != 0 ? (
                    <td className="text-center"> {totalcredits?.credits} </td>
                  ) : (
                    ""
                  )}

                  <td className="text-center">
                    {totalsum?.emax} <br />
                    {totalsum_imax?.imax == 0 ? "" : totalsum_imax?.imax}
                  </td>
                  {totalcredits?.credits == 0 ? (
                    <td className="text-center">
                      {examResults?.data[0]?.department == "B.H.M.S."
                        ? totalsum_emin?.emin
                        : " "}
                    </td>
                  ) : (
                    <td className="text-center">
                      - <br /> -
                    </td>
                  )}
                  <td className="text-center">
                    {totalsum_emarks?.emarks <= 0 ? (
                      <span className="text-danger">
                        <b>F</b>
                      </span>
                    ) : (
                      totalsum_emarks?.emarks
                    )}{" "}
                    <br />
                    {totalsum_imarks?.imarks == 0
                      ? ""
                      : totalsum_imarks?.imarks}
                  </td>
                  <th className="text-center">{totalsum_obt?.obt}</th>
                  {totalcredits?.credits != 0 ? <th colSpan={2}></th> : ""}

                  {totalcredits?.credits != 0 ? (
                    <th className="text-center">
                      {totalabsent?.absent >= 1
                        ? "-"
                        : examResults?.data
                            ?.reduce(
                              (total, i) =>
                                i?.is_not_countable
                                  ? total + 0
                                  : total +
                                    calculateGradePoint(
                                      (Number(i?.i_marks) +
                                        Number(i?.e_marks) +
                                        Number(i?.e_grace)) /
                                        (Number(i?.e_max) + Number(i?.i_max))
                                    ) *
                                      Number(i?.credit),
                              0
                            )
                            .toFixed(2)}
                    </th>
                  ) : (
                    ""
                  )}
                  {totalcredits?.credits != 0 ? <th></th> : ""}
                </tr>
                <tr className="text-center">
                  <th className="text-center"> Result </th>
                  <td colSpan={5} className="text-center">
                    {totalabsent?.absent >= 1 ||
                    examResults?.data?.reduce(
                      (total, i) =>
                        total +
                        (calculateGradePoint(
                          (Number(i?.i_marks) +
                            Number(i?.e_marks) +
                            Number(i?.e_grace)) /
                            (Number(i?.e_max) + Number(i?.i_max))
                        ) <
                          3.6),
                      0
                    ) ||
                    !isPass ? (
                      <p className="text-danger">
                        <b> FAIL </b>{" "}
                      </p>
                    ) : (
                      <p className="text-success">
                        <b>PASS </b>
                      </p>
                    )}
                  </td>

                  {totalcredits?.credits != 0 ? <th>SGPA:</th> : ""}

                  {totalcredits?.credits != 0 ? (
                    <th colSpan={3} className="text-center">
                      {totalabsent?.absent >= 1 ||
                      examResults?.data?.reduce(
                        (total, i) =>
                          i?.is_not_countable
                            ? total + 0
                            : total +
                              (calculateGradePoint(
                                (Number(i?.i_marks) +
                                  Number(i?.e_marks) +
                                  Number(i?.e_grace)) /
                                  (Number(i?.e_max) + Number(i?.i_max))
                              ) <
                                3.6),
                        0
                      )
                        ? "-"
                        : totalabsent?.absent >= 1
                        ? "-"
                        : (
                            examResults?.data?.reduce(
                              (total, i) =>
                                i?.is_not_countable
                                  ? total + 0
                                  : total +
                                    calculateGradePoint(
                                      (Number(i?.i_marks) +
                                        Number(i?.e_marks) +
                                        Number(i?.e_grace)) /
                                        (Number(i?.e_max) + Number(i?.i_max))
                                    ) *
                                      Number(i?.credit),
                              0
                            ) / totalcredits?.credits
                          ).toFixed(2)}
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </tbody>
            </table>
            <h5>This is Computer Generated Marksheet</h5>
          </div>
        ) : (
          <h3 className="text-center alert alert-danger">
            Your exam Result is{" "}
            {examResults?.examStatus == "HOLD"
              ? "holded back due to some reasons"
              : "not yet published"}
          </h3>
        )}

        {examResults?.examStatus == "HOLD" ? null : (
          <>
            <div className="text-center mt-4">
              <h6>Nomenclature / Abbreviations</h6>
            </div>
            <div className="d-flex justify-content-around px-5 mt-4">
              <h6> P {"->"} Pass</h6>
              <h6> F {"->"} Fail</h6>
              <h6> A {"->"} Absent</h6>
              <h6> NE {"->"} Not Eligible</h6>
            </div>
          </>
        )}

        {/* <h5 className="text-danger mt-4">Note *</h5>
                        <p><b>This is Computer Generate Statement of Marks</b></p> */}

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="float-end text-center mb-5 mt-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrevResults;
