import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  STAFF_DETAILS_NEW_GET,
  STAFF_DETAILS_NEW_PUT,
  STAFF_DETAILS_NEW_UPLOAD,
} from "../../utils/InfoUploadingApiConstants";
import DocumentsModal from "../../modals/Students/DocumentsModal";
import { getFileUrl } from "../../Helpers/Helpers";
import { ASSET_MEDIA } from "../../utils/AssetsReferenceTypes";

function StaffDetailsNew({ setLoading }) {
  const fileref = useRef(null);

  const [user, setUser] = useState({
    emp_category: "",
    attachment: "",
  });
  const [data, setData] = useState([]);

  const [edit, setEdit] = useState(0);

  const [editId, setEditId] = useState();

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  const clearData = () => {
    setUser({
      emp_category: "",
      attachment: "",
    });
    fileref.current.value = null;
  };

  const getdata = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${STAFF_DETAILS_NEW_GET}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        // setData(res.data.data);
        const data1 = res.data.data.filter(
          (item) =>
            item.status === "ACTIVE" &&
            item.college_id == sessionStorage.getItem("college_id")
        );
        setData(data1);
        data1.sort((a, b) => b.id - a.id);
        data1.forEach((element) => {
          element.attachment = JSON.parse(element.attachment);
        });
      })
      .catch((err) => {
        setLoading(0);
        // toast.error("Something Went Wrong");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!user.emp_category)
      return toast.error("Please Select Employee Category");

    if (!user.attachment) return toast.error("Please Upload Attachment File");

    const college_id = sessionStorage.getItem("college_id");

    setLoading(1);

    const existingRows = data.filter(
      (item) =>
        item.emp_category == user?.emp_category && item.college_id == college_id
    );

    if (existingRows.length > 0) {
      const promises = existingRows.map((existingRow) => {
        const updateConfig = {
          method: "put",
          url: `${STAFF_DETAILS_NEW_PUT}/${existingRow.id}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: {
            ...user, // Update all values with the new data
            college_id: college_id, // Ensure college_id is included
          },
        };
        return axios(updateConfig);
      });

      await Promise.all(promises)
        .then((responses) => {
          setLoading(0);
          toast.success("Existing rows updated successfully");
          const updatedData = data.map((item) => {
            const updatedRow = existingRows.find((row) => row.id === item.id);
            return updatedRow ? { ...item, ...user } : item;
          });
          setData(updatedData);
          clearData();
          getdata();
        })
        .catch((error) => {
          setLoading(0);
          toast.error("Failed to update existing rows");
          console.error(error);
        });
    } else {
      // No existing row found, create a new row
      const newConfig = {
        method: "post",
        url: STAFF_DETAILS_NEW_UPLOAD,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ...user,
          college_id: college_id, // Add college_id to the data
        },
      };

      axios(newConfig)
        .then((res) => {
          console.log(res);
          toast.success("Successfully Uploaded Details");
          clearData();
          getdata();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to upload new row");
        });
    }

    setLoading(0);
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleEdit = (editId) => {
    if (!user?.emp_category)
      return toast.error("Employee Category is required");

    // Update state before making the API call
    setUser((prevValue) => ({ ...prevValue }));

    setLoading(1);
    const config = {
      method: "put",
      url: `${STAFF_DETAILS_NEW_PUT}/${editId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        // getdata();
        clearData();
        getdata();
        toast.success("Success");
      })
      .catch((err) => {
        setLoading(0);
        // toast.error("Something went wrong");
      });
  };

  const handleDelete = (id) => {
    const config = {
      method: "put",
      url: `${STAFF_DETAILS_NEW_PUT}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Deleted Successfully");
        const updatedData = data.map((item) =>
          item.id == id ? { ...item, status: "INACTIVE" } : item
        );
        setData(updatedData);
        getdata();
      })
      .catch((err) => {
        setLoading(0);
        // toast.error("Something Went Wrong")
      });
  };

  const handleApprove = (id) => {
    const config = {
      method: "put",
      url: `${STAFF_DETAILS_NEW_PUT}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "ACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("ACTIVE Successfully");
        const updatedData = data.map((item) =>
          item.id === id ? { ...item, status: "ACTIVE" } : item
        );
        setData(updatedData);
      })
      .catch((err) => {
        setLoading(0);
        // toast.error("Something Went Wrong")
      });
  };

  // const handleChange1 = async (e) => {
  //   console.log(e.target.files[0]);
  //   try {
  //     const d = await getFileUrl(
  //       ASSET_MEDIA,
  //       "media",
  //       e.target.value.split(".")[1],
  //       setLoading,
  //       e.target.files[0]
  //     );
  //     user.attachment = d;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleChange1 = async (e) => {
    const file = e.target.files[0];
    console.log(file);

    // Check if a file is selected
    if (file) {
      // Get the file extension
      const fileExtension = file.name.split(".").pop().toLowerCase();

      // Check if the file extension is PDF
      if (fileExtension !== "pdf") {
        // Reset file input and show error message
        e.target.value = null;
        toast.error("Please select a PDF file");
        return;
      }

      try {
        const d = await getFileUrl(
          ASSET_MEDIA,
          "media",
          e.target.value.split(".")[1],
          setLoading,
          e.target.files[0]
        );
        user.attachment = d;
      } catch (error) {
        console.log(error);
      }
    }
  };

  console.log("data -", data);

  return (
    <>
      <DocumentsModal title={title} img={link} setLink={setLink} />
      <div ClassName="StaffDetails">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* Followup */}
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h5 className="">STAFF DETAILS</h5>
                  </div>
                </div>
              </div>
              <>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">Add Criteria</h2>
                        <br />
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">
                                Select Employee Category{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                id="section"
                                className="form-control"
                                name="emp_category"
                                value={user?.emp_category}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              >
                                <option value=""> Select </option>
                                <option value="TEACHING STAFF">
                                  {" "}
                                  TEACHING STAFF{" "}
                                </option>
                                <option value="NON-TEACHING STAFF">
                                  {" "}
                                  NON-TEACHING STAFF{" "}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">
                                Attachment<span className="text-danger">*</span>
                              </label>
                              <input
                                type="file"
                                placeholder="Upload Attachment File"
                                className="form-control"
                                name="attachment"
                                ref={fileref}
                                onChange={(e) => handleChange1(e)}
                              />
                              {user?.attachment?.length > 0 ? (
                                <button
                                  onClick={() => {
                                    setLink(user?.attachment);
                                    // setLink(`${user?.attachment}?#toolbar=0`);
                                    setTitle("Staff Details");
                                  }}
                                  data-toggle="modal"
                                  data-target="#DocumentsModal"
                                  className="btn btn-primary btn-sm my-1"
                                >
                                  View
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row float-right ">
                          {edit ? (
                            <>
                              <button
                                className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                                type="submit"
                                name="submit"
                                // style={{aspectRatio:'1/1'}}
                                onClick={() => {
                                  setEdit(0);
                                  getdata();
                                  clearData();
                                  // setAddNew(false);
                                }}
                              >
                                {"<   "}{" "}
                              </button>
                              <button
                                className="btn btn-nex btn-rounded float-right  "
                                type="submit"
                                name="submit"
                                //   onClick={(e) => handleEdit(e)}
                                onClick={() => handleEdit(editId)}
                              >
                                Save Changes
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn btn-nex btn-rounded float-right mr-4"
                              type="submit"
                              name="submit"
                              onClick={(e) => handleSubmit(e)}
                            >
                              <i className="fa fa-save" aria-hidden="true" />{" "}
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* end card */}
                  </div>
                </div>
                {/* container-fluid */}
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="card-title text-primary">
                              STAFF DETAILS LIST
                            </h3>
                          </div>
                        </div>
                        <hr />
                        <table
                          className="table table-bordered dt-responsive nowrap"
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>Sl. No.</th>
                              <th>Employee Category</th>
                              <th>Attachment</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((data, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{data?.emp_category}</td>
                                    <td>
                                      <a
                                        href={
                                          data?.attachment &&
                                          data?.attachment != '""'
                                            ? data.attachment
                                            : "#"
                                        }
                                        target={
                                          data?.attachment &&
                                          data?.attachment != '""'
                                            ? "_blank"
                                            : "_self"
                                        }
                                        style={{
                                          color:
                                            data?.attachment &&
                                            data?.attachment != '""'
                                              ? "blue"
                                              : "black",
                                        }}
                                      >
                                        {data?.attachment == '""'
                                          ? "Not Uploaded"
                                          : "View File"}
                                      </a>
                                    </td>

                                    <td
                                      className={
                                        data?.status == "ACTIVE"
                                          ? "badge badge-soft-success"
                                          : "badge badge-soft-danger"
                                      }
                                    >
                                      {data?.status}
                                    </td>

                                    <td>
                                      <span
                                        className="badge badge-light text-dark mr-3"
                                        data-toggle="tooltip"
                                        title="Edit"
                                        onClick={() => {
                                          setUser({
                                            emp_category: data?.emp_category,
                                            attachment: data?.attachment,
                                          });
                                          setEdit(1);
                                          setEditId(data?.id);
                                        }}
                                      >
                                        {" "}
                                        <i
                                          class="fa fa-edit "
                                          aria-hidden="true"
                                        ></i>
                                        {/* Edit */}
                                      </span>

                                      {data?.status == "INACTIVE" ? (
                                        <span
                                          className="text-success mr-2"
                                          onClick={() =>
                                            handleApprove(data?.id)
                                          }
                                        >
                                          <i
                                            class="fa fa-thumbs-up text-success"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      ) : (
                                        <span
                                          className="text-danger mr-2"
                                          onClick={() => handleDelete(data?.id)}
                                        >
                                          <i
                                            class="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>{" "}
                  {/* end col */}
                </div>{" "}
                {/* end row */}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffDetailsNew;
