import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFileUrl } from '../../Helpers/Helpers';
import { Http } from "../../Services/Services";
import { sessionOpt, sessionOpt as SessionOpt, } from '../../Data/jsonData/Academics/Academics';
import { LOCAL_DEPARTMENT, LOCAL_PROGRAM, LOCAL_SPECIALIZATION } from '../../utils/LocalStorageConstants';
import { GET_DEPARTMENTDATA, SYLLABUS_GET, SYLLABUS_PUT, SYLLABUS_UPLOAD } from '../../utils/InfoUploadingApiConstants';
import { ASSET_MEDIA } from '../../utils/AssetsReferenceTypes';
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SECTION, ACADEMICS_ADD_SEMESTER } from '../../utils/Academics.apiConst';



function SyllabusUpload({ setLoading }) {

    const fileref = useRef(null);

    const college_id = sessionStorage.getItem("college_id");

    console.log("college_id -", college_id);

    const role = sessionStorage.getItem("role");

    const [info, setInfo] = useState({
        session:"",
        department:"",
        program:"",
        year: "",
        semester:"",
        date:"",
        attachments:"",
        college_id:"",
    });

    // const [programOpt, setProgramOpt] = useState(getLocalProgram());
    const [data, setData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [semesterData, setSemesterData] = useState([]);
    const [faculty, setFaculty] = useState("");

    const clearData = () => {
        setInfo({
            session:"",
            // department:"",
            program:"",
            year: "",
            semester:"",
            date:"",
            college_id:"",
        });
        fileref.current.value = null;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInfo((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
    };

    const handleChange1 = async (e) => {
        console.log(e.target.files[0]);
        // let empId = Math.floor(Math.random() * 100);
        try {
          const d = await getFileUrl(
            ASSET_MEDIA,
            "media",
            e.target.value.split(".")[1],
            setLoading,
            e.target.files[0]
          );
          info.attachments = d;
        } catch (error) {
          console.log(error);
        }
      };

    // const getLocalProgram = () => {
    //     return localStorage.getItem(LOCAL_PROGRAM)
    //       ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
    //       : null;
    //   };

    // useEffect(() => {
    //     setProgramOpt(getLocalProgram());
    //   }, [localStorage.getItem(LOCAL_PROGRAM)]);

    const departmentOpt = JSON.parse(
        localStorage.getItem(LOCAL_DEPARTMENT)
    )

    const [department, setDepartment] = useState(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
    );

    useEffect(() => {
      setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
    }, [localStorage.getItem(LOCAL_DEPARTMENT), college_id]);

    // const departmentOpt = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)) || []; 

    // const filteredDepartmentOpt = departmentOpt.filter(item => item.college_id === college_id);

    // console.log("filteredDepartmentOpt -", filteredDepartmentOpt);

    const [deptdata, setDeptData] = useState([]);

  const getDepartmentData = async () => {
    const config = {
        method: "get",
        url: GET_DEPARTMENTDATA,
        headers: {
            "Content-Type": "application/json",
        },
    };

    await axios(config)
        .then((res) => {
            const activeData = res.data.data.filter(element => element.college_id === college_id);
            console.log("activeData -", activeData);
            setDeptData(activeData);
        })
        .catch((err) => {
            console.log(err);
        });
      
};

useEffect(() => {
    getDepartmentData();
}, [deptdata]);


    const programOpt = JSON.parse(
        localStorage.getItem(LOCAL_PROGRAM)
    )  

      
    const handleSubmit = async () => {

        // if (
        //   !info?.session ||
        //   !info?.date ||
        //   !info?.department ||
        //   !info?.year ||
        //   !info?.semester ||
        //   !info?.program
        // ) {
        //   toast.error("Please Enter all the required Details");
        //   return;
        // }
    
        const dataToSend = {
          ...info,
          college_id: sessionStorage.getItem("college_id"),
          department: faculty,
        };
    
        const config = {
          method: "post",
          url: SYLLABUS_UPLOAD,
          headers: {
            "Content-Type": "application/json",
            //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
          },
          // data: info,
          data: dataToSend,
        };
    
        axios(config)
          .then((res) => {
            console.log(res);
            toast.success("Succesfully Uploaded Details");
            clearData();
            getData();
          })
          .catch((err) => {
            console.log(err);
          });
    
        setLoading(0);
      };

      const getData1 = async () => {
        setLoading(true);
        const [data1, data2] = await Promise.all([
          Http.get(`${ACADEMICS_ADD_CLASS}?department_id=${faculty}`)
            .then((res) => {
              return res.data.data;
            })
            .catch((err) => {
              console.log(err);
              return [];
            }),
          Http.get(ACADEMICS_ADD_SEMESTER)
            .then((res) => {
              return res.data.data;
            })
            .catch((err) => {
              console.log(err);
              return [];
            }),
        ]);
        setClassData(data1);
        setSemesterData(data2);
        setLoading(false);
      };

      useEffect(() => {
        getData1()
      }, []);

      const getData = async () => {
        const config = {
            method: "get",
            url: SYLLABUS_GET,
            headers: {
                "Content-Type": "application/json",
            },
        };
    
        await axios(config)
            .then((res) => {
                // res.data.data.sort((a, b) => b.id - a.id);
                
                const data1 = res.data.data.filter(item => 
                  item.college_id == sessionStorage.getItem("college_id"));
                setData(data1);
                data1.sort((a, b) => b.id - a.id);
                data1.forEach((element) => {
                  element.attachments = JSON.parse(element.attachments);
                });
              })
            .catch((err) => {
                console.log(err);
            });
    };
    
    useEffect(() => {
        getData();
    }, []);
    
    const handleDelete = (id) => {
      const config = {
          method: 'put',
          //   url: `${BASE_URL}/api/infouploading/updateAdmissionNotifications/${id}`,
          url: `${SYLLABUS_PUT}/${id}`,
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
              "Content-Type": "application/json",
          },
          data: {
              status: 'INACTIVE'
          }
      }
    
      axios(config)
          .then(res => {
              setLoading(0)
              toast.success("Success")
              const updatedData = data.map(item => (item.id === id ? { ...item, status: 'INACTIVE' } : item));
              setData(updatedData);
          })
          .catch(err => {
              setLoading(0)
              toast.error("Something Went Wrong")
          })
    }
    
    const handleApprove = (id) => {
      const config = {
          method: 'put',
          //   url: `${BASE_URL}/api/infouploading/updateAdmissionNotifications/${id}`,
          url: `${SYLLABUS_PUT}/${id}`,
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
              "Content-Type": "application/json",
          },
          data: {
              status: 'ACTIVE'
          }
      }
    
      axios(config)
          .then(res => {
              setLoading(0)
              toast.success("Success")
              const updatedData = data.map(item => (item.id === id ? { ...item, status: 'ACTIVE' } : item));
              setData(updatedData);
          })
          .catch(err => {
              setLoading(0)
              toast.error("Something Went Wrong")
          })
    }

    // Helper function to get department name by ID
  // const getDepartmentNameById = (id) => {
  //   const department = departmentOpt.find((item) => item.id === id);
  //   return department ? department.name : "";
  // };

  console.log("info?.program -", info?.program);
  console.log("dept-program_id -", (departmentOpt
    ?.filter((item) => item.program_id == info.program && item.college_id == college_id)));

    let homeoprgmData = [
      {
        id: "CERTIFICATE",
        title: "CERTIFICATE",
      },
      {
        id: "DIPLOMA",
        title: "DIPLOMA",
      },
      {
        id: "PG_DIPLOMA",
        title: "PG DIPLOMA",
      },
      {
        id: "UG",
        title: "UG",
      },
      {
        id: "PG/MASTER",
        title: "PG / MASTER",
      },
      {
        id: "DOCTORATE",
        title: "DOCTORATE",
      },
    ]
  
    let homeoData = [
      {
        id: "UG-Anatomy",
        title: "Anatomy",
        value: "Anatomy",
      },
      {
        id: "UG-Physiology",
        title: "Physiology",
        value: "Physiology",
      },
      {
        id: "UG-Pathology & Microbiology",
        title: "Pathology & Microbiology",
        value: "Pathology & Microbiology",
      },
      {
        id: "UG-Forensic Medicine & Toxicology",
        title: "Forensic Medicine & Toxicology",
        value: "Forensic Medicine & Toxicology",
      },
      {
        id: "UG-Surgery",
        title: "Surgery",
        value: "Surgery",
      },
      {
        id: "UG-Obstetrics & Gynaecology",
        title: "Obstetrics & Gynaecology",
        value: "Obstetrics & Gynaecology",
      },
      {
        id: "UG-Practice of Medicine",
        title: "Practice of Medicine",
        value: "Practice of Medicine",
      },
      {
        id: "UG-Community Medicine",
        title: "Community Medicine",
        value: "Community Medicine",
      },
      {
        id: "UG-Homeopathic Pharmacy",
        title: "Homeopathic Pharmacy",
        value: "Homeopathic Pharmacy",
      },
      {
        id: "UG-Organon of Medicine",
        title: "Organon of Medicine",
        value: "Organon of Medicine",
      },
      {
        id: "UG-Materia Medica",
        title: "Materia Medica",
        value: "Materia Medica",
      },
      {
        id: "UG-Repertory",
        title: "Repertory",
        value: "Repertory",
      },
      {
        id: "UG-Yog",
        title: "Yog",
        value: "Yog",
      },
  
      {
        id: "PG/MASTER-ORGANON OF MEDICINE",
        title: "Organon of Medicine",
        value: "ORGANON OF MEDICINE"
      },
      {
        id: "PG/MASTER-REPERTORY",
        title: "Repertory",
        value: "REPERTORY",
      },
      {
        id: "PG/MASTER-MATERIA",
        title: "Materia Medica",
        value: "MATERIA MEDICA",
      },
    ];

    let homeoClassData = [
      {
        id: "1",
        title: "1st Year",
      },
      {
        id: "2",
        title: "2nd Year",
      },
      {
        id: "3",
        title: "3rd Year",
      },
      {
        id: "4",
        title: "4th Year",
      },
    ]

    let homeoSemData = [
      {
        id: "1",
        title: "1st Sem",
      },
      {
        id: "1",
        title: "2nd Sem",
      },
      {
        id: "2",
        title: "3rd Sem",
      },
      {
        id: "2",
        title: "4th Sem",
      },
      {
        id: "3",
        title: "5th Sem",
      },
      {
        id: "3",
        title: "6th Sem",
      },
      {
        id: "4",
        title: "7th Sem",
      },
      {
        id: "4",
        title: "8th Sem",
      },
    ]

  return (
    <div>
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h3 className="mb-0"> UPLOAD SYLLABUS </h3>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">

                              {/* <div className="col-md-4">
                                <h4 className="card-title">Upload Syllabus</h4>
                              </div> */}

                                <div className="row">

                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label htmlFor="validationCustom02">
                                          Academic Year<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <select
                                          className="form-control"
                                          name="session"
                                          id="class"
                                          value={info.session}
                                          onChange={handleChange}
                                        >
                                        <option value="">Select Session</option>
                                          {SessionOpt.map((i, key) => (
                                            <option value={i.id} key={key}>
                                              {i.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label htmlFor="validationCustom02">
                                          Program<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <select
                                          className="form-control"
                                          name="program"
                                          id="program"
                                          value={info.program}
                                          onChange={handleChange}
                                        >
                                        <option value="">Select Program</option>
                                        {role === "HOMOWEB" ? (
                                          <>
                                          {homeoprgmData
                                            ?.map((i, key) => (
                                              <option value={i.id} key={key}>
                                                {i.title}
                                              </option>
                                          ))}
                                          </>
                                        ) : (
                                          programOpt.map((i, key) => (
                                            <option value={i.id} key={key}>
                                              {i.name}
                                            </option>
                                          ))
                                        )}
                                        </select>
                                      </div>
                                    </div>
                                    
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label htmlFor="validationCustom02">
                                          Department<span style={{ color: "red" }}>*</span>
                                        </label>
                                        

                                        <select
                            name="faculty"
                            id="section"
                            className="form-control"
                            // value={info.department}
                            // onChange={handleChange}
                            value={faculty}
                            onChange={(e) => {
                              setFaculty(e.target.value);
                            }}
                          >
                            <option value="" selected>Select Department</option>
                            { role === "HOMOWEB" ? (
                              homeoData
                              ?.filter((s) => s.id.startsWith(info?.program))
                              ?.map((i) => (
                              <option key={i.id} value={i.id}>{i.title}</option>
                              ))
                              )
                              : (
                               department
                                  ?.filter((s) => s.college_id == college_id && s.program_id == info.program)
                                  ?.map((i, key) => (
                                    <option value={i.id} key={key}>
                                      {i.name}
                                    </option>
                                  ))
                                )}
                          </select>

                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label htmlFor="validationCustom02">
                                          Class<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <select
                                          className="form-control"
                                          name="year"
                                          id="class"
                                          value={info.year}
                                          onChange={handleChange}
                                        >
                                        <option value="">Select Class</option>

                                        { role === "HOMOWEB" ? (
                                          <>
                                          {homeoClassData
                                            ?.map((i, key) => (
                                              <option value={i.id} key={key}>
                                                {i.title}
                                              </option>
                                          ))}
                                          </>
                                        ) : (
                                          classData
                                          ?.filter(
                                            (s) => s?.department_id == faculty
                                          )
                                          ?.map((i, key) => (
                                            <option value={i.id} key={key}>
                                              {i.name}
                                            </option>
                                          ))
                                        )
                                      }  
                                        </select>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label htmlFor="validationCustom02">
                                          Semester<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <select
                                          className="form-control"
                                          name="semester"
                                          id="class"
                                          value={info.semester}
                                          onChange={handleChange}
                                        >
                                        <option value="">Select Semester</option>
                                        { role === "HOMOWEB" ? (
                                          <>
                                          {homeoSemData
                                            ?.filter((item) => item.id == info.year)
                                            ?.map((i, key) => (
                                              <option value={i.title} key={key}>
                                                {i.title}
                                              </option>
                                          ))}
                                          </>
                                        ) : (
                                        semesterData
                                        ?.filter((item) => item.class_id == info.year)
                                        .map((i, key) => (
                                            <option key={key} value={i.id}>
                                              {i.name}
                                            </option>
                                          ))
                                        )}
                                        </select>
                                      </div>
                                    </div>

                                    {/* <div className="col-md-3">
                                      <div className="form-group">
                                        <label htmlFor="validationCustom02">
                                          Date<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                          type="date"
                                          className="form-control"
                                          name="date"
                                          value={info.date}
                                          onChange={handleChange}
                                        />
                                        
                                      </div>
                                    </div> */}

                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label htmlFor="validationCustom02">
                                          Attachments<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                          type="file"
                                          className="form-control"
                                          placeholder="Enter PG Marks"
                                          name="attachments"
                                          ref={fileref}
                                          onChange={(e) => handleChange1(e)}
                                        //   value={info.attachments}
                                        //   onChange={handleChange}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                        <button
                                        className="btn btn-success float-right"
                                        id="submit"
                                        onClick={handleSubmit}
                                        >
                                            Submit
                                        </button>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className='card'>
                    
                    <div class="card-header">
                      <h4 class="text-primary"> UPLOADED SYLLABUS LIST </h4>
                    </div>

                    <div className='card-body'>

                    <table className="table table-bordered">

                      <thead>
                      <tr>
                        <th>Sl. No.</th>
                        <th>Session</th>
                        <th>Program</th>
                        <th>Department</th>
                        <th>Class</th>
                        <th>Semester</th>
                        <th>Attachments</th>
                        <th>Status</th>
                        <th>Action</th>                                              
                      </tr>
                      </thead>

                      <tbody>

                        {
                          data && data
                          // .filter((item) => item.college_id === college_id)
                          ?.map((item, key) => {
                            console.log("Semester Data:", semesterData);
                            return (

                              <tr>
                                
                                <td>{key + 1}</td>
                                <td>{item?.session}</td>
                                {/* <td>{item?.program}</td> */}
                                <td>
                                { role === "HOMOWEB" ? (
                                  <>
                                  {item?.program}
                                  </>
                                ) : (
                                  programOpt?.find(
                                    (s) => s.id == item.program
                                  )?.name
                                )                                  
                                }
                              </td>
                                {/* <td>
                                  {item?.department}
                                </td> */}
                                <td>
                                { role === "HOMOWEB" ? (
                                  <>
                                  {item?.department}
                                  </>
                                ) : (
                                  departmentOpt.find(
                                    (s) => s.id == item.department
                                  )?.name
                                )}
                              </td>
                                {/* <td>{item?.year}</td> */}
                                <td>
                                { role === "HOMOWEB" ? (
                                  
                                  homeoClassData?.find(
                                    (s) => s.id == item.year
                                  )?.title
                                  
                                ) : ( 
                                  classData?.find(
                                    (s) => s.department_id == item.department
                                  )?.name
                                )}
                              </td>
                                {/* <td>
                                  {item?.semester}
                                </td> */}
                                <td>
                                { role === "HOMOWEB" ?
                                (
                                  <>
                                    {item?.semester}
                                  </>
                                ) : (
                                  semesterData?.find(
                                    (s) => s.id == item.semester
                                  )?.name
                                )}
                              </td>
                                <td><a href={item?.attachments} target="_blank">View Uploaded File</a></td>
                                <td>{item?.status}</td>
                                <td>{item?.status == "INACTIVE" ? <button className="btn btn-danger mr-2" onClick={() => handleApprove(item?.id)}>INACTIVE</button>
                                    : <button className="btn btn-success mr-2" onClick={() => handleDelete(item?.id)}>ACTIVE</button>}</td>
                                  
                                    
                              </tr>

                            )
                          })
                        }
                      </tbody>

                    </table>

                  </div>
                  <br/>
                  <br/>
                    </div>
                  </div>
                </div>

                </div>
            </div>
        </div>

    </div>
  )
}

export default SyllabusUpload;