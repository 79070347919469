import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { getFileUrl } from "../../Helpers/Helpers";
import { Http } from "../../Services/Services";
import {
  COMPANY_DETAILS_GET,
  PLACEMENTS_GET,
  PLACEMENTS_GET2,
  PLACEMENTS_PUT,
  PLACEMENTS_UPLOAD,
} from "../../utils/InfoUploadingApiConstants";
import { ASSET_MEDIA } from "../../utils/AssetsReferenceTypes";
import { STUDENT_ADMISSION } from "../../utils/apiConstants";
import Select from "react-select";
import DocumentsModal from "../../modals/Students/DocumentsModal";

function StudentsPlacements({ setLoading }) {
  const fileref = useRef(null);
  const [user, setUser] = useState({
    company: "",
    student_id: "", // Set an initial value for student_id
    package: "",
    year: "",
    student_name: "",
    enrollment_no: "",
    student_picture: "",
  });
  const [data, setData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [studentData, setStudentData] = useState([]);

  const [edit, setEdit] = useState(0);

  const [editId, setEditId] = useState();

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  const clearData = () => {
    setUser({
      company: "",
      student_id: "",
      package: "",
      year: "",
      student_name: "",
      enrollment_no: "",
      student_picture: "",
    });
    fileref.current.value = null;
  };

  const getdata = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PLACEMENTS_GET2}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        // setData(res.data.data);
        const data3 = res.data.data.filter(
          (item) =>
            item.status == "ACTIVE" &&
            item.college_id == sessionStorage.getItem("college_id")
        );
        setData(data3);
        data3.forEach((element) => {
          element.student_picture = JSON.parse(element.student_picture);
        });
      })
      .catch((err) => {
        setLoading(0);
        // toast.error("Something Went Wrong");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   console.log("Previous User State:", user); // Log the previous state

  //   setUser((prevValue) => {
  //     const updatedState = {
  //       ...prevValue,
  //       // [name]: value,
  //       [name]: name === "student_id" ? value.value : value,

  //       // Update student_name when student_id changes
  //       student_name:
  //         name === "student_id"
  //           ? value.label.split(" - ")[0]
  //           : prevValue.student_name,
  //       enrollment_no:
  //         name === "student_id"
  //           ? value.label.split(" - ")[1]
  //           : prevValue.enrollment_no,
  //     };

  //     console.log("Updated User State:", updatedState); // Log the updated state

  //     return updatedState;
  //   });
  // };

  const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      user.student_picture = d;
    } catch (error) {
      console.log(error);
    }
  };

  const getData1 = async () => {
    setLoading(true);
    try {
      const response = await Http.get(`${COMPANY_DETAILS_GET}`);
      const data1 = response.data.data.filter(
        (item) =>
          item.status === "ACTIVE" &&
          item.college_id == sessionStorage.getItem("college_id")
      );
      setCompanyData(data1);
    } catch (error) {
      console.error(error);
      setCompanyData([]); // Set an empty array or handle the error as needed
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData1();
  }, []);

  const getData2 = async () => {
    setLoading(true);
    try {
      const response = await Http.get(`${STUDENT_ADMISSION}`);
      const data2 = response.data.data.filter(
        (item) =>
          item.status === "ACTIVE" &&
          item.college_id == sessionStorage.getItem("college_id")
      );
      setStudentData(data2);
    } catch (error) {
      console.error(error);
      setStudentData([]); // Set an empty array or handle the error as needed
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData2();
  }, []);

  const handleSubmit = () => {
    setLoading(1);

    const config = {
      method: "post",
      url: `${PLACEMENTS_UPLOAD}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        company: user.company,
        // student_id: user.student_id.value, // Extract the value property
        // student_name: user.student_id.label.split(" - ")[0],
        // enrollment_no: user.student_id.label.split("-")[1].replace(/[()]/g, ""),
        student_name: user.student_name,
        package: user.package,
        year: user.year,
        college_id: sessionStorage.getItem("college_id"),
        student_name: user.student_name,
        student_picture: user.student_picture,
        enrollment_no: user.enrollment_no,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        getdata();
        toast.success("Successfully Uploaded Details");
        getdata();
        // Clear the user state to reset the form
        clearData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleEdit = (editId) => {
    //   if (!user?.companyname) return toast.error("name is required");

    // Update state before making the API call
    setUser((prevValue) => ({ ...prevValue }));

    setLoading(1);
    const config = {
      method: "put",
      url: `${PLACEMENTS_PUT}/${editId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        getdata();
        clearData();
        toast.success("Successfully Updated Details");
      })
      .catch((err) => {
        setLoading(0);
        // toast.error("Something went wrong");
      });
  };

  const handleDelete = (id) => {
    const config = {
      method: "put",
      url: `${PLACEMENTS_PUT}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("INACTIVE Successfully");
        const updatedData = data.map((item) =>
          item.id === id ? { ...item, status: "INACTIVE" } : item
        );
        setData(updatedData);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  const handleApprove = (id) => {
    const config = {
      method: "put",
      url: `${PLACEMENTS_PUT}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "ACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("ACTIVE Successfully");
        const updatedData = data.map((item) =>
          item.id === id ? { ...item, status: "ACTIVE" } : item
        );
        setData(updatedData);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  const years = Array.from(
    { length: new Date().getFullYear() - 2010 + 1 },
    (_, i) => (2010 + i).toString()
  ).reverse();

  console.log("placementsData -", data);
  console.log("user?.student_picture -", user?.student_picture);

  return (
    <>
      <DocumentsModal title={title} img={link} setLink={setLink} />
      <div ClassName="SudentsPlacements">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* Followup */}
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="text-primary">PLACEMENTS DETAILS</h4>
                  </div>
                </div>
              </div>
              <>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">Add Criteria</h2>
                        <br />

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">
                                Company Names{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                name="company"
                                id="name"
                                value={user?.company}
                                onChange={handleChange}
                              >
                                <option value="">Select Company</option>
                                {companyData.map((i, key) => (
                                  <option value={i.id} key={key}>
                                    {i.companyname}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">
                                Student Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              {/* <Select
                              id="name"
                              name="student_id"
                              value={studentData.find(
                                (i) => i.id === user.student_id
                              )}
                              onChange={(selectedOption) => {
                                setUser((prevValue) => ({
                                  ...prevValue,
                                  student_id: selectedOption,
                                }));
                              }}
                              options={studentData.map((i) => ({
                                value: i.id,
                                // label: i.name,
                                label: `${i.name} - (${i.user_id})`, // Display both name and user_id
                              }))}
                              placeholder="Search Student Name"
                            /> */}

                              <input
                                type="text"
                                placeholder="Enter Student Name"
                                className="form-control"
                                name="student_name"
                                value={user?.student_name}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">
                                Enrollment No{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="int"
                                placeholder="Enter Student Enrollment Number"
                                className="form-control"
                                name="enrollment_no"
                                value={user?.enrollment_no}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">Student Photo</label>
                              <input
                                type="file"
                                placeholder="Upload Photo"
                                className="form-control"
                                name="student_picture"
                                ref={fileref}
                                onChange={(e) => handleChange1(e)}
                              />
                              {user?.student_picture?.length > 0 ? (
                                <button
                                  onClick={() => {
                                    setLink(user?.student_picture);
                                    setTitle("Student Picture");
                                  }}
                                  data-toggle="modal"
                                  data-target="#DocumentsModal"
                                  className="btn btn-primary btn-sm my-1"
                                >
                                  View
                                </button>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">
                                Package <span className="text-danger">*</span>
                              </label>
                              <input
                                type="int"
                                placeholder="Enter Package"
                                className="form-control"
                                name="package"
                                value={user?.package}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Select Year
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-control"
                                name="year"
                                id="year"
                                value={user.year}
                                onChange={handleChange}
                              >
                                <option value=""> Select Year </option>
                                {years.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row float-right mt-5">
                          {edit ? (
                            <>
                              <button
                                className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                                type="submit"
                                name="submit"
                                // style={{aspectRatio:'1/1'}}
                                onClick={() => {
                                  setEdit(0);
                                  getdata();
                                  clearData();
                                  // setAddNew(false);
                                }}
                              >
                                {"<   "}{" "}
                              </button>
                              <button
                                className="btn btn-nex btn-rounded float-right  "
                                type="submit"
                                name="submit"
                                //   onClick={(e) => handleEdit(e)}
                                onClick={() => handleEdit(editId)}
                              >
                                Save Changes
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn btn-nex btn-rounded float-right mr-4"
                              type="submit"
                              name="submit"
                              onClick={(e) => handleSubmit(e)}
                            >
                              <i className="fa fa-save" aria-hidden="true" />{" "}
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* end card */}
                  </div>
                </div>
                {/* container-fluid */}
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="card-title text-primary">
                              CAMPUS PLACEMENTS LIST
                            </h3>
                          </div>
                        </div>
                        <hr />
                        <table
                          className="table table-bordered dt-responsive nowrap"
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>Sl. No.</th>
                              <th>Company Names</th>
                              <th>Student Name</th>
                              <th>Enrollment No</th>
                              <th>Student Picture</th>
                              <th>Package</th>
                              <th>Year</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((data, key) => {
                              return (
                                <tr key={key}>
                                  <td>{key + 1}</td>

                                  <td>{data?.companyname}</td>
                                  <td>{data?.student_name}</td>
                                  <td>{data?.enrollment_no}</td>
                                  <td>
                                    <a
                                      href={
                                        data?.student_picture &&
                                        data?.student_picture != '""'
                                          ? data.student_picture
                                          : "#"
                                      }
                                      target={
                                        data?.student_picture &&
                                        data?.student_picture != '""'
                                          ? "_blank"
                                          : "_self"
                                      }
                                      style={{
                                        color:
                                          data?.student_picture &&
                                          data?.student_picture != '""'
                                            ? "blue"
                                            : "black",
                                      }}
                                    >
                                      {data?.student_picture == '""' ||
                                      data?.student_picture == null
                                        ? "Not Uploaded"
                                        : "View File"}
                                    </a>
                                  </td>
                                  <td>{data?.package}</td>
                                  <td>{data?.year}</td>
                                  <td
                                    className={
                                      data?.status == "ACTIVE"
                                        ? "badge badge-soft-success"
                                        : "badge badge-soft-danger"
                                    }
                                  >
                                    {data?.status}
                                  </td>

                                  <td>
                                    <span
                                      className="badge badge-light text-dark mr-3"
                                      data-toggle="tooltip"
                                      title="Edit"
                                      onClick={() => {
                                        setUser({
                                          company: data?.company,
                                          // student_id: data?.student_id,
                                          package: data?.package,
                                          year: data?.year,
                                          student_name: data?.student_name,
                                          student_picture:
                                            data?.student_picture,
                                          enrollment_no: data?.enrollment_no,
                                        });
                                        setEdit(1);
                                        setEditId(data?.id);
                                      }}
                                    >
                                      {" "}
                                      <i
                                        class="fa fa-edit "
                                        aria-hidden="true"
                                      ></i>
                                      {/* Edit */}
                                    </span>

                                    {data?.status == "INACTIVE" ? (
                                      <span
                                        className="mr-2"
                                        onClick={() => handleApprove(data?.id)}
                                      >
                                        <i
                                          class="fa fa-thumbs-up text-success"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="mr-2 text-danger"
                                        onClick={() => handleDelete(data?.id)}
                                      >
                                        <i
                                          class="fa fa-thumbs-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>{" "}
                  {/* end col */}
                </div>{" "}
                {/* end row */}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentsPlacements;
