export const college_title = 'Swaminarayan University'
export const college_footer_title = 'Swaminarayan University'
export const company_footer_title = 'Nexenstial LLP'
export const company_footer_href = 'https://www.nexenstial.com'
export const college_logo = 'url'
export const college_address = 'url'
export const college_phone_1 = 'url'
export const college_phone_2 = 'url'
export const college_email = 'url'
export const college_fax = 'url'
export const college_fav_icon = 'url'
export const college_signature = {
    sign1:'',
    sign2:'',
    sign3:'',
}


export const Varible1 = process.env.UNIVERSITY_NAME=="SUK"? "SUK" : "XYZ"