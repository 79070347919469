import axios from "axios";
import React, { useState, useEffect } from "react";
import { PREV_ENGG_EXAM_RESULT } from "../../../utils/apiConstants";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import "./style.scss";
import img1 from "./favicon.png";
import { useNavigate } from "react-router-dom";

function PrevResultsEngg({ setLoading, studentData, user }) {
  const componentRef = useRef();

  const calculateGradePoint = (p) => {
    return parseFloat(p * 10).toFixed(2);
  };

  const [faculty, setFaculty] = useState(studentData?.college_id);
  const [prog, setProg] = useState(studentData?.program_id);
  const [depart, setDepart] = useState(studentData?.department_id);
  const [currentclass, setCurrentClass] = useState(user?.class_id);
  const [currentSemester, setCurrentSemester] = useState(user?.sem_id);
  const [status, setStatus] = useState("");

  //Faculty data
  const facultyData = [
    {
      id: 1111000,
      name: "FACULTY OF ENGINEERING AND TECHNOLOGY",
      college_type_id: "ENG",
      status: "ACTIVE",
      colloge_name:
        "Shree Swaminarayan College Engineering and Technology,Kalol",
    },
    {
      id: 1111001,
      name: "FACULTY OF AYURVEDA",
      college_type_id: "AYU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Ayurvedic College,Kalol",
    },
    {
      id: 1111002,
      name: "FACULTY OF LAW",
      college_type_id: "LAW",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Law College, Kalol",
    },
    {
      id: 1111003,
      name: "FACULTY OF NURSING",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  College of Nursing, Kalol",
    },
    {
      id: 1111004,
      name: "FACULTY OF ARTS AND HUMANITIES ",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Arts  College, Kalol",
    },
    {
      id: 1111005,
      name: "FACULTY OF COMMERCE AND MANAGEMENT",
      college_type_id: "COM",
      status: "ACTIVE",
      colloge_name: "FACULTY OF COMMERCE AND MANAGEMENT,Kalol",
    },
    {
      id: 1111010,
      name: "Faculty of Education",
      college_type_id: "EDU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan B.Ed College, Kalol",
      program_name: "Bachelor of Education ",
    },
    {
      id: 1111011,
      name: "FACULTY OF SCIENCE",
      college_type_id: "SCI",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  Science College, Kalol",

      program_name: "Bachelor of Science ",
    },
    {
      id: 1111007,
      name: "FACULTY OF HOMEOPATHY",
      college_type_id: "HOMEOPATHY",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Homoeopathy College, Kalol",
    },
  ];

  const gparef = [
    {
      min: 85,
      max: 100,
      grade: "AA",
      gp: 10,
    },
    {
      min: 75,
      max: 84.99,
      grade: "AB",
      gp: 9,
    },
    {
      min: 65,
      max: 74.99,
      grade: "BB",
      gp: 8,
    },
    {
      min: 55,
      max: 64.99,
      grade: "BC",
      gp: 7,
    },
    {
      min: 45,
      max: 54.99,
      grade: "CC",
      gp: 6,
    },
    {
      min: 40,
      max: 44.99,
      grade: "CD",
      gp: 5,
    },
    {
      min: 35,
      max: 39.99,
      grade: "DD",
      gp: 4,
    },
    {
      min: 0,
      max: 34.99,
      grade: "FF",
      gp: 0,
    },
  ];

  const [studentId, setStudentId] = useState(studentData?.user_id);
  const [sessionId, setSessionId] = useState(user?.session);

  const [flag, setFlag] = useState(0);

  const [examResults, setExamResults] = useState([]);
  const [totalsum, setTotalSum] = useState();
  const [totalsum_emin, setTotalSumEmin] = useState();
  const [isPass, setIsPass] = useState(0);
  const [totalsum_imin, setTotalSumImin] = useState();
  const [totalsum_imax, setTotalSumImax] = useState();
  const [totalsum_mtmin, setTotalSumMtmin] = useState();
  const [totalsum_mtmax, setTotalSumMtmax] = useState();
  const [totalsum_vivamin, setTotalSumVivamin] = useState();
  const [totalsum_vivamax, setTotalSumVivamax] = useState();
  const [totalsum_imarks, setTotalSumImarks] = useState();
  const [totalsum_emarks, setTotalSumEmarks] = useState();
  const [totalsum_mtmarks, setTotalSumMtmarks] = useState();
  const [totalsum_vivamarks, setTotalSumVivamarks] = useState();
  const [totalsum_obt, setTotalSumobt] = useState();
  const [sgpaObt, setSgpaObt] = useState(0);
  const [totalabsent, setTotalAbsent] = useState();
  const [totalcredits, setTotalCredits] = useState();
  const [grade, setGrade] = useState();

  const maptoHundred = (num, max) => (100 * Number(num)) / Number(max);

  const calculateGradePointEachSub = (num, max) => {
    // console.log(d);
    let marks = maptoHundred(num, max);
    for (const i of gparef) {
      if (i.min <= marks && i.max >= marks) {
        // console.log(i, marks);
        return i;
      }
    }
  };

  const analyseResults = () => {
    for (const i of examResults?.data) {
      if (i?.e_min > i?.e_marks + i?.e_grace) return false;
      if (i?.i_min > i?.i_marks) return false;
      if (i?.mt_min > i?.mt_marks) return false;
      if (i?.viva_min > i?.viva_marks) return false;
      if (calculateGradePointEachSub(i?.viva_marks, i?.viva_max)?.gp == 0)
        return false;
      if (calculateGradePointEachSub(i?.i_marks, i?.i_max)?.gp == 0)
        return false;
      if (
        calculateGradePointEachSub(i?.e_marks + i?.e_grace, i?.e_max)?.gp == 0
      )
        return false;
      if (calculateGradePointEachSub(i?.mt_marks, i?.mt_max)?.gp == 0)
        return false;
    }
    console.log("result is true");

    return true;
  };

  const getDraftResults = async () => {
    if (
      !studentId ||
      !sessionId ||
      !faculty ||
      !prog ||
      !depart ||
      !currentclass ||
      !currentSemester
    )
      return toast.error("Please fill Student Id and Session");
    if (!studentId || !sessionId)
      return toast.error("Please fill Student Id and Session");
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${PREV_ENGG_EXAM_RESULT}?student_id=${studentData?.user_id}&session_id=${user?.session}&class_id=${user?.class_id}&semester=${user?.sem_id}&college_id=${studentData?.college_id}`,
      //   url: `${EXAM_RESULT_ENG}?student_id=${studentId}&session_id=${sessionId}`,
    };

    await axios(config)
      .then(async (res) => {
        console.log("Here", res.data.data);
        setStatus(res.data.data.examStatus);
        if (res.data.data.data.length === 0)
          return toast.error("No Data Found");

        const result = Object.values(
          res.data.data.data.reduce((acc, curr) => {
            const subject = curr.sub_code;
            if (!acc[subject]) {
              acc[subject] = { ...curr };
            } else {
              acc[subject].e_marks += curr.e_marks;
              acc[subject].e_max += curr.e_max;
              acc[subject].e_min += curr.e_min;
              if (acc[subject].e_grace != null || acc[subject].e_grace == 0) {
                acc[subject].e_grace += curr.e_grace;
              }
              acc[subject].credit += curr.credit;
              acc[subject].i_marks += curr.i_marks;
              acc[subject].i_max += curr.i_max;
              acc[subject].i_min += curr.i_min;
              acc[subject].viva_marks += curr.viva_marks;
              acc[subject].viva_max += curr.viva_max;
              acc[subject].viva_min += curr.viva_min;
            }
            return acc;
          }, {})
        );

        if (result.length == 0) {
          toast.error("Please Check Entered Details");
          return;
        }
        await setExamResults({ ...res.data.data, data: result });
        // console.log(result);
        var gradep = 0;
        res.data.data.data?.forEach((j) => {
          gradep +=
            calculateGradePointEachSub(
              j.e_marks + j?.i_marks + j?.mt_marks + j?.viva_marks,
              j.e_max + j?.i_max + j?.mt_max + j?.viva_max
            )?.gp * j.credit
              ? calculateGradePointEachSub(
                  j.e_marks + j?.i_marks + j?.mt_marks + j?.viva_marks,
                  j.e_max + j?.i_max + j?.mt_max + j?.viva_max
                )?.gp * j.credit
              : 0;
        });
        // console.log('gpppppppppppp', gradep);
        setGrade(gradep);

        // console.log(result);
        var sgpagrade = 0;
        var sgpacredit = 0;
        res.data.data.data?.forEach((j) => {
          if (j.is_not_countable || j.e_marks < j.e_min) {
            sgpagrade = sgpagrade;
            sgpacredit = sgpacredit;
          } else {
            sgpagrade +=
              calculateGradePointEachSub(
                j.e_marks + j?.i_marks + j?.mt_marks + j?.viva_marks,
                j.e_max + j?.i_max + j?.mt_max + j?.viva_max
              )?.gp * j.credit
                ? calculateGradePointEachSub(
                    j.e_marks + j?.i_marks + j?.mt_marks + j?.viva_marks,
                    j.e_max + j?.i_max + j?.mt_max + j?.viva_max
                  )?.gp * j.credit
                : 0;
            sgpacredit += j.credit;
          }
        });

        setSgpaObt({
          grade: sgpagrade,
          credit: sgpacredit,
          sgpa: sgpagrade / sgpacredit,
        });

        var sum = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum = sum;
          else sum += item.e_max;
        });
        setTotalSum({ emax: sum });

        var sum_emin = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_emin = sum_emin;
          else sum_emin += item.e_min;
        });
        setTotalSumEmin({ emin: sum_emin });

        var sum_imin = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_imin = sum_imin;
          else sum_imin += item.i_min;
        });
        setTotalSumImin({ imin: sum_imin });

        //midterm min marks
        var sum_mtmin = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_mtmin = sum_mtmin;
          else sum_mtmin += item?.mt_min;
        });
        setTotalSumMtmin({ mtmin: sum_mtmin });

        //midterm max marks
        var sum_mtmax = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_mtmax = sum_mtmax;
          else sum_mtmax += item?.mt_max;
        });
        setTotalSumMtmax({ mtmax: sum_mtmax });

        //Viva min marks
        var sum_vivamin = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_vivamin = sum_vivamin;
          else sum_vivamin += item?.viva_min;
        });
        setTotalSumVivamin({ vivamin: sum_vivamin });

        //midterm max marks
        var sum_vivamax = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_vivamax = sum_vivamax;
          else sum_vivamax += item?.viva_max;
        });
        setTotalSumVivamax({ vivamax: sum_vivamax });

        var sum_imax = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_imax = sum_imax;
          else sum_imax += item.i_max;
        });
        setTotalSumImax({ imax: sum_imax });

        var sum_imarks = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_imarks = sum_imarks;
          else sum_imarks += item.i_marks;
        });
        setTotalSumImarks({ imarks: sum_imarks });

        var sum_mtmarks = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_mtmarks = sum_mtmarks;
          else sum_mtmarks += item.mt_marks;
        });
        setTotalSumMtmarks({ mtmarks: sum_mtmarks });

        var sum_vivamarks = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_vivamarks = sum_vivamarks;
          else sum_vivamarks += item.viva_marks;
        });
        setTotalSumVivamarks({ vivamarks: sum_vivamarks });

        var sum_emarks = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_emarks = sum_emarks;
          else sum_emarks += item.e_marks;
        });
        setTotalSumEmarks({ emarks: sum_emarks });

        var sum_obt = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_obt = sum_obt;
          else
            sum_obt +=
              item.e_marks +
              item.i_marks +
              item?.e_grace +
              item?.mt_marks +
              item?.viva_marks;
        });
        console.log("obt" + sum_obt);
        setTotalSumobt({ obt: sum_obt });

        var sum_credits = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_credits = sum_credits;
          else sum_credits += item.credit;
        });
        console.log(sum_credits);
        setTotalCredits({ credits: sum_credits });

        var absent = 0;
        res.data.data.data?.forEach((item) => {
          if (item.i_absent == 1 || item.e_absent == 1) {
            absent++;
          }
        });
        var flag = 0;
        for (const item of result) {
          if (item.i_absent == 1 || item.e_absent == 1) {
            flag = 1;
            break;
          }
          if (
            item.e_min > item.e_grace + item.e_marks &&
            item.e_grace != null &&
            item.e_grace != 0
          ) {
            flag = 1;
            console.log("grace min " + item.e_min);
            console.log("grace " + item.subject);
            console.log("grace mark " + item.e_grace);
            console.log("grace flag " + flag);
            break;
          } else if (
            item.e_min > item.e_marks &&
            (item.e_grace == null || item.e_grace == 0)
          ) {
            flag = 1;
            console.log("min flag" + flag);
            break;
          } else if (item.i_min > item.i_max) {
            flag = 1;
            console.log("internal flag" + flag);
            break;
          } else if (item.viva_min > item.viva_max) {
            flag = 1;
            console.log("internal flag" + flag);
            break;
          } else if (item.mt_min > item.mt_max) {
            flag = 1;
            console.log("internal flag" + flag);
            break;
          }
        }
        console.log("ispass" + isPass);
        if (!flag) {
          setIsPass(1);
        } else {
          setIsPass(0);
        }

        setTotalAbsent({ absent: absent });

        setFlag(1);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  useEffect(() => {
    getDraftResults();
  }, [user]);

  console.log(examResults);
  return (
    <div className="Enrollment-area pb-70 ">
      <div className="container p-5" ref={componentRef}>
        <br />

        {/* <h5 class="text-uppercase text-center">
          Previous Provisional Statement of Marks and Grades of{" "}
          {(examResults?.data?.length > 0 && examResults?.data?.[0]?.program) ||
            "UG"}{" "}
          Examination &nbsp;
          {months[Number(examResults?.data?.[0]?.month) - 1] ||
            "JAN" ||
            "MARCH"}
          -{examResults?.data[0]?.year || "2023"}
        </h5> */}
        <br />

        {examResults?.examStatus == "PUBLISHED" && status == "PUBLISHED" ? (
          <div className="table-responsive ">
            {/* <table className="table table-bordered result ">
              <thead>
                <tr>
                  <th>Enrollment Number</th>
                  <td>{examResults?.data?.[0]?.student_id}</td>
                  <th>Student Name </th>
                  <td>{examResults?.data?.[0]?.name}</td>
                </tr>
                <tr>
                  <th>Faculty Name</th>
                  <td>{examResults?.data?.[0]?.college}</td>

                  <th>Program</th>
                  <td>{examResults?.data?.[0]?.department}</td>
                </tr>
                <tr>
                  <th>College Name</th>
                  <td>
                    {
                      facultyData.find(
                        (item) => item.id == examResults?.data?.[0]?.college_id
                      )?.colloge_name
                    }
                  </td>

                  <th>
                    {examResults?.data[0]?.department == "B.H.M.S."
                      ? "Year"
                      : examResults?.data[0]?.department == "B.Sc. Nursing"
                      ? "Year"
                      : examResults?.data[0]?.department ==
                        "Post Basic B.Sc. Nursing"
                      ? "Year"
                      : "Semester"}
                  </th>

                  <td>
                    {" "}
                    {examResults?.data[0]?.department == "B.H.M.S."
                      ? "1st Year"
                      : examResults?.data[0]?.department == "B.Sc. Nursing"
                      ? "1st Year"
                      : examResults?.data[0]?.department ==
                        "Post Basic B.Sc. Nursing"
                      ? "1st Year"
                      : "1"}{" "}
                  </td>
                </tr>
              </thead>
            </table> */}

            <table className="table result table-bordered ">
              <thead>
                <tr>
                  <th rowSpan="2" className="text-center">
                    Course Name
                  </th>

                  {totalcredits?.credits != 0 ? (
                    <th rowSpan="2" className="text-center">
                      Credit
                    </th>
                  ) : (
                    ""
                  )}

                  <th colSpan={2} className="text-center" rowSpan={1}>
                    Theory Component
                  </th>
                  <th colSpan={2} className="text-center" rowSpan={1}>
                    Practical Component
                  </th>
                  <th rowSpan={2} className="text-center">
                    Theory Grade
                  </th>
                  <th rowSpan={2} className="text-center">
                    Practical Grade
                  </th>
                  <th rowSpan={2} className="text-center">
                    Subject Grade
                  </th>
                </tr>
                <tr>
                  <th className="text-center">ESE</th>
                  <th className="text-center">PA</th>
                  <th className="text-center">ESE</th>
                  <th className="text-center">PA</th>
                </tr>
              </thead>
              <tbody>
                {examResults &&
                  examResults?.data?.map((i, key) => (
                    <tr key={key}>
                      <td>
                        {i?.sub_code} - {i?.subject}
                      </td>
                      {totalcredits?.credits != 0 ? (
                        <td className="text-center">{i?.credit} </td>
                      ) : (
                        ""
                      )}
                      <td className="text-center">
                        {i?.e_min > i.e_marks + i?.e_grace
                          ? "FF"
                          : calculateGradePointEachSub(
                              i?.e_marks + i?.e_grace,
                              i?.e_max
                            )
                          ? calculateGradePointEachSub(
                              i?.e_marks + i?.e_grace,
                              i?.e_max
                            )?.grade
                          : "-"}
                      </td>
                      <td className="text-center">
                        {i?.mt_min > i.mt_marks
                          ? "FF"
                          : calculateGradePointEachSub(i?.mt_marks, i?.mt_max)
                          ? calculateGradePointEachSub(i?.mt_marks, i?.mt_max)
                              ?.grade
                          : "-"}
                      </td>
                      <td className="text-center">
                        {i?.i_min > i.i_marks
                          ? "FF"
                          : calculateGradePointEachSub(i?.i_marks, i?.i_max)
                          ? calculateGradePointEachSub(i?.i_marks, i?.i_max)
                              ?.grade
                          : "-"}
                      </td>
                      <td className="text-center">
                        {i?.viva_min > i.viva_marks
                          ? "FF"
                          : calculateGradePointEachSub(
                              i?.viva_marks,
                              i?.viva_max
                            )
                          ? calculateGradePointEachSub(
                              i?.viva_marks,
                              i?.viva_max
                            )?.grade
                          : "-"}
                      </td>
                      <td className="text-center">
                        {i?.e_min > i.e_marks + i?.e_grace
                          ? "FF"
                          : i?.mt_min > i.mt_marks
                          ? "FF"
                          : calculateGradePointEachSub(
                              i?.e_marks + i?.e_grace,
                              i?.e_max
                            )?.gp == 0
                          ? "FF"
                          : calculateGradePointEachSub(i?.mt_marks, i?.mt_max)
                              ?.gp == 0
                          ? "FF"
                          : calculateGradePointEachSub(
                              i?.e_marks + i?.e_grace + i?.mt_marks,
                              i?.e_max + i?.mt_max
                            )
                          ? calculateGradePointEachSub(
                              i?.e_marks + i?.e_grace + i?.mt_marks,
                              i?.e_max + i?.mt_max
                            )?.grade
                          : "-"}
                      </td>
                      <td className="text-center">
                        {i?.i_min > i.i_marks
                          ? "FF"
                          : i?.viva_min > i.viva_marks
                          ? "FF"
                          : calculateGradePointEachSub(
                              i?.viva_marks,
                              i?.viva_max
                            )?.gp == 0
                          ? "FF"
                          : calculateGradePointEachSub(i?.i_marks, i?.i_max)
                              ?.gp == 0
                          ? "FF"
                          : calculateGradePointEachSub(
                              i?.i_marks + i?.viva_marks,
                              i?.i_max + i?.viva_max
                            )
                          ? calculateGradePointEachSub(
                              i?.i_marks + i?.viva_marks,
                              i?.i_max + i?.viva_max
                            )?.grade
                          : "-"}
                      </td>
                      <td className="text-center">
                        {/* {i.subject} */}
                        {i.subject == "Induction Program"
                          ? "PS"
                          : i?.e_min > i.e_marks + i?.e_grace
                          ? "FF"
                          : i?.mt_min > i.mt_marks
                          ? "FF"
                          : i?.i_min > i.i_marks
                          ? "FF"
                          : i?.viva_min > i.viva_marks
                          ? "FF"
                          : calculateGradePointEachSub(
                              i?.e_marks + i?.e_grace,
                              i?.e_max
                            )?.gp == 0
                          ? "FF"
                          : calculateGradePointEachSub(i?.mt_marks, i?.mt_max)
                              ?.gp == 0
                          ? "FF"
                          : calculateGradePointEachSub(
                              i?.viva_marks,
                              i?.viva_max
                            )?.gp == 0
                          ? "FF"
                          : calculateGradePointEachSub(i?.i_marks, i?.i_max)
                              ?.gp == 0
                          ? "FF"
                          : calculateGradePointEachSub(
                              i?.e_marks +
                                i?.e_grace +
                                i?.mt_marks +
                                i?.i_marks +
                                i?.viva_marks,
                              i?.i_max + i?.viva_max + i?.e_max + i?.mt_max
                            )
                          ? calculateGradePointEachSub(
                              i?.e_marks +
                                i?.e_grace +
                                i?.mt_marks +
                                i?.i_marks +
                                i?.viva_marks,
                              i?.i_max + i?.viva_max + i?.e_max + i?.mt_max
                            )?.grade
                          : "-"}
                      </td>
                    </tr>
                  ))}

                <tr>
                  <th className="text-center">Total</th>
                  {totalcredits?.credits != 0 ? (
                    <th colSpan={4} className="text-center">
                      {" "}
                      Credits : {totalcredits?.credits}{" "}
                    </th>
                  ) : (
                    ""
                  )}
                  {totalcredits?.credits != 0 ? (
                    <th colSpan={4} className="text-center">
                      Grade Points : {sgpaObt?.grade}
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
                <tr className="text-center">
                  <th className="text-center"> Result </th>
                  <td colSpan={5} className="text-center">
                    {!analyseResults() ||
                    totalabsent?.absent >= 1 ||
                    examResults?.data?.reduce(
                      (total, i) =>
                        total +
                        (calculateGradePoint(
                          (Number(i?.i_marks) +
                            Number(i?.e_marks) +
                            Number(i?.e_grace) +
                            Number(i?.mt_marks) +
                            Number(i?.viva_marks)) /
                            (Number(i?.e_max) +
                              Number(i?.i_max) +
                              Number(i?.mt_max) +
                              Number(i?.viva_max))
                        ) <
                          3.6),
                      0
                    ) ||
                    !isPass ? (
                      <p className="text-danger">
                        <b> FAIL </b>{" "}
                      </p>
                    ) : (
                      <p className="text-success">
                        <b>PASS </b>
                      </p>
                    )}
                  </td>

                  {totalcredits?.credits != 0 ? <th>SPI:</th> : ""}

                  {totalcredits?.credits != 0 ? (
                    <th colSpan={3} className="text-center">
                      {totalabsent?.absent >= 1 ||
                      examResults?.data?.reduce(
                        (total, i) =>
                          i?.is_not_countable
                            ? total + 0
                            : total +
                              (calculateGradePoint(
                                (Number(i?.i_marks) +
                                  Number(i?.e_marks) +
                                  Number(i?.e_grace) +
                                  Number(i?.mt_marks) +
                                  Number(i?.viva_marks)) /
                                  (Number(i?.e_max) +
                                    Number(i?.i_max) +
                                    Number(i?.mt_max) +
                                    Number(i?.viva_max))
                              ) <
                                3.6),
                        0
                      )
                        ? "0"
                        : totalabsent?.absent >= 1
                        ? "0"
                        : (sgpaObt?.grade / totalcredits?.credits)?.toFixed(2)}
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <h3 className="text-center alert alert-danger">
            Exam Result is{" "}
            {examResults?.examStatus == "HOLD"
              ? "holded back due to some reasons"
              : "not yet published"}
          </h3>
        )}

        {examResults?.examStatus == "HOLD" ? null : (
          <>
            {/* <div className="text-center mt-4">
                                    <h6>Nomenclature / Abbreviations</h6>
                                </div>
                                <div className="d-flex justify-content-around px-5 mt-4">
                                    <h6> P {"->"} Pass</h6>
                                    <h6> F {"->"} Fail</h6>
                                    <h6> A {"->"} Absent</h6>
                                    <h6> NE {"->"} Not Eligible</h6>
                                </div> */}
          </>
        )}

        {/* <h5 className="text-danger mt-4">Note *</h5>
                        <p><b>This is Computer Generate Statement of Marks</b></p> */}

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="float-end text-center mb-5 mt-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrevResultsEngg;
