import React from 'react';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from "react-select";
import { ACADEMIC_CALENDAR_CREATE, ACADEMIC_CALENDAR_GET, ACADEMIC_CALENDAR_UPDATE } from '../../utils/InfoUploadingApiConstants';
import { sessionOpt } from '../../Data/jsonData/Academics/Academics';
import { ASSET_MEDIA } from '../../utils/AssetsReferenceTypes';
import { getFileUrl } from '../../Helpers/Helpers';

function AcademicCalendar({ setLoading }) {

  const fileref = useRef(null);

//   const [attachments, setAttachments] = useState([]);

  const college_id = sessionStorage.getItem("college_id");

  console.log("college_id -", college_id);

  const role = sessionStorage.getItem("role");

  const [data, setData] = useState([]);

  const [session, setSession] = useState("");

  const [info, setInfo] = useState({
    // session: "",
    date: "",
    attachments: "",
    type: "",
    classes: "",
  });

  const clearData = () => {
    setInfo({
    //   session: "",
      date: "",
      attachments: "",
      type: "",
      classes : "",
    });
    fileref.current.value = null;
  };

  
const handleSubmit = async () => {
  if (!info?.type || !info.attachments) {
      toast.error("Please Enter all the required Details");
      return;
  }

  const college_id = sessionStorage.getItem("college_id");

  setLoading(1);

  const existingRows = data.filter(item => item.type === info.type);

  // if (existingRows.length > 0 && info.type === "Fee Structure") {
    if (existingRows.length > 0 && info.type !== "Academic Calendar") {
      const promises = existingRows.map(existingRow => {
          const updateConfig = {
              method: 'put',
              url: `${ACADEMIC_CALENDAR_UPDATE}/${existingRow.id}`,
              headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                  "Content-Type": "application/json",
              },
              data: {
                  status: 'INACTIVE',
              }
          };
          return axios(updateConfig);
      });

      await Promise.all(promises)
          .then(responses => {
              setLoading(0);
              toast.success("Existing rows updated to INACTIVE");
              const updatedData = data.map(item => {
                  if (existingRows.find(row => row.id === item.id)) {
                      return { ...item, status: 'INACTIVE' };
                  }
                  return item;
              });
              setData(updatedData);
          })
          .catch(error => {
              setLoading(0);
              toast.error("Failed to update existing rows");
              console.error(error);
          });
  }

  // Get current date
  const currentDate = new Date().toISOString();

  // Proceed to create the new row
  const newConfig = {
      method: "post",
      url: ACADEMIC_CALENDAR_CREATE,
      headers: {
          "Content-Type": "application/json",
      },
      data: {
          ...info,
          date: currentDate,
          session: session,
          college_id: college_id, // Add college_id to the data
      },
  };

  axios(newConfig)
      .then((res) => {
          console.log(res);
          toast.success("Successfully Uploaded Details");
          clearData();
          getData();
      })
      .catch((err) => {
          console.log(err);
          toast.error("Failed to upload new row");
      });

  setLoading(0);
};

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    // let empId = Math.floor(Math.random() * 100);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      info.attachments = d;
    } catch (error) {
      console.log(error);
    }
  };


  const getData = async () => {
    const collegeid = sessionStorage.getItem("college_id");

    const config = {
        method: "get",
        url: ACADEMIC_CALENDAR_GET,
        headers: {
            "Content-Type": "application/json",
        },
    };

    await axios(config)
        .then((res) => {
            res.data.data.sort((a, b) => b.id - a.id);
            res.data.data.forEach((element) => {
                element.attachments = JSON.parse(element.attachments);
            });
            console.log(res.data.data)
            setData(res.data.data.filter((item) => item.college_id == collegeid))            

        })
        .catch((err) => {
            console.log(err);
        });
};

useEffect(() => {
    getData();
}, []);

const handleApprove = (id) => {
  const config = {
      method: 'put',
      url: `${ACADEMIC_CALENDAR_UPDATE}/${id}`,
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
      },
      data: {
          status: 'ACTIVE'
      }
  }

  axios(config)
      .then(res => {
          setLoading(0)
          toast.success("Success")
          const updatedData = data.map(item => (item.id === id ? { ...item, status: 'ACTIVE' } : item));
          setData(updatedData);
          getData();
      })
      .catch(err => {
          setLoading(0)
          toast.error("Something Went Wrong")
      })
}

const handleDelete = (id) => {
const config = {
    method: 'put',
    url: `${ACADEMIC_CALENDAR_UPDATE}/${id}`,
    headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
    },
    data: {
        status: 'INACTIVE'
    }
}

axios(config)
    .then(res => {
        setLoading(0)
        toast.success("Successfully Deleted")
        const updatedData = data.map(item => (item.id === id ? { ...item, status: 'INACTIVE' } : item));
        setData(updatedData);
        getData();
    })
    .catch(err => {
        setLoading(0)
        toast.error("Something Went Wrong")
    })
}

const sessionformat = {
  2025: "2025-26" ,
  2024: "2024-25" ,
  2023: "2023-24" ,
  2022: "2022-23" ,
  2021: "2021-22" ,
  2020: "2020-21" ,
  2019: "2019-20" ,
  2018: "2018-19" ,
  2017: "2017-18" ,
  2016: "2016-17" ,
  2015: "2015-16" ,
  2014: "2014-15" ,
  2013: "2013-14" ,
}

  return (
    <div>

        <div className="row">
            <div className="col-12 mt-4">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h5 className='ml-4'>Academic Calendar</h5>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-xl-12 mb-2">
                <div className="card">
                    <div className="card-body">

                        <h2 className="card-title">Select criteria</h2>
                        <br/>

                        <div className="row">

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label> session <span style={{ color: "red" }}>*</span></label>
                                    <select
                            className="form-control"
                            value={session}
                            onChange={(e) => {
                              setSession(e.target.value);
                            }}
                            // value={info?.session}
                            // onChange={(e) => {
                            //     handleChange(e);
                            // }}
                          >
                            <option value="">Select Session</option>
                            {sessionOpt?.map((i, key) => (
                              <option value={i?.id}>{i?.name}</option>
                            ))}
                            </select>
                                </div>
                            </div>

                            <div className="col-md-3">
                            <div className="form-group">
                                <label> Type <span style={{ color: "red" }}>*</span></label>
                                <select
                                    name="type"
                                    id="class"
                                    className="form-control"
                                    value={info?.type}
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                >
                                  <option value="">Select Type</option>
                                  <option value="Courses Details">Courses Details</option>
                                  <option value="Fee Structure">Fee Structure</option>
                                  <option value="Academic Calendar">Academic Calendar</option>                               
                                </select>
                            </div>
                            </div>

                          { info?.type === "Academic Calendar" && role === "HOMOWEB" && college_id === "1111007" ? (
                            <>
                              <div className="col-md-3">
                            <div className="form-group">
                                <label> Class <span style={{ color: "red" }}>*</span></label>
                                <select
                                    name="classes"
                                    id="classes"
                                    className="form-control"
                                    value={info?.classes}
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                >
                                  <option value="">Select Year</option>
                                  <option value="1st Year">1st Year</option>
                                  <option value="2nd Year">2nd Year</option>
                                  <option value="3rd Year">3rd Year</option>
                                  <option value="4th Year">4th Year</option>                               
                                </select>
                            </div>
                            </div>
                            </>
                          ) : (
                            <></>
                          )}

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="">Attachments<span style={{ color: "red" }}>*</span></label>
                                    <input type="file"
                            placeholder="Attach the file"
                            className="form-control"
                            name="attachments"
                            ref={fileref}
                            onChange={(e) => {
                              handleChange1(e);
                            }}
                          />
                                </div>
                            </div>
                            
                        </div>

                        <div className="row float-right mt-5">
                            <button
                              className="btn btn-primary mr-5"
                              type="submit"
                              name="submit"
                              onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                        <br/>
                        <br/>
                        <br/>

                    </div>
                </div>
            </div>
        </div>

        <div className="row">
              <div className="col-12">

                <div className="card">

                  <div class="card-header">
                    <h5 class="text-primary"> ACADEMIC CALENDAR LIST </h5>
                  </div>

                  <div className="card-body">
                    <br/>                     
                    
                    <div style={{ overflowX: "auto" }}>

                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Sl.No.</th>
                            <th>Session</th>
                            {/* {role === "HOMOWEB" && college_id === "1111007" ? (
                             <th>Class</th>
                            ) : (
                              <></>
                            )} */}
                            <th>Type</th>
                            <th>Attachments</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        
                        <tbody>

                          {
                            data && data?.map((item, key) => {
                            
                                return (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>
                                    {/* {item?.session} */}
                                    {sessionformat[item?.session] || item?.session}
                                  </td>
                                  {/* {role === "HOMOWEB" && college_id === "1111007" ? (
                                    <td>{item?.classes}</td>
                                  ) : (
                                    <></>
                                  )} */}
                                  <td>{item?.type}</td>
                                  <td><a href={item?.attachments} target="_blank"> View Uploaded File </a></td>
                                  <td>{new Date(item?.date).toISOString().split('T')[0]}</td>
                                  <td>{item?.status == "INACTIVE" 
                                      ? 
                                    <button className="btn btn-danger mr-2" onClick={() => handleApprove(item?.id)}>INACTIVE</button>
                                      : 
                                    <button className="btn btn-success mr-2" onClick={() => handleDelete(item?.id)}>ACTIVE</button>}
                                  </td>
                                </tr>

                              ); 
                            })
                          }
                        </tbody>
                      </table>

                    </div>
                  </div>

                </div>

            </div>
        </div>
      
    </div>
  )
}

export default AcademicCalendar;
