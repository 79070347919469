import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { APPROVALS, MILESTONES } from "../../utils/InfoUploadingApiConstants";
import { type } from "@testing-library/user-event/dist/type";
import {
  ASSET_HOMEOMEDIA,
  ASSET_MEDIA,
} from "../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../Helpers/Helpers";
import EventsDocumentsModal from "../../modals/Students/EventsDocumentsModal";
import DocumentsModal from "../../modals/Students/DocumentsModal";

const Approvals = ({ setLoading }) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  const fileref = useRef(null);

  const [info, setInfo] = useState({
    type: "",
    title: "",
    attachments: "",
    date: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const Change = async (e) => {
    let files = [];
    // return;
    for (let i of e.target.files) {
      console.log(i);
      try {
        const d = await getFileUrl(
          ASSET_HOMEOMEDIA,
          "Approvals/Recognition",
          i?.name.split(".")[1],
          setLoading,
          i
        );
        // files.push(d);
        files.push(d + "#toolbar=0"); // Append #toolbar=0 here
      } catch (error) {
        console.log(error);
      }
    }
    setAttachments(files);
  };

  const clearData = () => {
    setInfo({
      type: "",
      title: "",
      attachments: "",
      date: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {
    if (!info?.type || !info?.title) {
      toast.error("Please Enter the required Details");
      return;
    }

    info.college_id = await sessionStorage.getItem("college_id");

    setLoading(1);

    // Get current date
    const currentDate = new Date().toISOString();

    const config = {
      method: "post",
      url: APPROVALS,
      headers: {
        "Content-Type": "application/json",
        //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: {
        ...info,
        date: currentDate,
        // attachments: attachments,
      },
    };

    axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Succesfully Uploaded Details");
        getData();
        clearData();
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    if (file.type !== "application/pdf") {
      toast.error("Only PDF files are allowed");
      return;
    }
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        // e.target.files[0]
        file
      );
      //   info.attachments = d;
      info.attachments = d + "#toolbar=0"; // Append #toolbar=0 here
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    const collegeid = sessionStorage.getItem("college_id");

    const config = {
      method: "get",
      url: APPROVALS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log("hi");

    await axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);
        res.data.data.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        console.log(res.data.data);
        // setData(res.data.data);
        setData(res.data.data.filter((item) => item.college_id == collegeid));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleEdit = async () => {
    setLoading(1);
    const config = {
      method: "put",
      url: APPROVALS + `/${info.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...info,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data Updated successfully");
        getData();
        clearData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        // toast.error("Something went wrong");
      });
  };

  const handleDelete = async (deletedItem, status) => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${APPROVALS}/${deletedItem?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: status,
      },
    };
    try {
      await axios(config);
      setLoading(0);
      toast.success("Data Status Updated");
      getData();
    } catch (error) {
      setLoading(0);
      toast.error("Some Error Occurred");
    }
  };

  return (
    <div>
      <div className="Approvals">
        <EventsDocumentsModal title={title} img={link} setLink={setLink} />
        <DocumentsModal title={title} img={link} setLink={setLink} />
        <>
          <div className="card">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="ml-4 text-primary">
                    UPLOAD APPROVALS DETAILS
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 mb-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Select criteria</h2>
                  <br />
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">
                          Select Type
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name="type"
                          id="approvals"
                          className="form-control"
                          value={info?.type}
                          onChange={handleChange}
                        >
                          <option value="">Select Type</option>
                          <option value="AISHE Codes">AISHE Codes</option>
                          <option value="Ayurveda">Ayurveda</option>
                          <option value="Homoeopathy">Homoeopathy</option>
                          <option value="Engineering">Engineering</option>
                          <option value="Nursing">Nursing</option>
                          <option value="Law">Law</option>
                          <option value="Pharmacy">Pharmacy</option>
                          <option value="Science">Science</option>
                          <option value="B.Ed">B.Ed</option>
                          <option value="UGC">UGC</option>
                          <option value="AIU Membership">AIU Membership</option>
                          <option value="Arts">Arts</option>
                          <option value="Commerce">Commerce</option>
                          <option value="Physiotherapy">Physiotherapy</option>
                          <option value="Design">Design</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">
                          title <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter the Title"
                          className="form-control"
                          name="title"
                          value={info?.title}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">
                          Upload Attachments
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          multiple="multiple"
                          type="file"
                          className="form-control"
                          name="attachments"
                          placeholder="Upload the file"
                          ref={fileref}
                          onChange={(e) => {
                            handleChange1(e);
                          }}
                          //   accept="application/pdf"
                          //   onChange={(e) => Change(e)}
                        />
                        {info?.attachments?.length > 0 ? (
                          <button
                            onClick={() => {
                              setLink(info?.attachments);
                              setTitle("Uploaded Files");
                            }}
                            data-toggle="modal"
                            data-target="#DocumentsModal"
                            className="btn btn-primary btn-sm my-1"
                          >
                            View
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-md-10 ml-auto mr-5">
                      {edit == false ? (
                        <>
                          <button
                            className="btn btn-nex btn-rounded float-right "
                            type="submit"
                            name="submit"
                            onClick={handleSubmit}
                          >
                            <i className="fa fa-save" aria-hidden="true" /> Save
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-nex btn-rounded float-right "
                            type="submit"
                            name="Update"
                            onClick={handleEdit}
                          >
                            <i className="fa fa-save" aria-hidden="true" />{" "}
                            Update
                          </button>
                          <button
                            className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                            type="submit"
                            name="submit"
                            // style={{aspectRatio:'1/1'}}
                            onClick={() => {
                              setEdit(false);
                              // setAddNew(false);
                            }}
                          >
                            {"<   "}{" "}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div class="card-header">
                  <h5 class="text-primary"> APPROVALS & RECOGNITION LIST </h5>
                </div>

                <div className="card-body">
                  <div style={{ overflowX: "auto" }}>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Sl.No.</th>
                          <th>Type</th>
                          <th>Title</th>
                          <th>Attachments</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data &&
                          data?.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td width="1%">{key + 1}</td>
                                <td width="4%">{item?.type}</td>
                                <td width="22%">{item?.title}</td>
                                <td width="4%">
                                  <a href={item?.attachments} target="_blank">
                                    View Attachments
                                  </a>
                                </td>
                                <td width="4%">
                                  {item?.date ? item.date.split("T")[0] : null}
                                </td>
                                <td width="2%">
                                  {item?.status === "ACTIVE" ? (
                                    <span className="badge badge-soft-success">
                                      {item?.status}
                                    </span>
                                  ) : (
                                    <span className="badge badge-soft-danger">
                                      {item?.status}
                                    </span>
                                  )}
                                </td>
                                <td width="2%">
                                  {item?.status == "ACTIVE" ? (
                                    <>
                                      <acronym title="Edit">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            setEdit(true);
                                            setInfo({ ...item });
                                          }}
                                        >
                                          <i
                                            className="fa fa-edit "
                                            aria-hidden="true"
                                          />
                                        </a>
                                      </acronym>{" "}
                                      &nbsp;&nbsp;&nbsp;
                                      <acronym title="Inactive">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() =>
                                            handleDelete(item, "INACTIVE")
                                          }
                                        >
                                          <i
                                            className="fa fa-thumbs-down"
                                            aria-hidden="true"
                                            style={{ color: "red" }}
                                          />
                                        </a>
                                      </acronym>
                                    </>
                                  ) : (
                                    <acronym title="active">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          handleDelete(item, "ACTIVE")
                                        }
                                      >
                                        <i
                                          className="fa fa-thumbs-up"
                                          aria-hidden="true"
                                          style={{ color: "green" }}
                                        />
                                      </a>
                                    </acronym>
                                  )}
                                  <a href="javascript:void(0)"> </a>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Approvals;
