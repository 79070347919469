// ageCalculator.js

export const ageCalculator = (birthDate) => {
  const currentDate = new Date();
  const birthDateObj = new Date(birthDate);

  const years = currentDate.getFullYear() - birthDateObj.getFullYear();
  const months = currentDate.getMonth() - birthDateObj.getMonth();
  const days = currentDate.getDate() - birthDateObj.getDate();

  const formatUnit = (value, unit) => {
    if (value === 1) {
      return `${value} ${unit}`;
    } else if (value > 1) {
      return `${value} ${unit}s`;
    }
    return '';
  };

  let ageString = '';

  if (days < 0) {
    const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, birthDateObj.getDate());
    const daysInLastMonth = Math.floor((currentDate - lastMonth) / (1000 * 60 * 60 * 24));
    ageString += `${formatUnit(years, 'year')} ${formatUnit(months - 1, 'month')} ${formatUnit(daysInLastMonth, 'day')}`;
  } else {
    ageString += `${formatUnit(years, 'year')} ${formatUnit(months, 'month')} ${formatUnit(days, 'day')}`;
  }

  return ageString.trim();
};
