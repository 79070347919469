import React, { useState, useEffect } from "react";
import axios from "axios";
import { EMPLOYEE_CREDENTIALS } from "../../../utils/apiConstants";
import {
  LOCAL_COLLEGE,
  LOCAL_EMPLOYEE,
  LOCAL_DEPARTMENT,
} from "../../../utils/LocalStorageConstants";

function UserCredentials({ collegeId, setLoading }) {
  const [search, setSearch] = useState("");

  const [faculty, setFaculty] = useState("");

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const getEmployee = async () => {
    return localStorage.getItem(LOCAL_EMPLOYEE)
      ? await JSON.parse(localStorage.getItem(LOCAL_EMPLOYEE))
      : null;
  };

  const [employees, setEmployees] = useState(getEmployee());

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: EMPLOYEE_CREDENTIALS,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        setEmployees(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="row card-body">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="validationCustom01">
                          Faculty <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name="clg"
                          id="faculty"
                          className="form-control"
                          value={faculty}
                          onChange={(e) => {
                            setFaculty(e.target.value);
                          }}
                        >
                          <option value="" selected>
                            ALL
                          </option>
                          {collegeOpt?.length > 0 &&
                            collegeOpt?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <label>Search Employee By Name</label>
                      <div className="form-group">
                        <input
                          type="text"
                          name="search"
                          className="form-control"
                          placeholder="Enter Employee Name"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title">Employee Credentials</h4>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table
                        id="datatable"
                        className="table table-bordered mt-2  nowrap table-hover"
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sl No</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>College</th>
                            <th>Department</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Role</th>
                          </tr>
                        </thead>

                        <tbody>
                          {employees?.length > 0 &&
                            employees
                              ?.filter((s) => {
                                if (search == "") {
                                  return s;
                                } else if (
                                  (
                                    s.first_name?.toLowerCase() +
                                    " " +
                                    s.second_name?.toLowerCase()
                                  ).includes(search?.toLowerCase())
                                ) {
                                  return s;
                                }
                              })
                              ?.map((item, key) => {
                                return (
                                  <tr>
                                    <td>{key + 1}</td>
                                    <td>{item.first_name}</td>
                                    <td>{item.last_name}</td>
                                    <td>
                                      {
                                        collegeOpt?.filter(
                                          (s) => s.id == item?.college_id
                                        )[0]?.name
                                      }
                                    </td>
                                    <td>
                                      {
                                        department?.filter(
                                          (s) => s.id == item?.department_id
                                        )[0]?.name
                                      }
                                    </td>
                                    <td>{item?.phone}</td>
                                    <td>{item?.email}</td>
                                    <td>{item?.role}</td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserCredentials;
