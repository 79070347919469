import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Invoice from "./../invoice/Invoice";
import { toast } from "react-toastify";
import Select from "react-select";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import { ROUTES } from "../../../Router/routerConfig";
import { useNavigate} from "react-router-dom";

import {
    LOCAL_DEPARTMENT,
    LOCAL_COLLEGE,
  } from "../../../utils/LocalStorageConstants";

  import {
    ACADEMICS_ADD_CLASS,
    ACADEMICS_ADD_SECTION,
    ACADEMICS_ADD_SEMESTER,
  } from "../../../utils/Academics.apiConst";
  import {
    STUDENT_ADMISSION,
    STUDENT_DETAILS,
    STUDENT_SESSION,
    STUDENT_ADVANCE_PAY,
  } from "../../../utils/apiConstants";
  import { SESSION_ROLE } from "../../../utils/sessionStorageContants";



function InvoiceDetails({ setLoading, collegeId }) {
    let role = sessionStorage.getItem(SESSION_ROLE);
    const getCollegeData = () => {
        return localStorage.getItem(LOCAL_COLLEGE)
          ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
          : null;
      };

    const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

    const [classOpt, setClassOpt] = useState([]);

    const [sectionOpt, setSectionOpt] = useState([]);

    const [semesterOpt, setSemesterOpt] = useState([]);

    const [gender, setGender] = useState([]);

    const [data, setData] = useState([]);

    const [session, setSession] = useState("");

    const [studentData, setStudentData] = useState([]);

    const [userId, setUserId] = useState("");

    const [faculty, setFaculty] = useState("");

    const [currentclass, setCurrentClass] = useState("");
  
    const [currentSemester, setCurrentSemester] = useState("");
  
    const [currentSection, setCurrentSection] = useState("");

    const [status, setStatus] = useState("ACTIVE");    

    const [student, setStudent] = useState([]);

    const [showInvoice, setShowInvoice] = useState(false);

    const [adv, setAdv] = useState([]);

    const [x, setX] = useState(false);

    const [z, setZ] = useState(false);
    const [y, setY] = useState(false);

    const navigate = useNavigate();

    const [department, setDepartment] = useState(
        JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      );
    
      
    const handleGenerateInvoice = () => {
        setShowInvoice(true);
    };

    const getAllData = async () => {
        const config = {
          method: "get",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
    
        const [data1, data2, data3, data4] = await Promise.all([
          await axios({
            ...config,
            url: `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}`,
          })
            .then((res) => {
              setClassOpt(res.data.data);
            })
            .catch((err) => {
              console.log(err);
            }),
    
          await axios({
            ...config,
            url: `${ACADEMICS_ADD_SEMESTER}?college_id=${collegeId}`,
          })
            .then((res) => {
              setSemesterOpt(res.data.data);
            })
            .catch((err) => {
              console.log(err);
            }),
    
          await axios({
            ...config,
            url: `${ACADEMICS_ADD_SECTION}?college_id=${collegeId}`,
          })
            .then((res) => {
              setSectionOpt(res.data.data);
            })
            .catch((err) => {
              console.log(err);
            }),
    
        ]);
        setStudentData([]);
      };

      const getStudentData = async () => {
        setLoading(1);
        const config = {
          method: 'get',
          url : `${STUDENT_ADMISSION}/${userId}`,
          data : {
            user_id: userId
          },
          headers : {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          }
        }
    
        const config1 = {
          method: "get",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
    
        await axios(config)
          .then((res)=>{
            setLoading(0);
            console.log('student data - ', res.data);
            let temp = res.data;
            let obj = {};
            obj['id'] = temp?.data.id;
            obj['department_id'] = temp?.data.department_id;
            obj['class_id'] = temp?.session[temp?.session.length-1]?.class_id;
            obj['session_id'] = temp?.session[temp?.session.length-1]?.session_id;
            obj['semester_id'] = temp?.semester[temp?.semester.length-1]?.semester_id;
            obj['section_id'] = temp?.semester[temp?.semester.length-1]?.section_id;
            obj['student_id'] = temp?.data.user_id;
            obj['name'] = temp?.data.name;
            obj['email'] = temp?.data.email;
            obj['phone'] = temp?.data.phone;
            obj['dob'] = temp?.data?.dob?.substring(0,10);
            obj['gender'] = temp?.data.gender;
            obj['category'] = temp?.data.category;
            obj['is_hostel'] = temp?.session[temp?.session.length-1]?.is_hostel;
            obj['is_transport'] = temp?.session[temp?.session.length-1]?.is_transport;
            obj['status'] = temp?.data.status;
            setData([]);
            setStudentData(obj);
            console.log('obj - ', obj);
            let targetDiv = document.getElementById('datatable');
            targetDiv.scrollIntoView({ behavior: 'smooth' });
          })
          .catch((err)=> {
            setLoading(0);
            console.log(err);
          })
    
          // await axios({
          //   ...config1,
          //   url: STUDENT_ADVANCE_PAY,
          // })
          //   .then((res) => {
          //     console.log("adv - ", res.data.data);
          //     setAdv(res.data.data);
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   })
      }

      const getData = async () => {
        setStudentData([]);
        if (!faculty || !currentclass || !session)
          return toast.error("Mandatory fields are required");
        setLoading(1);
        const config = {
          method: "get",
          url:
            STUDENT_SESSION +
            `?college_id=${collegeId}&department_id=${faculty}&class_id=${currentclass}&section_id=${currentSection}&semester_id=${currentSemester}&session_id=${session}&status_student=${status}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
    
        const config1 = {
          method: "get",
          url: `${STUDENT_DETAILS}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
    
        await axios(config)
          .then((res) => {
            console.log("Data Main", res.data.data);
            setData(res.data.data);
            let data = [];
            for (var i = 0; i < res.data.data.length; i++) {
              data.push({
                id: res.data.data[i]?.user_id,
                gender: res.data.data[i]?.gender,
              });
            }
            setGender(data);
          })
          .catch((err) => {
            console.log(err);
            toast.error("Some Error Occured");
          });
    
        await axios(config1)
          .then((res) => {
            setLoading(0);
            setStudent(res.data.data);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
          });
      };

      const changeDir = (dir,i) => {
        let obj = {};
        console.log('i - ', i);
        if(i) {
            obj['class_id'] = i?.class_id;
            obj['id'] = i?.student_session_id;
            obj['student_id'] = i?.user_id;
            obj['department_id'] = i?.department_id;
            obj['is_transport'] = i?.is_transport;
            obj['is_hostel'] = i?.is_hostel;
            obj['session_id'] = i?.session_id;
            obj['status'] = i?.sem_status;
            obj['name'] = i?.name;
            obj['category'] = i?.category;
            obj['gender'] = i?.gender;
            obj['section_id'] = i?.section_id;
            obj['semester_id'] = i?.semester_id;
            obj['email'] = i?.email;
            obj['college_id'] = i?.college_id;
            obj['deparment_name'] = i?.dept_name;
        }
        navigate(
          `${dir}`,
          {state: {
            data: obj,
            student: student,
          }},
        );
      };

      const changeDir1 = (dir,i) => {
        let obj = i;
        obj['college_id'] = department.find((s)=> s.id == i.department_id)?.college_id;
        obj['deparment_name'] = department.find((s)=> s.id == i.department_id)?.name;
        navigate(
          `${dir}`,
          {state: {
            data: studentData,
            student: student,
          }},
        );
      };

      useEffect(() => {
        getAllData();
      }, []);

    // Handle changes in the department dropdown
    const handleDepartmentChange = (selectedOption) => {
    setFaculty(selectedOption.value);

    // Reset class, semester, section, and other related state values
    setCurrentClass("");
    setCurrentSemester("");
    setCurrentSection("");
    setSession("");

    // Fetch class options based on the selected department
    fetchClassOptions(selectedOption.value);
    };

    // Function to fetch class options based on the selected department
    const fetchClassOptions = async (selectedDepartment) => {
    const classOptions = await axios.get(
      `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}&department_id=${selectedDepartment}`
    );
    const formattedClassOptions = classOptions.data.data.map((c) => ({
      value: c.id,
      label: c.name,
    }));
    setClassOpt(formattedClassOptions);
  };

  // Function to fetch semester options based on the selected class
  const fetchSemesterOptions = async (selectedClass) => {
    const semesterOptions = await axios.get(
      `${ACADEMICS_ADD_SEMESTER}?college_id=${collegeId}&class_id=${selectedClass}`
    );
    const formattedSemesterOptions = semesterOptions.data.data.map((s) => ({
      value: s.id,
      label: s.name,
    }));
    setSemesterOpt(formattedSemesterOptions);
  };

  // Function to fetch section options based on the selected semester
  const fetchSectionOptions = async (selectedSemester) => {
    const sectionOptions = await axios.get(
      `${ACADEMICS_ADD_SECTION}?college_id=${collegeId}&semester_id=${selectedSemester}`
    );
    const formattedSectionOptions = sectionOptions.data.data.map((section) => ({
      value: section.id,
      label: section.name,
    }));
    setSectionOpt(formattedSectionOptions);
  };

  // Handle changes in the class dropdown
  const handleClassChange = (selectedOption) => {
    setCurrentClass(selectedOption.value);
    fetchSemesterOptions(selectedOption.value);
  };

  // Handle changes in the semester dropdown
  const handleSemesterChange = (selectedOption) => {
    setCurrentSemester(selectedOption.value);
    fetchSectionOptions(selectedOption.value);
  };


    return (<>
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="mb-0">INVOICE GENERATION</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item">
                                <a href="javascript: void(0);">Students Information</a>
                            </li>
                            <li className="breadcrumb-item active">
                                Students Details
                            </li>
                            </ol>
                        </div>
                        </div>
                    </div>
                </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Search By Enrollment No.</h2>
                    <br />
                    {/* <div className="row">
                      <div className="col-md-4">
                      <div className="form-group">
                          <label htmlFor="">Student Enrollment No.</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Student Enrollment No."
                            value={userId}
                            onChange={(e) => {
                              setUserId(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-0">
                      <button
                        href="#datatable"
                        className="btn btn-primary btn-rounded"
                        type="submit"
                        name="submit"
                        onClick={getStudentData}
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                      </button>
                    </div>
                    </div> */}
                    <div className="input-group mb-3 col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Student Enrollment No."
                            value={userId}
                            onChange={(e) => {
                              setUserId(e.target.value);
                            }}
                          />
                      <div className="input-group-append">
                      <button
                        href="#datatable"
                        className="btn btn-nex btn-rounded"
                        type="submit"
                        name="submit"
                        onClick={getStudentData}
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                        <h2 className="card-title">Select Criteria</h2>
                    <br />
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Department <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <select
                            name="faculty"
                            id="section"
                            className="form-control"
                            value={faculty}
                            onChange={(e) => {
                              setFaculty(e.target.value);
                            }}
                          >
                            <option value="" selected>
                              ALL
                            </option>
                            {department?.filter((s)=>s.college_id == collegeId)?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select> */}
                          {console.log('department - ', department)}
                          {console.log('faculty - ', faculty)}
                          <Select
                            options={department?.filter((s)=> s.college_id == collegeId)?.map((i) => ({
                              value: i.id,
                              label: i.name,
                            }))}
                            value={
                              faculty
                                ? { value: faculty, label: department.find((d) => d.id === faculty)?.name }
                                : null
                            }
                            onChange={handleDepartmentChange}
                            placeholder="Select Department"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Session</label>
                          {/* <select
                            className="form-control"
                            value={session}
                            onChange={(e) => {
                              setSession(e.target.value);
                            }}
                          >
                            <option value="">Select Session</option>
                            {sessionOpt?.map((i, key) => (
                              <option value={i?.id}>{i?.name}</option>
                            ))}
                          </select> */}

                          <Select
                            options={sessionOpt.map((i) => ({
                              value: i.id,
                              label: i.name,
                            }))}
                            value={
                              session
                              ? {
                                value: session,
                                label: sessionOpt.find((i) => i.id === session)?.name || '',
                              }
                              : null
                            }
                            onChange={(selectedOption) => setSession(selectedOption.value)}
                            placeholder="Select Session"
                          />

                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          {/* <select
                            className="form-control"
                            value={currentclass}
                            onChange={(e) => {
                              setCurrentClass(e.target.value);
                            }}
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter((s) => s?.department_id == faculty)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select> */}

                          <Select
                            options={classOpt}
                            value={classOpt.find((c) => c.value === currentclass)}
                            onChange={handleClassChange}
                            placeholder="Select Class"
                          />

                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          {/* <select
                            className="form-control"
                            value={currentSemester}
                            onChange={(e) => {
                              setCurrentSemester(e.target.value);
                            }}
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == currentclass)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select> */}

                          <Select
                            options={semesterOpt}
                            value={semesterOpt.find(
                              (s) => s.value === currentSemester
                            )}
                            onChange={handleSemesterChange}
                            placeholder="Select Semester"
                          />

                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Section</label>
                          {/* <select
                            className="form-control"
                            value={currentSection}
                            onChange={(e) => {
                              setCurrentSection(e.target.value);
                            }}
                          >
                            <option value="">Select Section</option>
                            {sectionOpt
                              ?.filter((s) => s.semester_id == currentSemester)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select> */}

                          <Select
                            options={sectionOpt}
                            value={sectionOpt.find((s) => s.value === currentSection)}
                            onChange={(selectedOption) =>
                              setCurrentSection(selectedOption.value)
                            }
                            placeholder="Select Section"
                          />

                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Status</label>
                          <select
                            className="form-control"
                            value={status}
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >
                            <option value="">Select Status</option>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row float-right mr-4">
                      <button
                        className="btn btn-nex btn-rounded"
                        type="submit"
                        name="submit"
                        onClick={getData}
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                <div className="row">
                    <div className="col-12">
                <div className="card">
                    <div className="card-body">

                <div className="table-responsive">
                      <hr />
                      <table
                        id="datatable"
                        className="table table-bordered  nowrap table-hover"
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sl No</th>
                            <th>Enrollment No.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Date of Birth</th>
                            <th style={{ width: "10rem" }}>Gender</th>
                            {role != "STAFF" ? (
                              <>
                                <th>Category</th>
                                <th>Hostel</th>
                                <th>Transport</th>
                                <th style={{ minWidth: "100px" }}>Action</th>
                              </>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {data && data.length !== 0 && student.length != 0 ? (
                            data?.map((i, key) => (
                              <tr>
                                <td>{key + 1}</td>
                                <td>{i?.user_id}</td>
                                <td>{i?.name}</td>
                                <td>{i?.email}</td>
                                <td>{i?.phone}</td>
                                <td>{i?.dob?.split("T")[0]}</td>
                                <td>{i?.gender}</td>
                                <td>{i?.category}</td>
                                <td>{i?.is_hostel == 1?"YES":"NO"}</td>
                                <td>{i?.is_transport == 1? "YES" : "NO"}</td>
                                <td>
                                    <button
                                        className="btn btn-nex btn-rounded"
                                        type="submit"
                                        name="submit"
                                        onClick={() => {
                                            changeDir(
                                                ROUTES.Registar.Admission.Invoice, i
                                                );
                                        }}
                                    >Generate Invoice</button>
                                 </td>
                              </tr>
                            ))
                          ) : studentData.length !== 0 ? (
                            <tr>
                              <td>1</td>
                              <td>{studentData?.student_id}</td>
                              <td>{studentData?.name}</td>
                              <td>{studentData?.email}</td>
                              <td>{studentData?.phone}</td>
                              <td>{studentData?.dob}</td>
                              <td>{studentData?.gender}</td>
                              <td>{studentData?.category}</td>
                              <td>{studentData?.is_hostel == 1?"YES":"NO"}</td>
                              <td>{studentData?.is_transport == 1? "YES" : "NO"}</td>
                              <td>
                                <button
                                    className="btn btn-nex btn-rounded"
                                    type="submit"
                                    name="submit"
                                    onClick={() => {
                                    changeDir1(
                                        ROUTES.Registar.Admission.Invoice,studentData
                                        );
                                    }}
                                >Generate Invoice</button>
                            </td>
                            </tr>
                          ) :(
                            <tr>
                              {" "}
                              <td colSpan={13}>
                                <div align="center" className="text-danger">
                                  No data available in table <br /> <br />
                                  <img
                                    src="/assets/images/addnewitem.svg"
                                    width={150}
                                  />
                                  <br />
                                  <br />{" "}
                                  <span className="text-success bolds">
                                    <i className="fa fa-arrow-left" /> Add new
                                    record or search with different criteria.
                                  </span>
                                  <div />
                                </div>
                              </td>
                            </tr>
                          )} 
                        </tbody>
                      </table>
                    </div>
                    </div>
                </div>
                </div>
                </div>
                
                </div>
            </div>
        </div>
    <div className="row">
        {showInvoice && <Invoice />}
    </div>
    </>
    )
}

export default InvoiceDetails;