import React from 'react'
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { getFileUrl } from '../../Helpers/Helpers';
import { ASSET_MEDIA } from '../../utils/AssetsReferenceTypes';
import { ROUTES } from "../../Router/routerConfig"
import { useNavigate } from 'react-router-dom';
import { COLLEGE_AFFILIATION_GET, COLLEGE_AFFILIATION_PUT, COLLEGE_AFFILIATION_UPLOAD } from '../../utils/InfoUploadingApiConstants';

function CollegeAffiliation({ setLoading }) {

  const [data, setData] = useState([]);

  const navigate = useNavigate()

  const fileref = useRef(null);
  let role = sessionStorage.getItem("role");
  const [info, setInfo] = useState({
    title: "",
    attachments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearData = () => {
    setInfo({
      title: "",
      attachments: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {
    if (!info?.title || !info?.attachments) {
      toast.error("Please Enter all the required Details");
      return;

    }

    setLoading(1);

    // Retrieve college_id from session storage
    const college_id = sessionStorage.getItem("college_id")
    console.log("college_id",college_id);

    const config = {
      method: "post",
      url: COLLEGE_AFFILIATION_UPLOAD,
      headers: {
        "Content-Type": "application/json",
        //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: {
        ...info,
        college_id: college_id, // Add college_id to the data
      },
    };

    axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Succesfully Uploaded Details");
        clearData();
        getData();
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    let empId = Math.floor(Math.random() * 100);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      info.attachments = d;
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    const config = {
        method: "get",
        url: COLLEGE_AFFILIATION_GET,
        headers: {
            "Content-Type": "application/json",
        },
    };

    await axios(config)
        .then((res) => {
            // res.data.data.sort((a, b) => b.id - a.id);
            // res.data.data.forEach((element) => {
            //     element.attachments = JSON.parse(element.attachments);
            // });
            // console.log(res.data.data)
            // setData(res.data.data);
            const data2 = res.data.data.filter(item => item.college_id == sessionStorage.getItem("college_id"));
            setData(data2);
            data2.sort((a, b) => b.id - a.id);
            data2.forEach((element) => {
                element.attachments = JSON.parse(element.attachments);
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

useEffect(() => {
    getData();
}, []);

const handleDelete = (id) => {
  const config = {
      method: 'put',
      url: `${COLLEGE_AFFILIATION_PUT}/${id}`,
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
      },
      data: {
          status: 'INACTIVE'
      }
  }

  axios(config)
      .then(res => {
          setLoading(0)
          toast.success("Success")
          const updatedData = data.map(item => (item.id === id ? { ...item, status: 'INACTIVE' } : item));
          setData(updatedData);
      })
      .catch(err => {
          setLoading(0)
          toast.error("Something Went Wrong")
      })
}

const handleApprove = (id) => {
  const config = {
      method: 'put',
      url: `${COLLEGE_AFFILIATION_PUT}/${id}`,
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
      },
      data: {
          status: 'ACTIVE'
      }
  }

  axios(config)
      .then(res => {
          setLoading(0)
          toast.success("Success")
          const updatedData = data.map(item => (item.id === id ? { ...item, status: 'ACTIVE' } : item));
          setData(updatedData);
      })
      .catch(err => {
          setLoading(0)
          toast.error("Something Went Wrong")
      })
}

  return (
    <div>
      <div className="container-fluid mt-3 ">
      <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">Upload Affiliation Letter</h4>
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            <a href="javascript: void(0);">Home</a>
                          </li>
                          <li className="breadcrumb-item active">
                            {" "}
                            <a href="javascript:void(0)">Upload Affiliation Letter</a>
                          </li>
                          
                        </ol>
                      </div>
                    </div>
                  </div>
                 
                </div>
        <div className="card">
          <div className="card-body">
            <div className="row d-flex align-items-center">
              
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Year of Affiliation </label>
                  <input type="text"
                    placeholder="example : 2022-2023"
                    className="form-control"
                    name="title"
                    value={info?.title}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Attachments</label>
                  <input type="file"
                    placeholder="Attach the file"
                    className="form-control"
                    name="attachments"
                    ref={fileref}
                    onChange={(e) => {
                      handleChange1(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <br></br>
                <button className='btn btn-success ' id="submit" onClick={handleSubmit}>Submit</button>

              </div>

            </div>
          </div>
        </div>

      </div>


                {/* <div className="container-fluid">
                  <div className="row"> */}

                    <div className='card'>
                    

                    <div class="card-header">
                      <h4 class="text-primary"> College Affiliation List </h4>
                    </div>

                    <div className='card-body'>

                    <table className="table table-bordered">
                      <tr>
                        <th><h5>Sl.No.</h5></th>
                        <th><h5>Title</h5></th>
                        <th><h5>Attachments</h5></th>
                        <th><h5>Status</h5></th>
                        <th><h5>Action</h5></th>                                              
                      </tr>

                      <tbody>

                        {
                          data && data?.map((item, key) => {
                            return (

                              <tr>                                
                                <td>{key + 1}</td>    
                                <td>{item?.title}</td>
                                <td><a href={item?.attachments} target="_blank">View uploaded Document</a></td>
                                <td>{item?.status}</td>
                                <td>{item?.status == "INACTIVE" ? <button className="btn btn-danger mr-2" onClick={() => handleApprove(item?.id)}>DELETE</button>
                                    : <button className="btn btn-success mr-2" onClick={() => handleDelete(item?.id)}>ACTIVE</button>}</td>                                                                  
                              </tr>

                            )
                          })
                        }
                      </tbody>

                    </table>

                  </div>
                </div>

              {/* </div>
              </div> */}

    </div>
  )
}

export default CollegeAffiliation;
