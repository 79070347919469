//Sessio or Year
export const SessionOpt = [
  {
    name: '2027-28',
    id: '2027'
  },
  {
    name: '2026-27',
    id: '2026'
  },
  {
    name: '2025-26',
    id: '2025'
  },
  {
    name: '2024-25',
    id: '2024'
  },
  {
    name: '2023-24',
    id: '2023'
  },
  {
    name: '2022-23',
    id: '2022'
  },
  {
    name: '2021-22',
    id: '2021'
  },
  {
    name: '2020-21',
    id: '2020'
  },
  {
    name: '2019-20',
    id: '2019'
  },
  {
    name: '2018-19',
    id: '2018'
  },
  {
    name: '2017-18',
    id: '2017'
  },
  {
    name: '2016-17',
    id: '2016'
  },
  {
    name: '2015-16',
    id: '2015'
  }
]