import React from "react";
import { Parser } from "html-to-react";
import "../Students/Events.scss";

function AchievementsModal({ data }) {
  //   console.log("modal data -", data);
  const images = data?.attachments;

  //   console.log("images -", images);

  const htmlParser = new Parser();

  const isPdf = (url) => url.endsWith(".pdf");

  const renderImages = () => {
    if (!images) {
      return <p>No image available</p>;
    }

    // Convert single string to array
    const imageArray = typeof images === "string" ? [images] : images;

    return imageArray.length > 1 ? (
      <div
        id="carouselExampleInterval"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          {imageArray.map((item, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-label={`Slide ${index + 1}`}
            />
          ))}
        </div>
        <div className="carousel-inner">
          {imageArray.map((image, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              data-bs-interval="4000"
            >
              {isPdf(image) ? (
                <iframe
                  src={`${image}#toolbar=0`}
                  className="d-block w-100"
                  style={{ height: "700px" }}
                  title={`Slide ${index + 1}`}
                ></iframe>
              ) : (
                <img
                  src={image}
                  className="d-block w-100 viewimage"
                  alt="..."
                />
              )}
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    ) : isPdf(imageArray[0]) ? (
      <div className="events-image text-center">
        <iframe
          src={`${imageArray[0]}#toolbar=0`}
          className="d-block w-100"
          style={{ height: "700px" }}
          title="PDF Document"
        ></iframe>
      </div>
    ) : (
      <div className="events-image text-center">
        <img
          style={{ objectFit: "fit", height: "700px" }}
          src={imageArray[0]}
          alt="Image"
          className="viewimage"
        />
      </div>
    );
  };

  return (
    <div
      className="modal fade text-left"
      id="AchievementsModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Images and Documents
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="events-details-left-content pr-20">
                    {renderImages()}
                    <div className="meetings mt-3">
                      <h2>{data?.title}</h2>
                      <p className="">
                        {data?.month} - {data?.year}
                      </p>
                      <p>{htmlParser.parse(data?.description)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AchievementsModal;
