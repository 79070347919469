import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ASSET_MEDIA } from "../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../Helpers/Helpers";
import { ADD_COMMITEES } from "../../utils/InfoUploadingApiConstants";
import DocumentsModal from "../../modals/Students/DocumentsModal";

function Committees({ setLoading }) {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [types, setTypes] = useState("");

  const fileref = useRef(null);

  const role = sessionStorage.getItem("role");

  const [edit, setEdit] = useState(false);

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  const [info, setInfo] = useState({
    type: "",
    attachment: "",
    date: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearData = () => {
    setInfo({
      type: "",
      attachment: "",
      date: "",
    });
    fileref.current.value = null;
  };

  const getData = async () => {
    const collegeid = sessionStorage.getItem("college_id");
    console.log("collegeid -", collegeid);

    const config = {
      method: "get",
      url: ADD_COMMITEES,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);
        // res.data.data.forEach((element) => {
        //   element.attachment = JSON.parse(element.attachment);
        // });
        console.log(res.data.data);
        // setData(res.data.data);
        setData(res.data.data.filter((item) => item.college_id == collegeid));
      })
      .catch((err) => {
        // console.log(err);
        setLoading(0);
        // toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  //   const handleSubmit = async () => {
  //     if (!info?.type || !info.attachment) {
  //       toast.error("Please Enter all the required Details");
  //       return;

  //     }

  //     info.college_id = await sessionStorage.getItem('college_id');

  //     setLoading(1);

  //     const config = {
  //       method: "post",
  //       url: ADD_COMMITEES,
  //       headers: {
  //         "Content-Type": "application/json",
  //         //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
  //       },
  //       data: info,
  //     };

  //     axios(config)
  //       .then((res) => {
  //         console.log(res);
  //         toast.success("Succesfully Uploaded Details");
  //         clearData();
  //         getData();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });

  //     setLoading(0);
  //   };

  const handleSubmit = async () => {
    if (!info?.type || !info.attachment) {
      toast.error("Please Enter all the required Details");
      return;
    }

    const collegeId = sessionStorage.getItem("college_id");

    setLoading(1);

    const existingRows = data.filter((item) => item.type === info.type);

    if (existingRows.length > 0) {
      const promises = existingRows.map((existingRow) => {
        const updateConfig = {
          method: "put",
          url: `${ADD_COMMITEES}/${existingRow.id}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: {
            status: "INACTIVE",
          },
        };
        return axios(updateConfig);
      });

      await Promise.all(promises)
        .then((responses) => {
          setLoading(0);
          toast.success("Existing rows updated to INACTIVE");
          const updatedData = data.map((item) => {
            if (existingRows.find((row) => row.id === item.id)) {
              return { ...item, status: "INACTIVE" };
            }
            return item;
          });
          setData(updatedData);
        })
        .catch((error) => {
          setLoading(0);
          toast.error("Failed to update existing rows");
          console.error(error);
        });
    }

    // Get current date
    const currentDate = new Date().toISOString();

    // Proceed to create the new row
    const newConfig = {
      method: "post",
      url: ADD_COMMITEES,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ...info,
        college_id: collegeId,
        date: currentDate,
      },
    };

    axios(newConfig)
      .then((res) => {
        console.log(res);
        toast.success("Successfully Uploaded Details");
        clearData();
        getData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to upload new row");
      });

    setLoading(0);
  };

  const handleChange1 = async (e) => {
    const file = e.target.files[0];
    console.log(file);

    // Check if a file is selected
    if (file) {
      // Get the file extension
      const fileExtension = file.name.split(".").pop().toLowerCase();

      // Check if the file extension is PDF
      if (fileExtension !== "pdf") {
        // Reset file input and show error message
        e.target.value = null;
        toast.error("Please select a PDF file");
        return;
      }

      // File is valid PDF, continue with processing
      let empId = Math.floor(Math.random() * 100);
      try {
        const d = await getFileUrl(
          ASSET_MEDIA,
          "media",
          fileExtension,
          setLoading,
          file
        );
        setInfo((prev) => ({
          ...prev,
          attachment: d,
        }));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleApprove = (id) => {
    const config = {
      method: "put",
      url: `${ADD_COMMITEES}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "ACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Successfully ACTIVE");
        const updatedData = data.map((item) =>
          item.id === id ? { ...item, status: "ACTIVE" } : item
        );
        setData(updatedData);
        getData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  const handleDeletes = (id) => {
    const config = {
      method: "put",
      url: `${ADD_COMMITEES}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data Deleted");
        const updatedData = data.map((item) =>
          item.id === id ? { ...item, status: "INACTIVE" } : item
        );
        setData(updatedData);
        getData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  const handleEdit = async () => {
    setLoading(1);
    const config = {
      method: "put",
      url: ADD_COMMITEES + `/${info.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...info,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data Updated successfully");
        clearData();
        getData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleDelete = async (deletedItem, status) => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${ADD_COMMITEES}/${deletedItem?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: status,
      },
    };
    try {
      await axios(config);
      setLoading(0);
      toast.success("Data Deleted");
      getData();
    } catch (error) {
      setLoading(0);
      toast.error("Some Error Occurred");
    }
  };

  // Function to clean the URL by removing extra quotes
  const cleanUrl = (url) => url.replace(/^"|"$/g, "");

  return (
    <div>
      <>
        <DocumentsModal title={title} img={link} setLink={setLink} />

        <div className="row">
          <div className="col-12 mt-4">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h5 className="ml-4">Committees</h5>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 mb-2">
            <div className="card">
              <div className="card-body">
                <h2 className="card-title">Select criteria</h2>
                <br />

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        {" "}
                        Type <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="type"
                        id="class"
                        className="form-control"
                        value={info?.type}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        {role === "MEDWEB" ? (
                          <>
                            <option value="">Select Type</option>
                            <option value="College Council">
                              College Council
                            </option>
                            <option value="Internal Complaints Committee">
                              Internal Complaints Committee
                            </option>
                            <option value="Anti-Ragging Committee">
                              Anti-Ragging Committee
                            </option>
                            <option value="Ethics Committee">
                              Ethics Committee
                            </option>
                            <option value="Medical Education">
                              Medical Education
                            </option>
                            <option value="Curriculum Committee">
                              Curriculum Committee
                            </option>
                          </>
                        ) : role === "PHAWEB" ? (
                          <>
                            <option value="">Select Type</option>
                            <option value="College Council">
                              College Council
                            </option>
                            <option value="Internal Complaints Committee">
                              Internal Complaints Committee
                            </option>
                            <option value="Anti-Ragging Committee">
                              Anti-Ragging Committee
                            </option>
                            <option value="Ethics Committee">
                              Ethics Committee
                            </option>
                            <option value="Medical Education Unit">
                              Medical Education Unit
                            </option>
                            <option value="Curriculum Committee">
                              Curriculum Committee
                            </option>
                            <option value="Hospital Infection Prevention & Control Committee">
                              Hospital Infection Prevention & Control Committee
                            </option>
                            <option value="Pharmacovigilance Committee">
                              Pharmacovigilance Committee
                            </option>
                          </>
                        ) : role === "NURWEB" ? (
                          <>
                            <option value="">Select Type</option>
                            <option value="Anti-Ragging Committee">
                              Anti-Ragging Committee
                            </option>
                            <option value="Admission Committee">
                              Admission Committee
                            </option>
                            <option value="Curriculum Committee">
                              Curriculum Committee
                            </option>
                            <option value="Library Committee">
                              Library Committee
                            </option>
                            <option value="Discipline Committee">
                              Discipline Committee
                            </option>
                            <option value="Advisory Committee">
                              Advisory Committee
                            </option>
                            <option value="SNA Committee">SNA Committee</option>
                          </>
                        ) : role === "PHYWEB" ? (
                          <>
                            <option value="">Select Type</option>
                            <option value="Curriculum Committee">
                              Curriculum Committee
                            </option>
                            <option value="Anti-Ragging Committee">
                              Anti-Ragging Committee
                            </option>
                            <option value="Women Development Cell Committee">
                              Women Development Cell Committee
                            </option>
                          </>
                        ) : role === "UNIWEBSITE" ? (
                          <>
                            <option value="">Select Type</option>
                            <option value="College Council">
                              College Council
                            </option>
                            <option value="Internal Complaints Committee">
                              Internal Complaints Committee
                            </option>
                            <option value="Anti-Ragging Committee">
                              Anti-Ragging Committee
                            </option>
                            <option value="Ethics Committee">
                              Ethics Committee
                            </option>
                            <option value="Medical Education Unit">
                              Medical Education Unit
                            </option>
                            <option value="Curriculum Committee">
                              Curriculum Committee
                            </option>
                            <option value="Hospital Infection Prevention & Control Committee">
                              Hospital Infection Prevention & Control Committee
                            </option>
                            <option value="Pharmacovigilance Committee">
                              Pharmacovigilance Committee
                            </option>
                          </>
                        ) : (
                          <>
                            <option value="">Select Type</option>
                            <option value="College Council">
                              College Council
                            </option>
                            <option value="Curriculum Committee">
                              Curriculum Committee
                            </option>
                            <option value="Internal Complaint Committee">
                              Internal Complaint Committee
                            </option>
                            <option value="Hospital Infection Control Committee">
                              Hospital Infection Control Committee
                            </option>
                            <option value="Anti-Ragging Committee">
                              Anti-Ragging Committee
                            </option>
                            <option value="Ethics Committee">
                              Ethics Committee
                            </option>
                            <option value="Pharmacovigilance Committee">
                              Pharmacovigilance Committee
                            </option>
                            <option value="Scientific review Committee">
                              Scientific review Committee
                            </option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">
                        Attachment<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        placeholder="Attach the file"
                        className="form-control"
                        name="attachment"
                        ref={fileref}
                        onChange={(e) => {
                          handleChange1(e);
                        }}
                      />

                      {info?.attachment?.length > 0 ? (
                        <button
                          onClick={() => {
                            setLink(info?.attachment);
                            setTitle("Uploaded file");
                          }}
                          data-toggle="modal"
                          data-target="#DocumentsModal"
                          className="btn btn-primary btn-sm my-1"
                        >
                          View
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row mr-5">
                  <div className="col-md-10 ml-auto mr-5">
                    {edit == false ? (
                      <>
                        <button
                          className="btn btn-nex btn-rounded float-right "
                          type="submit"
                          name="submit"
                          onClick={handleSubmit}
                        >
                          <i className="fa fa-save" aria-hidden="true" /> Save
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-nex btn-rounded float-right "
                          type="submit"
                          name="Update"
                          onClick={handleEdit}
                        >
                          <i className="fa fa-save" aria-hidden="true" /> Update
                        </button>
                        <button
                          className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                          type="submit"
                          name="submit"
                          // style={{aspectRatio:'1/1'}}
                          onClick={() => {
                            setEdit(false);
                            // setAddNew(false);
                          }}
                        >
                          {"<   "}{" "}
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <br />
                <br />
                <br />

                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div class="card-header">
                        <h5 class="text-primary"> COMMITTEES LIST </h5>
                      </div>

                      <div className="card-body">
                        <br />
                        <br />

                        <div style={{ overflowX: "auto" }}>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Sl.No.</th>
                                <th>Type</th>
                                <th>Attachment</th>
                                <th>Date</th>
                                <th>Action</th>
                                <th>Status</th>
                              </tr>
                            </thead>

                            <tbody>
                              {data &&
                                data?.map((item, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{key + 1}</td>
                                      <td>{item?.type}</td>
                                      {/* <td>
                                        <a
                                          href={item?.attachment}
                                          target="_blank"
                                        >
                                          {" "}
                                          View Uploaded File{" "}
                                        </a>
                                      </td> */}
                                      <td>
                                        <a
                                          href={cleanUrl(item?.attachment)}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {" "}
                                          View Uploaded File{" "}
                                        </a>
                                      </td>
                                      {/* <td>{item?.date.split('T')[0]}</td> */}
                                      <td>
                                        {
                                          new Date(item?.date)
                                            .toISOString()
                                            .split("T")[0]
                                        }
                                      </td>

                                      <td>
                                        {item?.status == "ACTIVE" ? (
                                          <>
                                            <acronym title="Edit">
                                              <a
                                                href="javascript:void(0)"
                                                onClick={() => {
                                                  setEdit(true);
                                                  setInfo({ ...item });
                                                }}
                                              >
                                                <i
                                                  className="fa fa-edit "
                                                  aria-hidden="true"
                                                />
                                              </a>
                                            </acronym>{" "}
                                            &nbsp;&nbsp;&nbsp;
                                            <acronym title="Inactive">
                                              <a
                                                href="javascript:void(0)"
                                                onClick={() =>
                                                  handleDelete(item, "INACTIVE")
                                                }
                                              >
                                                <i
                                                  className="fa fa-thumbs-down"
                                                  aria-hidden="true"
                                                  style={{ color: "red" }}
                                                />
                                              </a>
                                            </acronym>
                                          </>
                                        ) : (
                                          <acronym title="active">
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() =>
                                                handleDelete(item, "ACTIVE")
                                              }
                                            >
                                              <i
                                                className="fa fa-thumbs-up"
                                                aria-hidden="true"
                                                style={{ color: "green" }}
                                              />
                                            </a>
                                          </acronym>
                                        )}
                                        <a href="javascript:void(0)"> </a>
                                      </td>

                                      <td>
                                        {item?.status == "INACTIVE" ? (
                                          <button
                                            className="btn btn-danger mr-2"
                                            onClick={() =>
                                              handleApprove(item?.id)
                                            }
                                          >
                                            INACTIVE
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-success mr-2"
                                            onClick={() =>
                                              handleDeletes(item?.id)
                                            }
                                          >
                                            ACTIVE
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Committees;
