import React from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IPD_SERVICES_GET, IPD_SERVICES_PUT, IPD_SERVICES_UPLOAD } from '../../utils/InfoUploadingApiConstants';
import { LOCAL_DEPARTMENT } from '../../utils/LocalStorageConstants';

function IPDServices({ setLoading }) {

    const fileref = useRef(null);

    const [data, setData] = useState([]);

    const college_id = sessionStorage.getItem("college_id");
    const role = sessionStorage.getItem("role");

    const [info, setInfo] = useState({
        month:"",
        department:"",
        total_number:"",
        college_id:"",
        year:"",
    });

    const clearData = () => {
        setInfo({
        month:"",
        department:"",
        total_number:"",
        college_id:"",
        year:"",
        });
        fileref.current.value = null;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInfo((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
    };

    const departmentOpt = JSON.parse(
        localStorage.getItem(LOCAL_DEPARTMENT)
    )

    const handleSubmit = async () => {

        if (
          !info?.month ||
          !info?.department ||
          !info?.total_number ||
          !info?.year
        ) {
          toast.error("Please Enter all the required Details");
          return;
        }
    
        // const dataToSend = {
        //   ...info,
        //   college_id: sessionStorage.getItem("college_id"),
        // };

        const dataToSend = {
            year: info.year,
            month: info.month,
            department: info.department, // Use the selected department name directly
            total_number: info.total_number,
            college_id: sessionStorage.getItem("college_id"),
          };
    
        const config = {
          method: "post",
          url: IPD_SERVICES_UPLOAD,
          headers: {
            "Content-Type": "application/json",
            //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
          },
          // data: info,
          data: dataToSend,
        };
    
        axios(config)
          .then((res) => {
            console.log(res);
            toast.success("Succesfully Uploaded Details");
            getData();
            clearData();
          })
          .catch((err) => {
            console.log(err);
          });
    
        setLoading(0);
      };

      const getData = async () => {
        const config = {
            method: "get",
            url: IPD_SERVICES_GET,
            headers: {
                "Content-Type": "application/json",
            },
        };
    
        await axios(config)
            .then((res) => {
                // res.data.data.sort((a, b) => b.id - a.id)
                // console.log(res.data.data)
                // setData(res.data.data);

              const IPDServices = res.data.data.filter(element => element.status == "ACTIVE"
              && element.college_id == sessionStorage.getItem("college_id"));               
              setData(IPDServices);
              // IPDServices.sort((a, b) => b.id - a.id);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    
    useEffect(() => {
        getData();
    }, []);

      const handleDelete = (id) => {
        const config = {
            method: 'put',
            url: `${IPD_SERVICES_PUT}/${id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            data: {
                status: 'INACTIVE'
            }
        }
      
        axios(config)
            .then(res => {
                setLoading(0)
                toast.success("Success")
                const updatedData = data.map(item => (item.id === id ? { ...item, status: 'INACTIVE' } : item));
                setData(updatedData);
            })
            .catch(err => {
                setLoading(0)
                toast.error("Something Went Wrong")
            })
      }
      
      const handleApprove = (id) => {
        const config = {
            method: 'put',
            url: `${IPD_SERVICES_PUT}/${id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            // data: {
            //     status: 'ACTIVE'
            // }
        }
      
        axios(config)
            .then(res => {
                setLoading(0)
                toast.success("Success")
                const updatedData = data.map(item => (item.id === id ? { ...item, status: 'ACTIVE' } : item));
                setData(updatedData);
            })
            .catch(err => {
                setLoading(0)
                toast.error("Something Went Wrong")
            })
      }

    const uniqueMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const uniqueDepartments = [...new Set(data.map(item => item.department))];

    const years = Array.from({ length: new Date().getFullYear() - 2015 + 1 }, (_, i) => (2015 + i).toString()).reverse();

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const groupDataByYear = () => {
      const groupedData = {};
    
      data.forEach((item) => {
        const key = item.year; // Assuming 'year' is a property in your data
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(item);
      });
    
      return groupedData;
    };
    
    const groupedDataByYear = groupDataByYear();
  
  const sortedYears = Object.keys(groupedDataByYear).sort((a, b) => b - a);
  
  // Function to calculate the total for each department
  const calculateDepartmentTotal = (departmentId, year) => {
    return groupedDataByYear[year].reduce(
      (total, item) => (item.department === departmentId ? total + item.total_number : total),
      0
    );
  };
  
  const calculateMonthTotal = (month, year, departmentId) => {
    return groupedDataByYear[year].reduce(
      (total, item) => (item.month === month && item.department === departmentId ? total + item.total_number : total),
      0
    );
  };
  
  const calculateYearTotalForMonth = (month, year) => {
    return uniqueDepartments.reduce(
      (total, departmentId) => total + calculateMonthTotal(month, year, departmentId),
      0
    );
  };
  
  // Add this function for the grand total
  const calculateGrandTotal = (year) => {
    return uniqueMonths.reduce(
      (total, month) => total + calculateYearTotalForMonth(month, year),
      0
    );
  };

  let homeoData = [
    {
      id: "Medicine",
      title: "Medicine",
    },
    {
      id: "General Surgery",
      title: "General Surgery",
    },
    {
      id: "Gynecology & Obstetrics",
      title: "Gynecology & Obstetrics",
    },
    {
      id: "Paediatrics",
      title: "Paediatrics",
    },
    
  ];

  console.log("total no -", info.total_number);

  return (
    <div>
      <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">

                <div className="row">
                  <div className="col-12 mt-4">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h5 className='ml-4'> IPD SERVICES DATA</h5>
                    </div>
                  </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">

                                <div className="row">

                                <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Select Year<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="year"
                            id="year"
                            value={info.year}
                            onChange={handleChange}
                          >
                            <option value=""> Select Year </option>
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label htmlFor="validationCustom02">
                                        Select Month<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <select
                                          className="form-control"
                                          name="month"
                                          id="class"
                                          value={info.month}
                                          onChange={handleChange}
                                        >
                                        <option value="">Select Month</option>    
                                        <option value="January">January</option>
                                        <option value="February">February</option>
                                        <option value="March">March</option>
                                        <option value="April">April</option>
                                        <option value="May">May</option>
                                        <option value="June">June</option>
                                        <option value="July">July</option>
                                        <option value="August">August</option>
                                        <option value="September">September</option>
                                        <option value="October">October</option>
                                        <option value="November">November</option>
                                        <option value="December">December</option>

                                        </select>
                                      </div>
                                    </div>
                                    
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label htmlFor="validationCustom02">
                                          Department<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <select
                                          className="form-control"
                                          name="department"
                                          id="class"
                                          value={info.department}
                                          onChange={handleChange}
                                        >
                                        <option value="">Select Department</option>                                          
                                          {role === "HOMOWEB" ? (
                                            homeoData?.map((i) => (
                                              <option key={i.id} value={i.id}>{i.title}</option>
                                            ))
                                          ) 
                                          : (
                                            departmentOpt
                                            ?.filter((s) => s.college_id == college_id)
                                            ?.map((i, key) => (
                                              <option value={i.id} key={key}>
                                                {i.name}
                                              </option>
                                            ))
                                          )}
                                        </select>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label htmlFor="validationCustom02">
                                          Total Number<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="Enter Total Number"
                                          name="total_number"
                                          value={info.total_number}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                        <button
                                        className="btn btn-success float-right"
                                        id="submit"
                                        onClick={handleSubmit}
                                        >
                                            Submit
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                </div>
            </div>
        </div>

        <div className="container">
                  <div className="row">

                    <div className='col-12'>

                    <div className='card'>
                    <div className='card-body'>

                    <div class="card-header">
                      <h4 class="text-primary"> IPD Sevices List </h4>
                    </div>

{sortedYears
.map((year) => (
  <div key={year} className="container">
    <br></br>
    <br></br>
    <br></br>
    <h4 className="text-primary">DETAILS OF IPD PATIENTS: {year}</h4>
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Month</th>
          {uniqueDepartments.map((departmentId) => (
            <th key={departmentId}>
              {departmentOpt.find((d) => d.id == departmentId)?.name || departmentId}
            </th>
          ))}
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {uniqueMonths.map((month, index) => (
          <tr key={index}>
            <td>{month}</td>
            {uniqueDepartments.map((departmentId) => (
              <td key={departmentId}>
                {calculateMonthTotal(month, year, departmentId)}
              </td>
            ))}
            <td>{calculateYearTotalForMonth(month, year)}</td>
          </tr>
        ))}
        <tr>
          <th>Total</th>
          {uniqueDepartments.map((departmentId) => (
            <td key={departmentId}>
              {calculateDepartmentTotal(departmentId, year)}
            </td>
          ))}
          <td className="text-dark">{calculateGrandTotal(year)}</td> {/* this line for the grand total */}
        </tr>
      </tbody>
    </table>
  </div>
))}

                  </div>
                </div>

                </div>

              </div>
              </div>

              
    </div>
  )
}

export default IPDServices;
