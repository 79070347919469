import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { SessionOpt } from '../../Data/student/sessionData'
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SECTION, ACADEMICS_ADD_SEMESTER, ACADEMICS_PROMOTE_STUDENT } from '../../utils/Academics.apiConst'
import { LOCAL_DEPARTMENT, LOCAL_PROGRAM } from '../../utils/LocalStorageConstants'

import Nodata from "../../Components/NoData/Nodata"

const TransferStudents = ({ setLoading, collegeId }) => {
  const [departmentOpt, setDepartmentOpt] = useState(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)))
  const [programOpt, setProgramOpt] = useState(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)))


  useEffect(() => {
    setDepartmentOpt(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)).filter(item => item.college_id == collegeId))
  }, [localStorage.getItem(LOCAL_DEPARTMENT)])


  const [classOpt, setClassOpt] = useState([])

  const [semOpt, setSemtOpt] = useState([])

  const [sectionOpt, setSectionOpt] = useState([])

  const [data, setData] = useState([])

  const [sessionTo, setSessionTo] = useState()

  const [classTo, setClassTo] = useState()

  const [semesterTo, setSemesterTo] = useState()

  const [sectionTo, setSectionTo] = useState()

  const [departmentTo, setDepartmentTo] = useState();

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [user, setUser] = useState({
    class_id: '',
    semester_id: '',
    department_id: '',
    section_id: '',
    course_id: '',
    employee_id: '',
    time_from: "",
    time_to: "",
    session_id: '',
    to_session_id: '',
    to_semester_id: '',
    to_section_id: ''
  })



  const getClassData = async () => {
    setLoading(1)
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    }

    const [data1, data2, data3] = await Promise.all([
      axios({ ...config, url: ACADEMICS_ADD_SEMESTER + `?college_id=${collegeId}` })
        .then(res => {
          setSemtOpt(res.data.data)
        })
        .catch(err => {
          setLoading(0)
          console.log(err);
          toast.error('Something went wrong')
        }),

      axios({ ...config, url: ACADEMICS_ADD_SECTION + `?college_id=${collegeId}` })
        .then(res => {
          setSectionOpt(res.data.data)
        })
        .catch(err => {
          setLoading(0)
          console.log(err);
          toast.error('Something went wrong')
        }),
      axios({ ...config, url: ACADEMICS_ADD_CLASS + `?college_id=${collegeId}` })
        .then(res => {
          setClassOpt(res.data.data)
        })
        .catch(err => {
          setLoading(0)
          console.log(err);
          toast.error('Something went wrong')
        }),
    ])

    setLoading(0)
  }


  const getData = async () => {

    if(!user.session_id || !user.semester_id || !user.class_id || !user.department_id) {
      toast.error("Please Enter Required Details");
      return;
    }

    setLoading(1)
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: ACADEMICS_PROMOTE_STUDENT + '/all' + `?session_id=${user.session_id}&section_id=${user?.section_id}&semester_id=${user?.semester_id}&class_id=${user?.class_id}&department_id=${user?.department_id}`
    }

    await axios(config).then(
      res => {
        setData(res.data.data)
        console.log('data - ', res.data.data)
      }
    ).catch(err => {
      setLoading(0)
      console.log(err);
      toast.error('Something went wrong')
    })
    setLoading(0)


  }


  const transferStudent = async (id, studentInfo, status) => {

    if (!departmentTo || !classTo || !semesterTo || !sectionTo) {
      return toast.error('Please choose where to Transfer');
    }

    if(user.department_id && departmentTo == user.department_id) {
      return toast.error('Cannot Transfer to Same Department');
    }
    setLoading(1);
    // console.log('userr_id : ', id);
    // console.log('studentInfo : ', studentInfo);
    // console.log('status : ', status);

    // const config = {
    //   method: 'post',
    //   url: `${ACADEMICS_PROMOTE_STUDENT}/${id}`,
    //   headers: {
    //     Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
    //     "Content-Type": "application/json",
    //   },
    //   data: {
    //     "session_id": sessionTo,
    //     "student_id": id,
    //     "department_id": user?.department_id,
    //     "class_id": classTo,
    //     "semester_id": semesterTo,
    //     "section_id": sectionTo,
    //     "batch_id": null,
    //     "route_id": null,
    //     "hostel_room_id": null,
    //     "status": status
    //   }
    // }
    // console.log('data to backend - ',{
    //   "session_id": sessionTo,
    //   "student_id": id,
    //   "department_id": user?.department_id,
    //   "class_id": classTo,
    //   "semester_id": semesterTo,
    //   "section_id": sectionTo,
    //   "batch_id": null,
    //   "route_id": null,
    //   "hostel_room_id": null,
    //   "status": status
    // });

    // axios(config)
    //   .then(res => {
    //     toast.success("Success")
    //     getData()
    //   })
    //   .catch(err => {
    //     toast.error("Something went wrong")
    //   })
    console.log('student transfered');
    setLoading(0);
  }

  useEffect(() => {
    getClassData()
  }, [])


  const handleDepartmentChange = (event) => {
    setDepartmentTo(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target
    setUser(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <div>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Transfer Students</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Academics</a>
                      </li>
                      <li className="breadcrumb-item active"> Transfer Students</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <>
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <h2 className="card-title">Transfer Student from</h2>
                      <br />
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Department<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="department_id"
                              id="class"
                              value={user.department_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Department</option>
                              {
                                departmentOpt.map((i, key) => (
                                  <option value={i.id} key={key}>{i.name}, {programOpt.find(item => item.id == i.program_id)?.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Academic Year<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="session_id"
                              id="class"
                              value={user.session_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Academic</option>
                              {
                                SessionOpt.map((i, key) => (
                                  <option value={i.id} key={key}>{i.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Class<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="class_id"
                              id="class"
                              value={user.class_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Class</option>
                              {
                                classOpt?.filter(s=>s?.department_id==user?.department_id)?.map((i, key) => (
                                  <option value={i.id} key={key}>{i.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Semester<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="semester_id"
                              id="class"
                              value={user.semester_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Semester</option>
                              {
                                semOpt?.filter(s=>s?.class_id==user?.class_id)?.map((i, key) => (
                                  <option value={i?.id} key={key} >{i.name}</option>
                                ))
                              }
                              {/* {
                                classOpt?.map((i, key) => (
                                  <option value={i.id}>{i.name}</option>
                                ))
                              } */}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Section<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="section_id"
                              id="class"
                              value={user?.section_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Section</option>
                              {
                                sectionOpt?.filter(s => (s.department_id == user.department_id && s.semester_id == user.semester_id))?.map((i, key) => (
                                  <option value={i.id} key={key}>{i.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4 d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-nex btn-rounded float-lg-left "
                            onClick={getData}
                          // style={{maxHeight:'40px'}}
                          >
                            <i className="fa fa-search" aria-hidden="true" /> Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end card */}
                </div>
              </div>
              {/* container-fluid */}
            </>

            {/* end card */}
            {/* container-fluid */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title">Transfer Student To</h4>
                      </div>
                      <div className="col-md-8 ">
                        <span className="float-right">
                          <acronym title="PDF">
                            {" "}
                            <a href="#">
                              <i className="fa fa-file-pdf-o " aria-hidden="true" />
                            </a>
                          </acronym>
                          <a href="#"> </a> &nbsp;{" "}
                          <acronym title="Excel">
                            <a href="#">
                              {" "}
                              <i className="fa fa-file-excel-o" aria-hidden="true" />
                            </a>
                          </acronym>
                          <a href="#"> </a>
                        </span>
                      </div>
                    </div>{" "}
                    <hr />
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                            <label htmlFor="validationCustom02">
                                Department<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                                className="form-control"
                                name="department_id"
                                id="class"
                                value={departmentTo}
                                onChange={handleDepartmentChange}
                            >
                                <option value="">Select Department</option>
                                {
                                departmentOpt.map((i, key) => (
                                    <option value={i.id} key={key}>{i.name}, {programOpt.find(item => item.id == i.program_id)?.name}</option>
                                ))
                                }
                            </select>
                            </div>
                        </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Class<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="to_section_id"
                            id="class"
                            value={classTo}
                            onChange={(e) => { setClassTo(e.target.value) }}
                          >
                            <option value="">Select Class</option>
                            {
                              classOpt?.filter(s=>s?.department_id==departmentTo)?.map((i, key) => (
                                <option value={i.id} key={key}>{i.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Semester<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="to_semester_id"
                            id="class"
                            value={semesterTo}
                            onChange={(e) => { setSemesterTo(e.target.value) }}
                          >
                            <option value="">Select Semester</option>
                            {
                              semOpt?.filter(s => s.class_id == classTo)?.map((i, key) => (
                                <option value={i.id} key={key}>{i.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Section<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="to_semester_id"
                            id="class"
                            value={sectionTo}
                            onChange={(e) => { setSectionTo(e.target.value) }}
                          >
                            <option value="">Select Section</option>
                            {
                              sectionOpt?.filter(s => s.semester_id == semesterTo)?.map((i, key) => (
                                <option value={i.id} key={key}>{i.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>

                    </div>
                    <hr />{" "}
                    {/* <button>Pass</button> */}
                    <div className="table-responsive dt-responsive">
                      <table
                        id="datatable"
                        className="table  nowrap table-hover  "
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          boarder: 0,
                          width: "100%"
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sl No.</th>
                            <th className="text-wrap">
                              Admission <br /> Number
                            </th>
                            <th className="text-wrap">Name</th>
                            {/* <th>Date of Birth</th> */}
                            <th>Class</th>
                            <th>Semester</th>
                            <th>Section</th>
                            <th>Next Session Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            data && data.map((d, k) => (
                              <tr>
                                <td>{k+1}</td>
                                <td className="text-wrap">{d?.user_id}</td>
                                <td className="text-wrap">{d?.name}</td>
                                <td>{d?.class_name}</td>
                                <td>{d?.semester_id ? semOpt?.find(s => s.id == d?.semester_id)?.name : null}</td>
                                <td>{d?.section_id ? sectionOpt?.find(s => s.id == d?.section_id)?.name : null}</td>
                                <td>
                                  {" "}
                                  {/* {
                                    d?.sem_status == "PASSED" ?
                                      <>PASSED</> : null}

                                  {
                                    d?.sem_status == "FAILED" ?
                                      <>FAILED</> : null
                                  } */}

                                  <>{
                                    d?.sem_status != "FAILED" && d?.sem_status != "PASSED" ? <>
                                      <button className='btn-primary btn mx-2'onClick={() => transferStudent(d?.user_id, d, "PASSED")}>Transfer</button>
                                    </>
                                      : <p>Student Not Promoted</p>
                                  }</>

                                </td>
                              </tr>
                            ))
                          }
                          
                        </tbody>
                      </table>
                      {data?.length == 0 ?
                        <Nodata />
                        : null
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransferStudents;