import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ASSET_MEDIA } from '../../utils/AssetsReferenceTypes';
import { HOSPITAL_STAFF_ATTENDANCE_CREATE, HOSPITAL_STAFF_ATTENDANCE_GET, HOSPITAL_STAFF_ATTENDANCE_UPDATE } from '../../utils/InfoUploadingApiConstants';
import DocumentsModal from '../../modals/Students/DocumentsModal';
import { getFileUrl } from '../../Helpers/Helpers';

function HospitalStaffAttendance({ setLoading }) {

  const fileref = useRef(null);

  const [data, setData] = useState([]);

  const [edit, setEdit] = useState(false);

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [sessions, setSessions] = useState("");
  
  // Initialize a counter outside the mapping function
  let serialNumber = 0;
  


  const [info, setInfo] = useState({
    session: "",
    attachments: "",
    month: "",
  });

  const clearData = () => {
    setInfo({
        session: "",
        attachments: "",
        month: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {
    if (!info?.session || !info?.month ) {
      toast.error("Please Enter all the required Details");
      return;
    }

    setLoading(1);

    // Retrieve college_id from session storage
    const college_id = sessionStorage.getItem("college_id")
    console.log("college_id",college_id);

    const currentDate = new Date().toISOString();

    const config = {
      method: "post",
      url: HOSPITAL_STAFF_ATTENDANCE_CREATE,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...info,
        date: currentDate,
        college_id: college_id, // Add college_id to the data
      },
    };

    axios(config)
      .then((res) => {
        // console.log("college_id"+res);
        toast.success("Succesfully Updated Details");
        clearData();
        getData();
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });

  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

    const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      info.attachments = d;
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    const collegeid = sessionStorage.getItem("college_id");

    const config = {
        method: "get",
        url: HOSPITAL_STAFF_ATTENDANCE_GET,
        headers: {
            "Content-Type": "application/json",
        },
    };

    await axios(config)
        .then((res) => {
            res.data.data.sort((a, b) => b.id - a.id);
            // res.data.data.forEach((element) => {
            //     element.attachments = JSON.parse(element.attachments);
            // });
            console.log(res.data.data)
            // setData(res.data.data);
            setData(res.data.data.filter((item) => item.college_id == collegeid))            

        })
        .catch((err) => {
            console.log(err);
        });
};

useEffect(() => {
    getData();
}, []);

const handleApprove = (id) => {
  const config = {
      method: 'put',
      url: `${HOSPITAL_STAFF_ATTENDANCE_UPDATE}/${id}`,
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
      },
      data: {
          status: 'ACTIVE'
      }
  }

  axios(config)
      .then(res => {
          setLoading(0)
          toast.success("Successfully ACTIVE")
          const updatedData = data.map(item => (item.id === id ? { ...item, status: 'ACTIVE' } : item));
          setData(updatedData);
          getData();
      })
      .catch(err => {
          setLoading(0)
          toast.error("Something Went Wrong")
      })
}

const handleDeletes = (id) => {
const config = {
    method: 'put',
    url: `${HOSPITAL_STAFF_ATTENDANCE_UPDATE}/${id}`,
    headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
    },
    data: {
        status: 'INACTIVE'
    }
}

axios(config)
    .then(res => {
        setLoading(0)
        toast.success("Successfully INACTIVE")
        const updatedData = data.map(item => (item.id === id ? { ...item, status: 'INACTIVE' } : item));
        setData(updatedData);
        getData();
    })
    .catch(err => {
        setLoading(0)
        toast.error("Something Went Wrong")
    })
}

const handleEdit = async () => {
  setLoading(1)

  const config = {
    method: "put",
    url: HOSPITAL_STAFF_ATTENDANCE_UPDATE + `/${info.id}`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      "Content-Type": "application/json",
    },
    data: {
      ...info,
    },
  }

  await axios(config)
    .then((res) => {
      setLoading(0)
      toast.success("Data Updated successfully");
      clearData();
      getData();
    })
    .catch(err => {
      setLoading(0)
      console.log(err);
      toast.error('Something went wrong')
    })
}

const handleDelete = async (deletedItem, status) => {
  setLoading(1);
  const config = {
    method: "put",
    url: `${HOSPITAL_STAFF_ATTENDANCE_UPDATE}/${deletedItem?.id}`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      "Content-Type": "application/json",
    },
    data: {
      status: status
    },
  };
  try {
    await axios(config);
    setLoading(0);
    toast.success('Data Deleted');
    getData();
  } catch (error) {
    setLoading(0);
    toast.error('Some Error Occurred');
  }
}

let sessionData = [
    {
      id: "2024-25",
      title: "2024-25" 
    },
    {
      id: "2023-24",
      title: "2023-24" 
    },
    { 
      id: "2022-23", 
      title: "2022-23"
    },
    {
      id: "2021-22",
      title: "2021-22",
    },   
    {
      id: "2020-21",
      title: "2020-21",
    },
    {
      id: "2019-20",
      title: "2019-20",
    },
    {
      id: "2018-19",
      title: "2018-19",
    },
    {
        id: "2017-18",
        title: "2017-18",
      },
      {
        id: "2016-17",
        title: "2016-17",
      },
      {
        id: "2015-16",
        title: "2015-16",
      },
      {
        id: "2014-15",
        title: "2014-15",
      },
  ];

  let monthData = [
    {
      id: "JANUARY",
      title: "JANUARY" 
    },
    { 
      id: "FEBRUARY", 
      title: "FEBRUARY"
    },
    {
      id: "MARCH",
      title: "MARCH",
    },
    {
      id: "APRIL",
      title: "APRIL",
    },
    {
      id: "MAY",
      title: "MAY",
    },
    {
      id: "JUNE",
      title: "JUNE",
    },
    {
      id: "JULY",
      title: "JULY",
    },
    {
      id: "AUGUST",
      title: "AUGUST",
    },
    {
        id: "SEPTEMBER",
        title: "SEPTEMBER",
      },
      {
        id: "OCTOBER",
        title: "OCTOBER",
      },
      {
        id: "NOVEMBER",
        title: "NOVEMBER",
      },
      {
        id: "DECEMBER",
        title: "DECEMBER",
      },
  ];

  return (
    <div>
        <>

        <DocumentsModal title={title} img={link} setLink={setLink}/>

        <div className="row">
            <div className="col-12 mt-4">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h5 className='ml-4'> HOSPITAL STAFF ATTENDANCE</h5>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-xl-12 mb-2">
                <div className="card">
                    <div className="card-body">

                        <h2 className="card-title">Select criteria</h2>
                        <br/>

                        <div className="row">

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label> Session <span style={{ color: "red" }}>*</span></label>
                                    <select
                                      className="form-control"
                                      name="session"
                                      id="session"
                                      value={info?.session}
                                      onChange={handleChange}
                                    >
                                      <option value="">Select Session</option>
                                      {sessionData?.map((i) => (
                                        <option key={i.id} value={i.id}>{i.title}</option>
                                      ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label> Month <span style={{ color: "red" }}>*</span></label>
                                    <select
                                      className="form-control"
                                      name="month"
                                      id="month"
                                      value={info?.month}
                                      onChange={handleChange}
                                    >
                                      <option value="">Select Month</option>
                                      {monthData?.map((i) => (
                                        <option key={i.id} value={i.id}>{i.title}</option>
                                      ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Attachments<span style={{ color: "red" }}>*</span></label>
                                    <input
                                      type="file"
                                      className="form-control"
                                      name="attachments"
                                      placeholder="Enter Title Of the Subject"
                                      ref={fileref}
                                      onChange={(e) => handleChange1(e)}
                                    />

                                    {info?.attachments?.length > 0 ? (
                                      <button
                                        onClick={() => {
                                          setLink(info?.attachments);
                                          setTitle("Uploaded file");
                                        }}
                                        data-toggle="modal"
                                        data-target="#DocumentsModal"
                                        className="btn btn-primary btn-sm my-1"
                                      >
                                        View
                                      </button>
                                    ) : null}

                                </div>
                            </div>
                            
                        </div>

                        <div className="row mr-5 mt-4">
                    <div className="col-md-12 ml-auto">
                      {
                        edit == false ? 
                        <>
                        <button
                          className="btn btn-nex btn-rounded float-right "
                          type="submit"
                          name="submit"
                          onClick={handleSubmit}
                        >
                          <i className="fa fa-save" aria-hidden="true" /> Save
                        </button>
                        </>
                         :
                         <>
                          <button
                            className="btn btn-nex btn-rounded float-right "
                            type="submit"
                            name="Update"
                            onClick={handleEdit}
                          >
                            <i className="fa fa-save" aria-hidden="true" /> Update  
                          </button>
                          <button
                            className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                            type="submit"
                            name="submit"
                            // style={{aspectRatio:'1/1'}}
                            onClick={() => {
                              setEdit(false);
                              // setAddNew(false);
                            }}
                          >
                          {"<   "}{" "}                                  
                          </button>
                          </>
                      }
                    </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div className="row">
              <div className="col-12">

                <div className="card">

                  <div class="card-header">
                    <h5 class="text-primary"> HOSPITAL STAFF ATTENDANCE LIST </h5>
                  </div>

                  <div className="card-body">
                    
                    {/* <h6 class="text-danger">Search Feild</h6>
                    <br/> */}

                    <div className="col-md-4">
                            <div className="form-group">
                                <label> Session <span style={{ color: "red" }}>*</span></label>
                                <select
                                    name="session"
                                    id="class"
                                    className="form-control"
                                    value={sessions}
                                    onChange={(e) => setSessions(e.target.value)}
                                >
                                  <option value="">Select Session</option>
                                      {sessionData?.map((i) => (
                                        <option key={i.id} value={i.id}>{i.title}</option>
                                      ))}                                
                                </select>
                            </div>
                      </div>
                      <br/>                     
                    
                    <div style={{ overflowX: "auto" }}>

                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Sl.No.</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Attachments</th>
                            <th>Date</th>
                            <th>Action</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        
                        <tbody>

                          {
                            data && data?.map((item, key) => {

                              const showRow =
                                (sessions === item?.session) ||
                                (sessions === "");

                              // Increment the serial number for all items
                              if (showRow) {
                                serialNumber++;
                              }
                            
                                return showRow ? (
                                <tr key={key}>
                                  <td width="5%">{serialNumber}</td>
                                  <td width="10%">{item?.session}</td>
                                  <td width="10%">{item?.month}</td>
                                  <td width="10%"><a href={item?.attachments} target="_blank"> View Uploaded File </a></td>
                                  <td width="10%">{new Date(item?.date).toISOString().split('T')[0]}</td>

                                  <td width="10%">
                            
                                    {item?.status == "ACTIVE" ? 
                                      <>
                                        <acronym title="Edit">
                                        <a href="javascript:void(0)" onClick={() => { setEdit(true); setInfo({ ...item }) }}>
                                          <i className="fa fa-edit " aria-hidden="true" />
                                        </a>
                                        </acronym> &nbsp;&nbsp;&nbsp;
                                        
                                        <acronym title="Inactive">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => handleDelete(item, "INACTIVE")}
                                        >
                                          <i
                                            className="fa fa-thumbs-down"
                                            aria-hidden="true"
                                            style={{ color: "red" }}
                                          />
                                        </a>
                                        </acronym>
                                      </> 
                                      :
                                        <acronym title="active">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => handleDelete(item, "ACTIVE")}
                                        >
                                          <i
                                            className="fa fa-thumbs-up"
                                            aria-hidden="true"
                                            style={{ color: "green" }}
                                          />
                                        </a>
                                        </acronym>}
                                        <a href="javascript:void(0)"> </a>
                                  </td>

                                  <td width="1%">{item?.status == "INACTIVE" 
                                      ? 
                                    <button className="btn btn-danger mr-2" onClick={() => handleApprove(item?.id)}>INACTIVE</button>
                                      : 
                                    <button className="btn btn-success mr-2" onClick={() => handleDeletes(item?.id)}>ACTIVE</button>}
                                  </td>
                                </tr>

                              ) : null
                            })
                          }
                        </tbody>
                      </table>

                    </div>
                  </div>

                </div>

              </div>
        </div>

        </>     
    </div>
  )
}

export default HospitalStaffAttendance;
