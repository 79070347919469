import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MILESTONES } from "../../utils/InfoUploadingApiConstants";

const Milestones = ({ setLoading }) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);

  const fileref = useRef(null);

  const [info, setInfo] = useState({
    description: "",
    date: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearData = () => {
    setInfo({
      description: "",
      date: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {
    if (!info?.description || !info?.date) {
      toast.error("Please Enter the required Details");
      return;
    }

    info.college_id = await sessionStorage.getItem("college_id");

    setLoading(1);

    // Get current date
    // const currentDate = new Date().toISOString();

    const config = {
      method: "post",
      url: MILESTONES,
      headers: {
        "Content-Type": "application/json",
        //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: {
        ...info,
        // date: currentDate,
      },
    };

    axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Succesfully Uploaded Details");
        getData();
        clearData();
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const getData = async () => {
    const collegeid = sessionStorage.getItem("college_id");

    const config = {
      method: "get",
      url: MILESTONES,
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log("hi");

    await axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);
        // res.data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        console.log(res.data.data);
        // setData(res.data.data);
        setData(res.data.data.filter((item) => item.college_id == collegeid));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleEdit = async () => {
    setLoading(1);
    const config = {
      method: "put",
      url: MILESTONES + `/${info.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...info,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data Updated successfully");
        getData();
        clearData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        // toast.error("Something went wrong");
      });
  };

  const handleDelete = async (deletedItem, status) => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${MILESTONES}/${deletedItem?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: status,
      },
    };
    try {
      await axios(config);
      setLoading(0);
      toast.success("Data Status Updated");
      getData();
    } catch (error) {
      setLoading(0);
      toast.error("Some Error Occurred");
    }
  };

  return (
    <div>
      <div className="Milestones">
        <>
          <div className="card">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="ml-4 text-primary">
                    UPLOAD MILESTONES DETAILS
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 mb-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Select criteria</h2>
                  <br />
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          {" "}
                          Date <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          type="date"
                          className="form-control"
                          name="date"
                          placeholder="Select Date"
                          value={info?.date ? info?.date?.split("T")[0] : ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <label htmlFor="">
                          Description <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          type="text"
                          placeholder="Enter the Title"
                          className="form-control"
                          name="description"
                          value={info?.description}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          rows={5}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-md-10 ml-auto mr-5">
                      {edit == false ? (
                        <>
                          <button
                            className="btn btn-nex btn-rounded float-right "
                            type="submit"
                            name="submit"
                            onClick={handleSubmit}
                          >
                            <i className="fa fa-save" aria-hidden="true" /> Save
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-nex btn-rounded float-right "
                            type="submit"
                            name="Update"
                            onClick={handleEdit}
                          >
                            <i className="fa fa-save" aria-hidden="true" />{" "}
                            Update
                          </button>
                          <button
                            className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                            type="submit"
                            name="submit"
                            // style={{aspectRatio:'1/1'}}
                            onClick={() => {
                              setEdit(false);
                              // setAddNew(false);
                            }}
                          >
                            {"<   "}{" "}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div class="card-header">
                  <h5 class="text-primary"> MILESTONES LIST </h5>
                </div>

                <div className="card-body">
                  <div style={{ overflowX: "auto" }}>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Sl.No.</th>
                          <th>Description</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data &&
                          data?.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td width="1%">{key + 1}</td>
                                <td width="30%">{item?.description}</td>
                                <td width="2%">
                                  {item?.date ? item.date.split("T")[0] : null}
                                </td>
                                <td width="2%">
                                  {item?.status === "ACTIVE" ? (
                                    <span className="badge badge-soft-success">
                                      {item?.status}
                                    </span>
                                  ) : (
                                    <span className="badge badge-soft-danger">
                                      {item?.status}
                                    </span>
                                  )}
                                </td>
                                <td width="2%">
                                  {item?.status == "ACTIVE" ? (
                                    <>
                                      <acronym title="Edit">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            setEdit(true);
                                            setInfo({ ...item });
                                          }}
                                        >
                                          <i
                                            className="fa fa-edit "
                                            aria-hidden="true"
                                          />
                                        </a>
                                      </acronym>{" "}
                                      &nbsp;&nbsp;&nbsp;
                                      <acronym title="Inactive">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() =>
                                            handleDelete(item, "INACTIVE")
                                          }
                                        >
                                          <i
                                            className="fa fa-thumbs-down"
                                            aria-hidden="true"
                                            style={{ color: "red" }}
                                          />
                                        </a>
                                      </acronym>
                                    </>
                                  ) : (
                                    <acronym title="active">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          handleDelete(item, "ACTIVE")
                                        }
                                      >
                                        <i
                                          className="fa fa-thumbs-up"
                                          aria-hidden="true"
                                          style={{ color: "green" }}
                                        />
                                      </a>
                                    </acronym>
                                  )}
                                  <a href="javascript:void(0)"> </a>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Milestones;
