import React from "react";
import { useReactToPrint } from "react-to-print";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify'
import { useRef } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router/routerConfig";
import { INVOICE_DATA_FETCH,INVOICE_CREATE, INVOICE_TABLE_FETCH, INVOIE_UPDATE_DUEDAYS } from "../../../utils/fees.apiConst";

import {
    LOCAL_DEPARTMENT,
    LOCAL_COLLEGE,
  } from "../../../utils/LocalStorageConstants";

const Invoice = ({ setLoading, collegeId }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [numberOfDays, setNumberOfDays] = useState("");

    const [data, setData] = useState(location?.state?.data);

    const [invoiceData, setInvoiceData] = useState([]);
    const [displayData, setDisplayData] = useState([]);

    const [invoiceTableData, setInvoiceTableData] = useState([]);

    const [flag, setFlag] = useState(0);
    const [invoiceGenerated, setInvoiceGenerated] = useState(0);

    const [from, setFrom] = useState("inr");
    const [to, setTo] = useState("usd");
    const [info, setInfo] = useState([]);

    const [invoiceNumber, setInvoiceNumber] = useState(0);

    const [totalAmount, setTotalAmount] = useState(0);
    const [paidAmount, setPaidAmount] = useState(0);
    const [dueAmount, setDueAmount] = useState(0);

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'numeric' });
    const year = currentDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    const [departments, setDepartments] = useState(
        JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      );
    const [colleges, setColleges] = useState(
    JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
    );

    const printRef = useRef();
    const PrintRecipt = useReactToPrint({
        content: () => printRef.current,
    });

    const HandleExport = () => {
        if(flag == 0 && invoiceGenerated == 0) {
            setFlag(1);
            postData();
            PrintRecipt();
        }
        else {
            updateDueDays();
            PrintRecipt();
        }
    }

    const fetchData = async () => {
        setLoading(1);
        if(!data){
            return;
        }

        const student_id = data?.student_id;
        const config = {
            method: "get",
            url:
              INVOICE_DATA_FETCH +
              `?student_id=${student_id}`,
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
              "Content-Type": "application/json",
            },
        };

        await axios(config)
        .then((res) => {
            console.log("Data from Backend - ", res.data.data);
            if(!res.data.data || res.data.data.length == 0) {
                alert("No Fee Data Available !!");
                navigate(ROUTES.Registar.Admission.InvoiceDetails);
            }
            setInvoiceData(res.data.data);
            setLoading(0);
        })
        .catch((err) => {
            console.log(err);
            setLoading(0);
            toast.error("Some Error Occured");
        });
        setLoading(0);

        setLoading(1);
        const config1 = {
            method: "get",
            url:
              INVOICE_TABLE_FETCH,
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
              "Content-Type": "application/json",
            }
        };

        await axios(config1).then((res)=>{
            setLoading(0);
            console.log('data from invoice table - ',res.data.data);
            setInvoiceTableData(res.data.data);

        }).catch((err)=>{
            setLoading(0);
            console.log(err)
        })
    }

    const updateDueDays = async() => {
        setLoading(1);

        const config = {
            method: "get",
            url:
            INVOIE_UPDATE_DUEDAYS + `?dueDays=${numberOfDays}&invoice_number=${data?.invoice_number}`,
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
              "Content-Type": "application/json",
            },
        };

        await axios(config).then((res)=>{
            // toast.success("Successfully Inserted Invoice Details")
            console.log(res);
            setLoading(0);
        }).catch((err)=>{
            setLoading(0);
            console.log(err)
        })
    }

    const postData = async()=> {

        let total_amount=0, total_paid=0, total_due=0;
        let amount = [];

        displayData?.map((value, index) => {
            total_amount = total_amount + value.amount;
            total_paid = total_paid + value.paid_amount;
            let temp = {};
            temp.type = value.fee_type_name;
            temp.total = value.amount;
            temp.paid = value.paid_amount;
            temp.due = value.amount - value.paid_amount;
            amount.push(temp);
        })
        total_due = total_amount - total_paid;

        setTotalAmount(total_amount);
        setDueAmount(total_due);
        setPaidAmount(total_paid);
    
        let data1 = {};
        data1.invoice_number = data?.invoice_number;
        data1.invoice_date = formattedDate;
        data1.student_session_id = invoiceData[0]?.student_session_id;
        data1.student_id = invoiceData[0]?.student_id;
        data1.total_amount = total_amount;
        data1.total_paid = total_paid;
        data1.due_amount = total_due;
        data1.amount = amount;
        data1.status = "ACTIVE";
        data1.due_days = numberOfDays;
        console.log("data to invoice table - ", data1);

        setLoading(1);
        const config = {
            method: "post",
            url:
              INVOICE_CREATE,
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
              "Content-Type": "application/json",
            },
            data:data1
        };

        await axios(config).then((res)=>{
            toast.success("Successfully Inserted Invoice Details")
            setLoading(0);
        }).catch((err)=>{
            setLoading(0);
            console.log(err)
        })
    }

    //convert INR to DOLLAR
    const convert = (input)=> {
        try {
            var rate = info["usd"];
            return (input * rate).toFixed(2);
        }
        catch {
            console.log("unable to convert INR to Dollar");
            return "";
        }
    }

    // function used to convert INR to DOLLAR
    const setInfoData = async () => {
        await axios.get(
            `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/inr.json`)
            .then((res) => {
                setInfo(res.data[from]);
            })
            .catch((err)=> {
                console.log('dollar error - ', err);
            })
    }

    useEffect(()=> {
        fetchData();
    }, [data]);

    useEffect(()=> {
        if(invoiceData && invoiceData.length != 0) {
            let total_amount=0, total_paid=0, total_due=0;
            invoiceData?.filter((s)=>s.amount > s.paid_amount).map((value, index) => {
                total_amount = total_amount + value.amount;
                total_paid = total_paid + value.paid_amount;
            })
            total_due = total_amount - total_paid;
            if(!invoiceData?.filter((s)=>s.amount > s.paid_amount) || (invoiceData?.filter((s)=>s.amount > s.paid_amount)).length == 0) {
                alert("No Dues or No Fee Data Available !!");
                navigate(ROUTES.Registar.Admission.InvoiceDetails);
            }
            setDisplayData(invoiceData?.filter((s)=>s.amount > s.paid_amount));
            setTotalAmount(total_amount);
            setDueAmount(total_due);
            setPaidAmount(total_paid);
        }
    }, [invoiceData]);
    
    useEffect(()=> {
        if(invoiceTableData.length == 0) {
            data['invoice_number'] = 'SUK/' + colleges.find((s)=> s.id == data?.college_id)?.code + '/' + new Date().getFullYear() + '/0001';
        }
        else {
            let mx = 0;
            let f=0;
            invoiceTableData?.forEach((value, index) => {
                mx = Math.max(mx, parseInt(value.invoice_number.substring(14,18)));
                if(value.student_session_id == invoiceData[0]?.student_session_id && value.invoice_date == formattedDate) {
                    setInvoiceGenerated(1);
                    f=1;
                    data['invoice_number'] = value.invoice_number;
                    setNumberOfDays(value.due_days);
                    return;
                }
            })
            if(!f) {
                mx = mx+1;
                let mxString = mx.toString().padStart(4, '0');
                let num = 'SUK/' + colleges.find((s)=> s.id == data?.college_id)?.code + '/' + new Date().getFullYear() + '/' + (mxString).toString();
                data['invoice_number'] = num;
            }
            
        }
    }, [invoiceTableData]);

    useEffect(()=> {
        console.log('data from parent - ', data);
        let obj = data;
        obj['college_id'] = departments.find((s)=> s.id == data.department_id)?.college_id;
        obj['deparment_name'] = departments.find((s)=> s.id == obj.department_id)?.name;
        setData(obj);
        setInfoData();
    }, []);

    return (
        <div>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center">
                            <button
                                className="btn btn-nex d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                                onClick={() => {
                                navigate(-1);
                                }}
                            >
                                <i className="ri-arrow-left-line"></i>
                            </button>
                            <h4 className="mb-0">Invoice Generation</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            {/* <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="">Enter Student Enrollment Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enrollment Number"
                                                // value={userId}
                                                // onChange={(e) => {
                                                // setUserId(e.target.value);
                                                // }}
                                            />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="card" id="divToExport" ref={printRef}>
                                <br />
                                <div className="card-body d-flex flex-column align-items-center">
                                    <div className="col-6 mb-3">
                                        <img src="/assets/images/logoWide.png" className="img-fluid mx-auto" alt="Logo" />
                                    </div>
                                    <div>
                                        <p>Established under Gujarat Private Universities (First Amendment Act) Act 7 of 2022</p>
                                    </div>
                                    <br/>
                                    <div>
                                        <h4 className="font-weight-bold text-decoration-underline"><u>INVOICE</u></h4>
                                        <br className='py-0'></br>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-6">
                                            <table className="float-right">
                                                <tr>
                                                    <td className="float-right font-weight-bold" >Invoice No. :</td>
                                                    <td className="ml-2" >{data?.invoice_number}</td>
                                                </tr>
                                                <tr>
                                                    <td className="float-right font-weight-bold" >Name :</td>
                                                    <td className="ml-2" >{data?.name?data?.name:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="float-right font-weight-bold" >Passport No. :</td>
                                                    <td className="ml-2" >{invoiceData[0]?.passport_no?invoiceData[0]?.passport_no:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="float-right font-weight-bold">Application No. :</td>
                                                    <td className="ml-2" >{invoiceData[0]?.application_no?invoiceData[0]?.application_no:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="float-right font-weight-bold" >Faculty :</td>
                                                    <td className="ml-2" >{colleges.find((s)=> s.id == data?.college_id)?.name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="float-right font-weight-bold" >Class :</td>
                                                    <td className="ml-2" >{invoiceData[0]?.class_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="float-right font-weight-bold">Admission Year :</td>
                                                    <td className="ml-2" >{invoiceData[0]?.year_of_admission?invoiceData[0]?.year_of_admission:"-"}</td>
                                                </tr>
                                            </table>
                                            <br />
                                        </div>
                                        <div className="col-lg-6 col-sm-6"> 
                                            <table className="p-0 mr-2">
                                                <tr>
                                                    <td className="float-right font-weight-bold" >Invoice Date :</td>
                                                    <td className="ml-2" >{formattedDate}</td>
                                                </tr>
                                                <tr>
                                                    <td className="float-right font-weight-bold" >Nationality :</td>
                                                    <td className="ml-2" >{invoiceData[0]?.nationality?invoiceData[0]?.nationality:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="float-right font-weight-bold" >Visa No. :</td>
                                                    <td className="ml-2" >{invoiceData[0]?.visa_no?invoiceData[0]?.visa_no:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="float-right font-weight-bold" >Enrollment No. :</td>
                                                    <td className="ml-2" >{invoiceData[0]?.student_id?invoiceData[0]?.student_id:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="float-right font-weight-bold" >Course/Program :</td>
                                                    <td className="ml-2" >{invoiceData[0]?.program?invoiceData[0]?.program:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="float-right font-weight-bold" >Semester :</td>
                                                    <td className="ml-2" >{invoiceData[0]?.sem_name?invoiceData[0]?.sem_name:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="float-right font-weight-bold" >Academic Year :</td>
                                                    <td className="ml-2" >{invoiceData[0]?.academic_year?invoiceData[0]?.academic_year:"-"}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <p className="float-right">As per the fee schedule, we request you to pay the due fee.</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                        </div>
                                    </div>
                                    <div className="row" style={{width:'88%'}}>
                                        {(invoiceData[0]?.nationality == 'INTERNATIONAL') ?
                                             (
                                                <table className="table table-bordered nowrap table-hover">
                                                    <tr>
                                                        <th>Sl No.</th>
                                                        <th>Description</th>
                                                        <th>Total Amount (INR-USD)</th>
                                                        <th>Paid Amount(INR-USD)</th>
                                                        <th>Dues (INR-USD)</th>
                                                    </tr>
                                                    {displayData?.map((i, key)=>{
                                                        return (
                                                            <tr>
                                                                <td>{key+1}</td>
                                                                <td>{i.fee_type_name}</td>
                                                                <td>{i.amount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})} - ${convert(i.amount)}</td>
                                                                <td>{i.paid_amount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})} - ${convert(i.paid_amount)}</td>
                                                                <td>{(i.amount - i.paid_amount)?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})} - ${convert(i.amount - i.paid_amount)}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td colSpan='2'><b>Grand Total</b></td>
                                                        <td><b>{totalAmount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}  - ${convert(totalAmount)}</b></td>
                                                        <td><b>{paidAmount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}  - ${convert(paidAmount)}</b></td>
                                                        <td><b>{dueAmount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}  - ${convert(dueAmount)}</b></td>
                                                    </tr>
                                                </table>
                                            ) :  (
                                                <table className="table table-bordered nowrap table-hover">
                                                    <tr>
                                                        <th>Sl No.</th>
                                                        <th>Description</th>
                                                        <th>Total Amount</th>
                                                        <th>Paid Amount</th>
                                                        <th>Dues</th>
                                                    </tr>
                                                    {displayData?.map((i, key)=>{
                                                        return (
                                                            <tr>
                                                                <td>{key+1}</td>
                                                                <td>{i.fee_type_name}</td>
                                                                <td>{i.amount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                                                <td>{i.paid_amount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                                                <td>{(i.amount - i.paid_amount)?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td colSpan='2'><b>Grand Total</b></td>
                                                        <td><b>{totalAmount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</b></td>
                                                        <td><b>{paidAmount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</b></td>
                                                        <td><b>{dueAmount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</b></td>
                                                    </tr>
                                                </table>
                                            )
                                        }
                                        
                                    </div>
                                    <div className="row" style={{width:'95%'}}>
                                    <ul style={{listStyleType: 'none'}}>
                                        <li>We request you make the above-mentioned tuition fee payment through NEFT/RTGS favouring as below:</li>
                                        <li><b>A/c Name     :</b> Swaminarayan University</li>
                                        <li><b>Account No.:</b> 59218008432900</li>
                                        <li><b>Bank Name :</b> HDFC Bank</li>
                                        <li><b>IFSC Code  :</b> HDFC0000894</li>
                                        <li><b>Branch        : </b>Kalol, Gujarat</li>
                                        <br/>
                                        <li>Kindly clear your due payment within 
                                            {" "}
                                            <input 
                                                value={numberOfDays} 
                                                id="numberofdays"
                                                style={{width:'4%',border:'none', borderBottom: '1.5px solid', textAlign: 'center'}}
                                                onChange={(e) => {
                                                    setNumberOfDays(e.target.value);
                                                  }} 
                                                required
                                            />
                                            {" "}
                                            days of this invoice to avoid interest.</li>
                                        <li>Thanks & Regards</li>
                                        <li>For Swaminarayan University</li>
                                        <li>Please note, during</li>
                                        <br/>
                                        <li>Bank/Online Transfer:</li>
                                    </ul>
                                    </div>
                                    <div className="row" style={{width:'92%'}}>
                                    <ol>
                                        <li>Please quote students name in the Transfer Information.</li>
                                        <li>Issuance of fee receipt subjected to realization of fees transacted in beneficiary account.</li>
                                        <li>The payer is to take responsibility of the Bank charges, conversion rate on the payment date or any other miscellaneous charges.</li>
                                        <li>The beneficiary/Swaminarayan University will only consider the amount received/realized in the account as the “Fee” amount. If any due or short fall or balance occurs, then the payer is to take care of this shortfall.</li>
                                    </ol>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    {numberOfDays ? (
                    <div className="col-xl-12 text-right mr-5">
                            <button
                                className="btn btn-nex btn-rounded mr-5"
                                type="submit"
                                name="submit"
                                style={{width:'8%'}}
                                onClick={HandleExport}
                            >Print</button>
                            <br/>
                            <br/>
                    </div> )
                    : (
                        <div className="col-xl-12 text-right mr-10">
                            <p className="mr-5">*Please enter Number of days in Invoice</p>
                            <button

                                className="btn btn-secondary btn-rounded mr-5"
                                type="submit"
                                name="submit"
                                style={{width:'8%'}}
                                disabled
                            >Print</button>
                            <br/>
                            <br/>
                        </div>
                    )}
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Invoice;
