import axios from "axios";
import React from "react";
import { getFileUrl } from "../../../Helpers/Helpers";
import {
  ASSET_EMPLOYEE_IMAGE,
  ASSET_MEDIA,
} from "../../../utils/AssetsReferenceTypes";
import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { NEW_WEB_STAFF } from "../../../utils/apiConstants";
import { toast } from "react-toastify";
import {
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
import DocumentsModal from "../../../modals/Students/DocumentsModal";

function NewStaff({ collegeId, setLoading }) {
  const ref = useRef();

  const fileref = useRef(null);

  const college_id = sessionStorage.getItem("college_id");

  const [edit, setEdit] = useState(false);

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [types, setTypes] = useState("");
  const [Status, setStatus] = useState("ACTIVE");

  const role = sessionStorage.getItem("role");
  // console.log("role -", role);
  // console.log("collegeId -", collegeId);

  // Initialize a counter outside the mapping function
  let serialNumber = 0;

  const [user, setUser] = useState({
    department_id: "",
    name: "",
    photo: "",
    role: "",
    staff_id: "",
    email: "",
    teaching_type: "",
    program_type: "",
    qualification: "",
    experience: "",
    expertise: "",
    about: "",
    date_of_joining: "",
    date_of_birth: "",
    attachments: "",
    reg_no: "",
  });

  const clearData = () => {
    setUser({
      department_id: "",
      name: "",
      photo: "",
      role: "",
      staff_id: "",
      email: "",
      teaching_type: "",
      program_type: "",
      qualification: "",
      experience: "",
      expertise: "",
      about: "",
      date_of_joining: "",
      date_of_birth: "",
      attachments: "",
      reg_no: "",
    });
  };

  const programOpt = JSON.parse(localStorage.getItem(LOCAL_PROGRAM));

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  useEffect(() => {
    setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT), college_id]);

  const [data, setData] = useState([]);

  const [roles, setRoles] = useState(
    JSON.parse(localStorage.getItem("JOB_ROLES"))
  );

  const getData = async () => {
    const collegeid = sessionStorage.getItem("college_id");
    setLoading(1);
    const config = {
      method: "get",
      url: `${NEW_WEB_STAFF}?college_id=${collegeid}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        // console.log('data - ', res.data.data);
        res.data.data.sort((a, b) => b.id - a.id);
        setData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleEdit = async () => {
    setLoading(1);
    const config = {
      method: "put",
      url: NEW_WEB_STAFF + `/${user.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
      },
    };

    console.log("user -", user);

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data Updated successfully");
        clearData();
        getData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleDelete = async (deletedItem, status) => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${NEW_WEB_STAFF}/${deletedItem?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: status,
      },
    };
    try {
      await axios(config);
      setLoading(0);
      toast.success("Data Status updated");
      // Update data state to remove the deleted item
      // setData(prevData => prevData.filter(item => item.id !== deletedItem.id));
      getData();
    } catch (error) {
      setLoading(0);
      toast.error("Some Error Occurred");
    }
  };

  const handleChange1 = async (e) => {
    // console.log(e.target.files[0]);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      user.attachments = d;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (i) => {
    if (user?.teaching_type === "NON_TEACHING" && role === "AYUWEB") {
      if (!user?.attachments) {
        toast.error("Please Attach a File");
        return;
      }
    } else {
      if (!user?.name) {
        toast.error("Please Enter Staff Name");
        return;
      }
      if (!user?.teaching_type) {
        toast.error("Please Select Staff Type");
        return;
      }
      if (!user?.department_id) {
        toast.error("Please Select the Department");
        return;
      }
      // if (!user?.date_of_joining) {
      //   toast.error("Please Select Date of Joining");
      //   return;
      // }
    }

    // Check if the user is NON_TEACHING and college_id is 1111001
    if (user?.teaching_type === "NON_TEACHING" && college_id === "1111001") {
      try {
        // Check if a related row exists
        const existenceRes = await axios.get(
          `${NEW_WEB_STAFF}?college_id=1111001`
        );

        if (existenceRes.data && existenceRes.data.status === "ACTIVE") {
          // If the row exists and is active, update its status to INACTIVE
          await axios.put(`${NEW_WEB_STAFF}/${existenceRes.data.id}`, {
            status: "INACTIVE",
          });
        }
      } catch (error) {
        console.error("Error checking or updating related row status:", error);
      }
    }

    user.college_id = await sessionStorage.getItem("college_id");

    setLoading(1);
    const config = {
      method: "post",
      url: `${NEW_WEB_STAFF}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: user,
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        getData();
        setUser({
          department_id: "",
          name: "",
          photo: "",
          role: "",
          staff_id: "",
          email: "",
          teaching_type: "",
          program_type: "",
          qualification: "",
          experience: "",
          expertise: "",
          about: "",
          date_of_joining: "",
          date_of_birth: "",
          reg_no: "",
        });
        ref.current.value = null;
        console.log("res -", res);
        toast.success("Successfully Added Details");
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
        // toast.error("Something went wrong");
        toast.success("Success");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpload = async (e, str) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_IMAGE,
        `Employee_${str}`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      console.log(d);
      setUser((prev) => ({
        ...prev,
        photo: d ? d : "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  let arr1 = [
    {
      id: "Anatomy",
      title: "Anatomy",
    },
    {
      id: "Physiology",
      title: "Physiology",
    },
    {
      id: "Biochemistry",
      title: "Biochemistry",
    },
    {
      id: "Pharmacology",
      title: "Pharmacology",
    },
    {
      id: "Pathology",
      title: "Pathology",
    },
    {
      id: "Microbiology",
      title: "Microbiology",
    },
    {
      id: "Forensic Medicine",
      title: "Forensic Medicine",
    },
    {
      id: "Community Medicine",
      title: "Community Medicine",
    },
    {
      id: "General Medicine",
      title: "General Medicine",
    },
    {
      id: "Pediatrics",
      title: "Pediatrics",
    },
    {
      id: "Dermatology",
      title: "Dermatology",
    },
    {
      id: "Psychiatry",
      title: "Psychiatry",
    },
    {
      id: "General Surgery",
      title: "General Surgery",
    },
    {
      id: "Orthopedics",
      title: "Orthopedics",
    },
    {
      id: "Ophthalmology",
      title: "Ophthalmology",
    },
    {
      id: "Otorhinolaryngology",
      title: "Otorhinolaryngology",
    },
    {
      id: "Obstetrcis & Gynecology",
      title: "Obstetrcis & Gynecology",
    },
    {
      id: "Radiodiagnosis",
      title: "Radiodiagnosis",
    },
    {
      id: "Anesthesia",
      title: "Anesthesia",
    },
    {
      id: "Dentistry",
      title: "Dentistry",
    },
  ];

  let homeoprgmData = [
    {
      id: "CERTIFICATE",
      title: "CERTIFICATE",
    },
    {
      id: "DIPLOMA",
      title: "DIPLOMA",
    },
    {
      id: "PG_DIPLOMA",
      title: "PG DIPLOMA",
    },
    {
      id: "UG",
      title: "UG",
    },
    {
      id: "PG/MASTER",
      title: "PG / MASTER",
    },
    {
      id: "DOCTORATE",
      title: "DOCTORATE",
    },
  ];

  let homeoData = [
    {
      id: "UG-Anatomy",
      title: "Anatomy",
      value: "Anatomy",
    },
    {
      id: "UG-Physiology",
      title: "Physiology",
      value: "Physiology",
    },
    {
      id: "UG-Pathology & Microbiology",
      title: "Pathology & Microbiology",
      value: "Pathology & Microbiology",
    },
    {
      id: "UG-Forensic Medicine & Toxicology",
      title: "Forensic Medicine & Toxicology",
      value: "Forensic Medicine & Toxicology",
    },
    {
      id: "UG-Surgery",
      title: "Surgery",
      value: "Surgery",
    },
    {
      id: "UG-Obstetrics & Gynaecology",
      title: "Obstetrics & Gynaecology",
      value: "Obstetrics & Gynaecology",
    },
    {
      id: "UG-Practice of Medicine",
      title: "Practice of Medicine",
      value: "Practice of Medicine",
    },
    {
      id: "UG-Community Medicine",
      title: "Community Medicine",
      value: "Community Medicine",
    },
    {
      id: "UG-Homeopathic Pharmacy",
      title: "Homeopathic Pharmacy",
      value: "Homeopathic Pharmacy",
    },
    {
      id: "UG-Organon of Medicine",
      title: "Organon of Medicine",
      value: "Organon of Medicine",
    },
    {
      id: "UG-Materia Medica",
      title: "Materia Medica",
      value: "Materia Medica",
    },
    {
      id: "UG-Repertory",
      title: "Repertory",
      value: "Repertory",
    },
    {
      id: "UG-Yog",
      title: "Yog",
      value: "Yog",
    },

    {
      id: "PG/MASTER-ORGANON OF MEDICINE",
      title: "Organon of Medicine",
      value: "ORGANON OF MEDICINE",
    },
    {
      id: "PG/MASTER-REPERTORY",
      title: "Repertory",
      value: "REPERTORY",
    },
    {
      id: "PG/MASTER-MATERIA",
      title: "Materia Medica",
      value: "MATERIA MEDICA",
    },
  ];

  let roleData = [
    {
      id: "DEAN",
      title: "DEAN",
    },
    {
      id: "HOD",
      title: "HOD",
    },
    {
      id: "PROFESSOR",
      title: "PROFESSOR",
    },
    {
      id: "ASSOCIATE PROFESSOR",
      title: "ASSOCIATE PROFESSOR",
    },
    {
      id: "ASSISTANT PROFESSOR",
      title: "ASSISTANT PROFESSOR",
    },
    {
      id: "SENIOR RESIDENT",
      title: "SENIOR RESIDENT",
    },
    {
      id: "JUNIOR RESIDENT",
      title: "JUNIOR RESIDENT",
    },
    {
      id: "TUTOR",
      title: "TUTOR",
    },
    {
      id: "CLERK",
      title: "CLERK",
    },
  ];

  console.log("types -", types);
  console.log("role -", role);

  return (
    <>
      <DocumentsModal title={title} img={link} setLink={setLink} />
      <div className="page-content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Add New Staff</h2>
              <br />

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">
                      Staff Type <span style={{ color: "red" }}>*</span>
                    </label>

                    <select
                      className="form-control"
                      name="teaching_type"
                      id="session"
                      value={user?.teaching_type}
                      onChange={handleChange}
                    >
                      <option value="">Select Staff Type</option>
                      <option value="TEACHING">TEACHING</option>
                      <option value="NON_TEACHING">NON-TEACHING</option>
                      <option value="HOSPITAL_STAFF">HOSPITAL STAFF</option>
                    </select>
                  </div>
                </div>

                {user?.teaching_type === "NON_TEACHING" && role === "AYUWEB" ? (
                  <>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">Attachments</label>
                        <input
                          type="file"
                          placeholder="Attach the file"
                          className="form-control"
                          name="attachments"
                          ref={fileref}
                          onChange={(e) => {
                            handleChange1(e);
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">
                          Program Type <span style={{ color: "red" }}>*</span>
                        </label>

                        <select
                          className="form-control"
                          name="program_type"
                          id="session"
                          value={user?.program_type}
                          onChange={handleChange}
                        >
                          {/* {role === "MEDWEB" ? (
                            <>
                              <option value="">Select Type</option>
                              <option value="UG">UG</option>
                              <option value="PG">PG</option>
                            </>
                          ) : role === "HOMOWEB" ? (
                            <>
                              <option value="">Select Program</option>
                              {homeoprgmData?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.title}
                                </option>
                              ))}
                            </>
                          ) : (
                            <>
                              <option value="">Select Program</option>
                              {programOpt.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                            </>
                          )} */}

                          <option value="">Select Type</option>
                          <option value="UG">UG</option>
                          <option value="PG">PG</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="validationCustom01">
                          Department <span style={{ color: "red" }}>*</span>
                        </label>

                        <select
                          className="form-control"
                          name="department_id"
                          id="section"
                          value={user?.department_id}
                          onChange={handleChange}
                        >
                          <option value="">Select Department</option>

                          {user?.program_type != "" && role === "MEDWEB"
                            ? arr1.map((i) => (
                                <option key={i.id} value={i.title}>
                                  {i.title}
                                </option>
                              ))
                            : role === "HOMOWEB"
                            ? homeoData
                                ?.filter((s) =>
                                  s.id.startsWith(user?.program_type)
                                )
                                ?.map((i) => (
                                  <option key={i.id} value={i.id}>
                                    {i.title}
                                  </option>
                                ))
                            : department
                                ?.filter(
                                  (s) =>
                                    s.college_id == college_id &&
                                    s.program_id == user?.program_type
                                )
                                ?.map((i, key) => (
                                  <option value={i.name} key={key}>
                                    {i.name}
                                  </option>
                                ))}
                        </select>
                      </div>
                    </div>

                    {user?.teaching_type != "HOSPITAL_STAFF" ? (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            Designation <span style={{ color: "red" }}>*</span>
                          </label>

                          <select
                            className="form-control"
                            name="role"
                            id="session"
                            value={user?.role}
                            onChange={handleChange}
                          >
                            <option value="">Select Role</option>
                            {roleData.map((i) => (
                              <option key={i.title} value={i.title}>
                                {i.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">
                          Name <span style={{ color: "red" }}>*</span>
                        </label>

                        <input
                          className="form-control"
                          placeholder="Enter Staff Name"
                          name="name"
                          id="class"
                          value={user?.name}
                          onChange={(e) => {
                            setUser((prev) => ({
                              ...prev,
                              ["name"]: e.target.value,
                            }));
                          }}
                          type="text"
                        />
                      </div>
                    </div>

                    {/* {user?.teaching_type != "HOSPITAL_STAFF" ? (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Staff Id</label>

                          <input
                            className="form-control"
                            placeholder="Enter Staff Id"
                            name="staff_id"
                            id="class"
                            value={user?.staff_id}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                ["staff_id"]: e.target.value,
                              }));
                            }}
                            type="text"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}

                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">
                          Registration No{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <input
                          className="form-control"
                          placeholder="Enter Registration Number"
                          name="reg_no"
                          id="class"
                          value={user?.reg_no}
                          onChange={(e) => {
                            setUser((prev) => ({
                              ...prev,
                              ["reg_no"]: e.target.value,
                            }));
                          }}
                          type="text"
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">Date of Birth</label>
                        <input
                          required
                          type="date"
                          className="form-control"
                          name="date_of_birth"
                          placeholder="Select Date of Birth"
                          value={
                            user?.date_of_birth
                              ? user?.date_of_birth?.split("T")[0]
                              : ""
                          }
                          onChange={handleChange}
                          // onChange={(e) => {
                          //   handleChange(e);
                          // }}
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">Email Id</label>
                        <input
                          className="form-control"
                          placeholder="Enter Staff email"
                          name="email"
                          value={user?.email}
                          onChange={(e) => {
                            setUser((prev) => ({
                              ...prev,
                              ["email"]: e.target.value,
                            }));
                          }}
                          type="text"
                        />
                      </div>
                    </div> */}

                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">
                          Qualification <span style={{ color: "red" }}>*</span>
                        </label>

                        <input
                          className="form-control"
                          placeholder="Enter Qualification"
                          name="qualification"
                          value={user?.qualification}
                          onChange={handleChange}
                          type="text"
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">Experience</label>

                        <input
                          className="form-control"
                          placeholder="Enter Staff Experience"
                          name="experience"
                          value={user?.experience}
                          onChange={handleChange}
                          type="text"
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">
                          Date of Joining{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          type="date"
                          className="form-control"
                          name="date_of_joining"
                          placeholder="Select Date of Joining"
                          value={
                            user?.date_of_joining
                              ? user?.date_of_joining?.split("T")[0]
                              : ""
                          }
                          onChange={handleChange}
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">Expertise</label>
                        <input
                          className="form-control"
                          placeholder="Enter Staff Expertise"
                          name="expertise"
                          value={user?.expertise}
                          onChange={handleChange}
                          type="text"
                        />
                      </div>
                    </div> */}

                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">
                          Image <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          className="form-control"
                          name="photo"
                          ref={ref}
                          onChange={(e) => {
                            handleUpload(e, "Image");
                          }}
                          type="file"
                        />
                        {user?.photo?.length > 0 ? (
                          <button
                            onClick={() => {
                              setLink(user?.photo);
                              setTitle("Staff Image");
                            }}
                            data-toggle="modal"
                            data-target="#DocumentsModal"
                            className="btn btn-primary btn-sm my-1"
                          >
                            View
                          </button>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className="col-md-11">
                      <div className="form-group">
                        <label htmlFor="">About</label>

                        <textarea
                          className="form-control"
                          placeholder="Enter About"
                          name="about"
                          value={user?.about}
                          onChange={handleChange}
                          rows={6} // Specify the number of rows for the text area
                        />
                      </div>
                    </div> */}
                  </>
                )}
              </div>

              <div className="row mr-5 mt-4">
                <div className="col-md-12 ml-auto">
                  {edit == false ? (
                    <>
                      <button
                        className="btn btn-nex btn-rounded float-right "
                        type="submit"
                        name="submit"
                        onClick={handleSubmit}
                      >
                        <i className="fa fa-save" aria-hidden="true" /> Save
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-nex btn-rounded float-right "
                        type="submit"
                        name="Update"
                        onClick={handleEdit}
                      >
                        <i className="fa fa-save" aria-hidden="true" /> Update
                      </button>
                      <button
                        className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                        type="submit"
                        name="submit"
                        // style={{aspectRatio:'1/1'}}
                        onClick={() => {
                          setEdit(false);
                          // setAddNew(false);
                        }}
                      >
                        {"<   "}{" "}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div class="card-header">
                  <h5 class="text-primary"> EMPLOYEE LIST </h5>
                </div>
                <div className="card-body">
                  {/* <h6 class="text-danger"> Search Fields </h6>
                  <br/> */}

                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          {" "}
                          Type <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name="type"
                          id="class"
                          className="form-control"
                          value={types}
                          onChange={(e) => setTypes(e.target.value)}
                        >
                          <option value="">All</option>
                          <option value="TEACHING">TEACHING</option>
                          <option value="NON_TEACHING">NON-TEACHING</option>
                          <option value="HOSPITAL_STAFF">HOSPITAL STAFF</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          {" "}
                          Status <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name="type"
                          id="class"
                          className="form-control"
                          value={Status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="ACTIVE">ACTIVE</option>
                          <option value="INACTIVE">INACTIVE</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />

                  <div className="table-responsive">
                    <table
                      // id="datatable"
                      className="table table-bordered  nowrap table-hover"
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <>
                            <th>Sl.No</th>
                            <th>Name</th>
                            <th>Qualification</th>
                            <th>Staff Type</th>
                            {/* <th>Program Type</th> */}
                            <th>Department</th>
                            <th>Designation</th>
                            {/* <th>Staff ID</th> */}
                            {/* <th>Date of Joining</th>
                            <th>Date of Birth</th>
                            <th>Email Id</th> */}
                            <th>Image</th>
                            <th>Status</th>
                            <th>Action</th>
                          </>
                        </tr>
                      </thead>

                      <tbody>
                        {data &&
                          data?.map((item, key) => {
                            const showRow =
                              (types === item?.teaching_type &&
                                Status === item?.status) ||
                              (types === "" && Status === item?.status);

                            // Increment the serial number for all items
                            if (showRow) {
                              serialNumber++;
                            }

                            return showRow ? (
                              <tr key={key}>
                                <>
                                  <td>{serialNumber}</td>
                                  <td>{item?.name}</td>
                                  <td>{item?.qualification}</td>
                                  <td>{item?.teaching_type}</td>

                                  {/* <td>{item?.program_type}</td> */}

                                  <td>{item?.department_id}</td>
                                  <td>{item?.role}</td>
                                  {/* <td>
                                    {item?.date_of_joining
                                      ? item?.date_of_joining.slice(0, 10)
                                      : ""}
                                  </td>
                                  <td>
                                    {item?.date_of_birth
                                      ? item?.date_of_birth.slice(0, 10)
                                      : ""}
                                  </td>
                                  <td>{item?.email}</td> */}
                                  <td>
                                    {item?.photo ? (
                                      <a href={item?.photo} target="_blank">
                                        View
                                      </a>
                                    ) : null}
                                  </td>
                                  <td>{item?.status}</td>
                                  <td>
                                    {item?.status == "ACTIVE" ? (
                                      <>
                                        <acronym title="Edit">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() => {
                                              setEdit(true);
                                              setUser({ ...item });
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit "
                                              aria-hidden="true"
                                            />
                                          </a>
                                        </acronym>{" "}
                                        &nbsp;&nbsp;&nbsp;
                                        <acronym title="Inactive">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() =>
                                              handleDelete(item, "INACTIVE")
                                            }
                                          >
                                            <i
                                              className="fa fa-thumbs-down"
                                              aria-hidden="true"
                                              style={{ color: "red" }}
                                            />
                                          </a>
                                        </acronym>
                                      </>
                                    ) : (
                                      <acronym title="active">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() =>
                                            handleDelete(item, "ACTIVE")
                                          }
                                        >
                                          <i
                                            className="fa fa-thumbs-up"
                                            aria-hidden="true"
                                            style={{ color: "green" }}
                                          />
                                        </a>
                                      </acronym>
                                    )}
                                    <a href="javascript:void(0)"> </a>
                                  </td>
                                </>
                              </tr>
                            ) : null;
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewStaff;
