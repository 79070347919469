export const sessionOpt = [
    {
        name: '2024-25',
        id: '2024'
    },
    {
        name: '2023-24',
        id: '2023'
    },
    {
        name: '2022-23',
        id: '2022'
    },
    {
        name: '2021-22',
        id: '2021'
    },
    {
        name: '2020-21',
        id: '2020'
    },
    {
        name: '2019-20',
        id: '2019'
    },
    {
        name: '2018-19',
        id: '2018'
    },
    {
        name: '2017-18',
        id: '2017'
    },
    {
        name: '2016-17',
        id: '2016'
    },
    {
        name: '2015-16',
        id: '2015'
    },
    {
        name: '2014-15',
        id: '2014'
    },
    {
        name: '2013-14',
        id: '2013'
    },
]