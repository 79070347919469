import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  ASSET_EMPLOYEE_IMAGE,
  ASSET_MEDIA,
} from "../../utils/AssetsReferenceTypes";
import { ADD_SLIDERS } from "../../utils/InfoUploadingApiConstants";
import { getFileUrl } from "../../Helpers/Helpers";
import DocumentsModal from "../../modals/Students/DocumentsModal";

function Sliders({ setLoading }) {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [types, setTypes] = useState("");

  const [edit, setEdit] = useState(false);

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  // Initialize a counter outside the mapping function
  let serialNumber = 0;

  const fileref = useRef(null);

  const [info, setInfo] = useState({
    title: "",
    type: "Main Slider",
    image: "",
    date: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearData = () => {
    setInfo({
      title: "",
      type: "Main Slider",
      image: "",
      date: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {
    if (!info?.title || !info?.type || !info.image) {
      toast.error("Please Enter all the required Details");
      return;
    }

    info.college_id = await sessionStorage.getItem("college_id");

    setLoading(1);

    // Get current date
    const currentDate = new Date().toISOString();

    const config = {
      method: "post",
      url: ADD_SLIDERS,
      headers: {
        "Content-Type": "application/json",
        //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: {
        ...info,
        date: currentDate,
      },
    };

    axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Succesfully Uploaded Details");
        getData();
        clearData();
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    let empId = Math.floor(Math.random() * 100);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      info.image = d;
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    const collegeid = sessionStorage.getItem("college_id");
    console.log("collegeid -", collegeid);

    const config = {
      method: "get",
      url: ADD_SLIDERS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log("hi");

    await axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);

        // setData(res.data.data);
        setData(res.data.data.filter((item) => item.college_id == collegeid));
        // console.log("data -", data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  console.log("data -", data);

  const handleEdit = async () => {
    setLoading(1);
    const config = {
      method: "put",
      url: ADD_SLIDERS + `/${info.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...info,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data Updated successfully");
        getData();
        clearData();
        // getData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleDelete = async (deletedItem, status) => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${ADD_SLIDERS}/${deletedItem?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: status,
      },
    };
    try {
      await axios(config);
      setLoading(0);
      toast.success("Data Status Updated");
      getData();
    } catch (error) {
      setLoading(0);
      toast.error("Some Error Occurred");
    }
  };

  console.log("info?.image -", info?.image);

  // Function to clean the URL by removing extra quotes
  const cleanUrl = (url) => url.replace(/^"|"$/g, "");

  return (
    <div className="Sliders">
      <DocumentsModal title={title} img={link} setLink={setLink} />
      <>
        <div className="row">
          <div className="col-12 mt-4">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h5 className="ml-4">UPLOAD SLIDER IMAGES</h5>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 mb-2">
            <div className="card">
              <div className="card-body">
                <h2 className="card-title">Select criteria</h2>
                <br />
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">
                        Title <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter the Title"
                        className="form-control"
                        name="title"
                        value={info?.title}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">
                        Image <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        placeholder="Attach the file"
                        className="form-control"
                        name="image"
                        ref={fileref}
                        onChange={(e) => {
                          handleChange1(e);
                        }}
                      />
                      {info?.image?.length > 0 ? (
                        <button
                          onClick={() => {
                            setLink(info?.image);
                            setTitle("Slider Image");
                          }}
                          data-toggle="modal"
                          data-target="#DocumentsModal"
                          className="btn btn-primary btn-sm my-1"
                        >
                          View
                        </button>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        {" "}
                        Type <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="type"
                        id="class"
                        className="form-control"
                        value={info?.type}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <option value="Main Slider">Main Slider</option>
                        <option value="PopUp">PopUp</option>
                        <option value="About">About</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-12 ml-auto">
                    {edit == false ? (
                      <>
                        <button
                          className="btn btn-nex btn-rounded float-right "
                          type="submit"
                          name="submit"
                          onClick={handleSubmit}
                        >
                          <i className="fa fa-save" aria-hidden="true" /> Save
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-nex btn-rounded float-right "
                          type="submit"
                          name="Update"
                          onClick={handleEdit}
                        >
                          <i className="fa fa-save" aria-hidden="true" /> Update
                        </button>
                        <button
                          className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                          type="submit"
                          name="submit"
                          // style={{aspectRatio:'1/1'}}
                          onClick={() => {
                            setEdit(false);
                            // setAddNew(false);
                          }}
                        >
                          {"<   "}{" "}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div class="card-header">
                <h5 class="text-primary"> SLIDERS IMAGES LIST </h5>
              </div>

              <div className="card-body">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      {" "}
                      Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      name="type"
                      id="class"
                      className="form-control"
                      value={types}
                      onChange={(e) => setTypes(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="Main Slider">Main Slider</option>
                      <option value="PopUp">PopUp</option>
                      <option value="About">About</option>
                    </select>
                  </div>
                </div>
                <br />
                <br />

                <div style={{ overflowX: "auto" }}>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Sl.No.</th>
                        <th>Type</th>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Image</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data &&
                        data?.map((item, key) => {
                          const showRow = types === item?.type || types === "";

                          // Increment the serial number for all items
                          if (showRow) {
                            serialNumber++;
                          }

                          return showRow ? (
                            <tr key={key}>
                              <td>{serialNumber}</td>
                              <td>{item?.type}</td>
                              <td>{item?.title}</td>
                              <td>
                                {item?.date ? item.date.split("T")[0] : null}
                              </td>
                              {/* <td>
                                <a
                                  href={item?.image}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {" "}
                                  View Uploaded File{" "}
                                </a>
                              </td> */}
                              <td>
                                <a
                                  href={cleanUrl(item?.image)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {" "}
                                  View Uploaded File{" "}
                                </a>
                              </td>
                              <td>
                                {item?.status === "ACTIVE" ? (
                                  <span className="badge badge-soft-success">
                                    {item?.status}
                                  </span>
                                ) : (
                                  <span className="badge badge-soft-danger">
                                    {item?.status}
                                  </span>
                                )}
                              </td>
                              <td>
                                {item?.status == "ACTIVE" ? (
                                  <>
                                    <acronym title="Edit">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() => {
                                          setEdit(true);
                                          setInfo({ ...item });
                                        }}
                                      >
                                        <i
                                          className="fa fa-edit "
                                          aria-hidden="true"
                                        />
                                      </a>
                                    </acronym>{" "}
                                    &nbsp;&nbsp;&nbsp;
                                    <acronym title="Inactive">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          handleDelete(item, "INACTIVE")
                                        }
                                      >
                                        <i
                                          className="fa fa-thumbs-down"
                                          aria-hidden="true"
                                          style={{ color: "red" }}
                                        />
                                      </a>
                                    </acronym>
                                  </>
                                ) : (
                                  <acronym title="active">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        handleDelete(item, "ACTIVE")
                                      }
                                    >
                                      <i
                                        className="fa fa-thumbs-up"
                                        aria-hidden="true"
                                        style={{ color: "green" }}
                                      />
                                    </a>
                                  </acronym>
                                )}
                                <a href="javascript:void(0)"> </a>
                              </td>
                            </tr>
                          ) : null;
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Sliders;
