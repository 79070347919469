import React from 'react';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from "react-select";
import { sessionOpt } from '../../Data/jsonData/Academics/Academics';
import { ASSET_MEDIA } from '../../utils/AssetsReferenceTypes';
import { getFileUrl } from '../../Helpers/Helpers';
import { PCI_SIF_CREATE, PCI_SIF_GET, PCI_SIF_UPDATE } from '../../utils/InfoUploadingApiConstants';

function PCI_SIF_Pharmacy({ setLoading }) {

    const fileref = useRef(null);

//   const [attachments, setAttachments] = useState([]);

  const [data, setData] = useState([]);

  const [session, setSession] = useState("");

  const [info, setInfo] = useState({
    // session: "",
    date: "",
    attachments: "",
  });

  const clearData = () => {
    setInfo({
    //   session: "",
      date: "",
      attachments: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {
    if ( !info?.attachments ) {
      toast.error("Please Enter all the required Details");
      return;
    }

    setLoading(1);

    // Retrieve college_id from session storage
    const college_id = sessionStorage.getItem("college_id")
    console.log("college_id",college_id);
    
    // Get current date
    const currentDate = new Date().toISOString();

    const config = {
      method: "post",
      url: PCI_SIF_CREATE,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...info,
        date: currentDate,
        session: session,
        // attachments,
        college_id: college_id, // Add college_id to the data
      },
    };

    axios(config)
      .then((res) => {
        toast.success("Succesfully Uploaded Details");
        clearData();
        getData();
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });

  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    // let empId = Math.floor(Math.random() * 100);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      info.attachments = d;
    } catch (error) {
      console.log(error);
    }
  };


  const getData = async () => {
    const collegeid = sessionStorage.getItem("college_id");

    const config = {
        method: "get",
        url: PCI_SIF_GET,
        headers: {
            "Content-Type": "application/json",
        },
    };

    await axios(config)
        .then((res) => {
            res.data.data.sort((a, b) => b.id - a.id);
            res.data.data.forEach((element) => {
                element.attachments = JSON.parse(element.attachments);
            });
            console.log(res.data.data)
            setData(res.data.data.filter((item) => item.college_id == collegeid))            

        })
        .catch((err) => {
            console.log(err);
        });
};

useEffect(() => {
    getData();
}, []);

const handleApprove = (id) => {
  const config = {
      method: 'put',
      url: `${PCI_SIF_UPDATE}/${id}`,
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
      },
      data: {
          status: 'ACTIVE'
      }
  }

  axios(config)
      .then(res => {
          setLoading(0)
          toast.success("Success")
          const updatedData = data.map(item => (item.id === id ? { ...item, status: 'ACTIVE' } : item));
          setData(updatedData);
          getData();
      })
      .catch(err => {
          setLoading(0)
          toast.error("Something Went Wrong")
      })
}

const handleDelete = (id) => {
const config = {
    method: 'put',
    url: `${PCI_SIF_UPDATE}/${id}`,
    headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
    },
    data: {
        status: 'INACTIVE'
    }
}

axios(config)
    .then(res => {
        setLoading(0)
        toast.success("Successfully Deleted")
        const updatedData = data.map(item => (item.id === id ? { ...item, status: 'INACTIVE' } : item));
        setData(updatedData);
        getData();
    })
    .catch(err => {
        setLoading(0)
        toast.error("Something Went Wrong")
    })
}

  return (
    <div>

<div className="row">
            <div className="col-12 mt-4">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h5 className='ml-4'>PCI-SIF</h5>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-xl-12 mb-2">
                <div className="card">
                    <div className="card-body">

                        <h2 className="card-title">Select criteria</h2>
                        <br/>

                        <div className="row">

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label> session <span style={{ color: "red" }}>*</span></label>
                                    <select
                            className="form-control"
                            value={session}
                            onChange={(e) => {
                              setSession(e.target.value);
                            }}
                            // value={info?.session}
                            // onChange={(e) => {
                            //     handleChange(e);
                            // }}
                          >
                            <option value="">Select Session</option>
                            {sessionOpt?.map((i, key) => (
                              <option value={i?.id}>{i?.name}</option>
                            ))}
                            </select>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Attachments<span style={{ color: "red" }}>*</span></label>
                                    <input type="file"
                            placeholder="Attach the file"
                            className="form-control"
                            name="attachments"
                            ref={fileref}
                            onChange={(e) => {
                              handleChange1(e);
                            }}
                          />
                                </div>
                            </div>
                            
                        </div>

                        <div className="row float-right">
                            <button
                              className="btn btn-primary mr-4"
                              type="submit"
                              name="submit"
                              onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                        <br/>
                        <br/>
                        <br/>

                    </div>
                </div>
            </div>
        </div>

        <div className="row">
              <div className="col-12">

                <div className="card">

                  <div class="card-header">
                    <h5 class="text-primary"> PCI-SIF LIST </h5>
                  </div>

                  <div className="card-body">
                    <br/>                     
                    
                    <div style={{ overflowX: "auto" }}>

                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Sl.No.</th>
                            <th>Session</th>
                            <th>Attachments</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        
                        <tbody>

                          {
                            data && data?.map((item, key) => {
                            
                                return (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>{item?.session}</td>
                                  <td><a href={item?.attachments} target="_blank"> View Uploaded File </a></td>
                                  <td>{new Date(item?.date).toISOString().split('T')[0]}</td>
                                  <td>{item?.status == "INACTIVE" 
                                      ? 
                                    <button className="btn btn-danger mr-2" onClick={() => handleApprove(item?.id)}>INACTIVE</button>
                                      : 
                                    <button className="btn btn-success mr-2" onClick={() => handleDelete(item?.id)}>ACTIVE</button>}
                                  </td>
                                </tr>

                              ); 
                            })
                          }
                        </tbody>
                      </table>

                    </div>
                  </div>

                </div>

            </div>
        </div>
      
    </div>
  )
}

export default PCI_SIF_Pharmacy;
