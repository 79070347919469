import React from "react";
import { useState, useEffect } from "react";
import PrevResultsEngg from "../../../modals/Students/Results/Engg";
import PrevResults from "../../../modals/Students/Results/Other";
import PrevResultsPharm from "../../../modals/Students/Results/Pharmacy";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst";
import axios from "axios";
import { toast } from "react-toastify";

function StudentExams({ id, studentDetails, setLoading }) {
  const [user, setUser] = useState({
    session: "",
    sem_id: "",
    class_id: "",
  });

  const [classOpt, setClassOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [sumbit, setSumbit] = useState(false);

  const [flag, setFlag] = useState(0);

  const getData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    setLoading(1);
    const [data1, data2] = await Promise.all([
      await axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?department_id=${studentDetails?.department_id}&college_id=${studentDetails?.college_id}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),

      await axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${studentDetails?.college_id}`,
      })
        .then((res) => {
          setLoading(0);
          setSemesterOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),
    ]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClick = () => {
    if (!user?.sem_id || !user?.class_id || !user?.session) {
      return toast.error("Please Select All Details");
    }
    setFlag((flag) => flag + 1);
    setSumbit(true);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="d-flex col-xl-12">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="validationCustom01">
                    Class <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    name="class_id"
                    id="class"
                    className="form-control"
                    value={user?.class_id}
                    onChange={handleChange}
                  >
                    <option value="" selected>
                      Select Class
                    </option>
                    {classOpt?.map((i, key) => (
                      <option value={i.id} key={key}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="validationCustom01">
                    Semester <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    name="sem_id"
                    className="form-control"
                    value={user?.sem_id}
                    onChange={handleChange}
                  >
                    <option value="" selected>
                      Select Semester
                    </option>
                    {semesterOpt
                      ?.filter((s) => s.class_id == user?.class_id)
                      ?.map((i, key) => (
                        <option value={i.id} key={key}>
                          {i.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="validationCustom01">
                    Academic Year <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    name="session"
                    className="form-control"
                    value={user?.session}
                    onChange={handleChange}
                  >
                    <option value="" selected>
                      Select Academic Year
                    </option>
                    {sessionOpt?.map((i, key) => (
                      <option value={i.id} key={key}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row float-right mr-3">
            <button
              className="btn btn-nex btn-md"
              type="submit"
              name="submit"
              value="collect"
              onClick={handleClick}
            >
              Submit
            </button>
          </div>
        </div>
        {studentDetails?.college_id == "1111000" && sumbit ? (
          <PrevResultsEngg
            user={user}
            studentData={studentDetails}
            setLoading={setLoading}
          />
        ) : studentDetails?.college_id == "1111008" && sumbit ? (
          <PrevResultsPharm
            user={user}
            studentData={studentDetails}
            setLoading={setLoading}
          />
        ) : sumbit ? (
          <PrevResults
            user={user}
            studentData={studentDetails}
            setLoading={setLoading}
          />
        ) : null}
      </div>
    </div>
  );
}

export default StudentExams;
